import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Image } from '@chakra-ui/image';

import { useHistory } from 'react-router-dom';
import { get, post } from '../../services/api';

import REAR from '../../assets/sarath_IMG/REAR_FLIPPED.png';
import FRONT from '../../assets/sarath_IMG/FRONT_FLIPPED.png';
import { Meta, UUID } from '../../services/Meta-Pixel';
import { Add2CartGtag, Save4LaterGtag } from '../../services/G-Tag';
import { useDispatch, useSelector } from 'react-redux';
import { setupdateCart } from '../../stateManagement/user/cartCountSlice';

function ProductCardScroll(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const updateCart = useSelector((state) => state.count.updateCart);

  const [pincode, setpincode] = useState('');

  const onBoxClick = (element) => {
    history.push(
      `/product/${element?.prod_id}${
        element.vehicle_details?.make_id
          ? '/' +
            element.vehicle_details?.make_id +
            '/' +
            element.vehicle_details?.model_id +
            '/' +
            element.vehicle_details?.year +
            (element.vehicle_details?.version
              ? '/' + element.vehicle_details?.version
              : '') +
            (element.vehicle_details?.fueltype
              ? '/' + element.vehicle_details?.fueltype
              : '')
          : ''
      }${
        element.vehicle_details?.type ? '/' + element.vehicle_details?.type : ''
      }`
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const tokenpin = JSON.parse(localStorage.getItem('pincode'));
    if (tokenpin != null) {
      setpincode(tokenpin.toString());
    } else {
      get('api/v2/products/pincode/address/')
        .then((res) => {
          if (res.address_pincode.pincode != []) {
            setpincode(res.address_pincode.pincode.toString());
          } else if (res.order_pincode.pincode != []) {
            setpincode(res.order_pincode.pincode.toString());
          }
        })
        .catch((err) => {
          setpincode('');
        });
    }
  }, []);

  //Toast

  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const Add2Cart = (element) => {
    let productdata = {
      product: [
        {
          vend_id: element?.vendor_products?.vend_id,
          prod_id: element?.prod_id,
          make_id: element?.vehicle_details?.make_id ?? '',
          model_id: element?.vehicle_details?.model_id ?? '',
          year: element?.vehicle_details?.year ?? '',
          fueltype: element?.vehicle_details?.fueltype ?? '',
          version: element?.vehicle_details?.version ?? '',
          delivery_date: '',
          quantity: 1,
        },
      ],
      pincode: pincode ? pincode : '110001',
    };
    const eventID = UUID();

    try {
      post('/api/v2/products/addtocart/', productdata)
        .then((res) => {
          Toast2('Item moved to Cart !', 'success', '#089520');
          dispatch(setupdateCart(!updateCart));

          window.fbq(
            'track',
            'AddToCart',
            {
              value: element?.vendor_products?.selling_price,
              currency: 'INR',
              contents: [
                {
                  id: element?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );

          let data = {
            currency: 'INR',
            value: element?.vendor_products?.selling_price,
            contents: [
              {
                id: element?.brakeup_part_no,
                quantity: 1,
                delivery_category: 'home_delivery',
              },
            ],
          };
          Meta('AddToCart', data, eventID);
          Add2CartGtag(element);
        })
        .catch((err) => {
          Toast2(err?.response?.data.message, 'error', '#f22020');
        });
    } catch (error) {}
  };

  const Save4Later = (element) => {
    let productdata = {
      product: [
        {
          vend_id: element?.vendor_products?.vend_id,
          prod_id: element?.prod_id,
          make_id: element?.vehicle_details?.make_id ?? '',
          model_id: element?.vehicle_details?.model_id ?? '',
          year: element?.vehicle_details?.year ?? '',
          fueltype: element?.vehicle_details?.fueltype ?? '',
          version: element?.vehicle_details?.version ?? '',
          quantity: 1,
        },
      ],
    };
    const eventID = UUID();

    try {
      post('/api/v2/products/savelater/productdetail/', productdata)
        .then((res) => {
          Toast2('Item added to Save for later!', 'success', '#089520');
          dispatch(setupdateCart(!updateCart));

          let data = {
            currency: 'INR',
            value: element?.vendor_products?.selling_price,
            contents: [
              {
                id: element?.brakeup_part_no,
                quantity: 1,
                delivery_category: 'home_delivery',
              },
            ],
          };

          let make1 = element?.vehicle_details?.make
            ? element?.vehicle_details?.make
            : '';

          let model1 = element?.vehicle_details?.model
            ? element?.vehicle_details?.model
            : '';

          let year1 = element?.vehicle_details?.year
            ? element?.vehicle_details?.year
            : '';

          let vehicle = make1 + ' ' + model1 + ' ' + year1;

          Meta('AddToWishlist', data, eventID);
          Save4LaterGtag(element, vehicle);
          window.fbq(
            'track',
            'AddToWishlist',
            {
              value: element?.mrp,
              currency: 'INR',
              contents: [
                {
                  id: element?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );
        })
        .catch((err) => {
          Toast2(err?.response?.data.message, 'error', '#f22020');
        });
    } catch (error) {}
  };

  return (
    <div className="mt-2 font-manrope ">
      <p className="nxt_subheaders">{props.title}</p>
      <div
        style={{ height: '2px' }}
        className="mb-3 border-y bg-yellowB border-yellowB"
      ></div>

      {props.reViewed.length == 0 ? (
        <div className="flex items-center justify-center text-sm text-center h-72 sm:h-80 md:h-96 sm:text-base md:text-lg">
          <p>No recent views</p>
        </div>
      ) : (
        <div
          // style={{ width: '90vw' }}
          className="flex flex-row gap-5 overflow-x-scroll md:px-2 "
        >
          {props.reViewed.map((element, index) => (
            <div
              key={index}
              className="my-2 grid first-of-type:ml-0.5 cursor-pointer"
              onClick={() => onBoxClick(element)}
            >
              <div className="px-3 py-2 mb-3 text-sm w-44 sm:w-52 md:w-60 rounded-brBs shadow-bsCard2">
                <Image src={element.image[0]} alt="" layout="responsive" />
                <div className="flex flex-col justify-between my-2 PCSdata">
                  <p className="font-bold uppercase ccr_fontpart1">
                    {element.brand}
                  </p>
                  <p className="overflow-hidden ccr_fontpart1 whitespace-nowrap text-ellipsis">
                    {element.prod_name}
                  </p>
                  {element?.vendor_products?.discount ? (
                    <div className="flex items-center font-normal ccr_fontpart3">
                      <p className="mr-2 font-normal line-through text-redB">
                        Rs. {element.mrp}
                      </p>
                      {element?.vendor_products?.discount.toFixed(0) != 0 && (
                        <p className=" text-greenB">
                          {element?.vendor_products?.discount
                            ? element?.vendor_products?.discount.toFixed(0)
                            : ''}
                          % OFF
                        </p>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <p className="text-base font-bold text-blueB3 md:text-lg">
                    Rs {element?.vendor_products?.selling_price ?? element.mrp}
                  </p>
                </div>
                <div>
                  {props.title !== 'SIMILAR PRODUCTS' ? (
                    <div>
                      <div className="flex items-center justify-center gap-1 px-2 font-bold ccr_fontpart3 text-greyB5">
                        <Image
                          src={element?.vehicle_details?.logo}
                          alt=""
                          height={'30px'}
                          objectFit="cover"
                        />
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {element?.vehicle_details?.model}
                        </p>
                        <p>{element?.vehicle_details?.year}</p>
                      </div>

                      {element?.vendor_products?.selling_price ? (
                        <div className="flex justify-center">
                          {props.title !== 'TOP SELLING' &&
                            (element?.stock_status === 'instock' ? (
                              <button
                                className="fpyellowBtnBorder whitespace-nowrap my-1 mx-6 px-3 py-1.5"
                                id="add_to_cart"
                                onClick={(e) => {
                                  e.stopPropagation(), Add2Cart(element);
                                }}
                              >
                                ADD TO CART
                              </button>
                            ) : (
                              <button
                                className="fpyellowBtnBorder whitespace-nowrap my-1 mx-6 px-3 py-1.5"
                                id="save_for_later"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  Save4Later(element);
                                }}
                              >
                                Save for later
                              </button>
                            ))}
                        </div>
                      ) : (
                        <div className="my-1 mx-6 px-3 py-1.5"></div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default ProductCardScroll;
