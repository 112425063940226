import { Cart } from './views/cart';

import { OrderConfirmed } from './views/OrderConfirmed/orderplaced';
import { OrderNotConfirmed } from './views/OrderNotConfirmed/ordernotplaced';

import MyProfile from './views/MyProfilePage/MyProfilePageLayout';
import { ReplacementWeb } from './views/ReplacementPage/ReplacementWeb';
import { ReplacementMob } from './views/ReplacementPage/ReplacementMob';

import { MobileLoginSignup } from './views/Login/MobileLoginSignup/MobileLoginSignup';

import MyProfileMobile from './views/MyProfilePage/MyProfileMobileLayout';

import { ReturnRefundPolicy } from './views/Policy/Return&RefundPolicy';
import { PrivacyPolicy } from './views/Policy/PrivacyPolicy';
import { TermsOfUse } from './views/Policy/TermsOfUse';
import { Shipping } from './views/Policy/Shipping';

import { CustomerCare } from './views/CustomerCare/CustomerCare';
import { ContactUs } from './views/ContactUs/ContactUs';
import { AboutUs } from './views/AboutUs/AboutUs';
import { HomePage } from './views/HomePage/HomePage';

import { FilterPageLayoutMobile } from './views/FilterPage/FilterPageLayoutMobile';
import { NOTFOUND404 } from './views/NOTFOUND404';
import { FilterPage } from './views/FilterPage';
import { CustomerCare2 } from './views/CustomerCare/CustomerCare2';

import Product_Detail from './views/ProductDetailpage/pd';
import Address from './views/Address/address';
import OrderSummary from './views/OrderSummary/order_summary';
import SelectPaymentMethod from './views/PaymentMethod';
import PaymentPending from './views/PaymentVerify/PaymentVerify';

export const dashRoutes = [
  { path: '/', component: HomePage },
  { path: '/filter', component: FilterPage },
  { path: '/product/:id', component: Product_Detail },

  {
    path: '/product/:id/:make/:model/:year/:version/:fuel/:type',
    component: Product_Detail,
  },
  { path: '/product/:id/:make/:model/:year', component: Product_Detail },
  {
    path: '/product/:id/:make/:model/:year/:version',
    component: Product_Detail,
  },
  {
    path: '/product/:id/:make/:model/:year/:version/:fuel',
    component: Product_Detail,
  },
  { path: '/product/:id/:make/:model/:year/:type', component: Product_Detail },
  {
    path: '/product/:id/:make/:model/:year/:version/:type',
    component: Product_Detail,
  },

  { path: '/cart', component: Cart },
  { path: '/address/:id', component: Address },
  { path: '/address', component: Address },
  { path: '/order-summary', component: OrderSummary },
  { path: '/payment', component: SelectPaymentMethod },
  { path: '/payment-check', component: PaymentPending },

  { path: '/order-success', component: OrderConfirmed },
  { path: '/order-failed', component: OrderNotConfirmed },

  { path: '/loginsignup', component: MobileLoginSignup },
  { path: '/myprofile', component: MyProfile },
  { path: '/myprofile/:id', component: MyProfile },

  {
    path: '/replace/:order_id/:prod_id/:vendor_id/:user_id',
    component: ReplacementWeb,
  },
  {
    path: '/replacemob/:order_id/:prod_id/:vendor_id/:user_id',
    component: ReplacementMob,
  },
  { path: '/myprofilemobile', component: MyProfileMobile },
  { path: '/myprofilemobile/:id', component: MyProfileMobile },
  { path: '/return&refundpolicy', component: ReturnRefundPolicy },
  { path: '/privacypolicy', component: PrivacyPolicy },
  { path: '/termsofuse', component: TermsOfUse },
  { path: '/shipping', component: Shipping },
  { path: '/customercare', component: CustomerCare },
  { path: '/helpcenter', component: CustomerCare },
  { path: '/contactus', component: CustomerCare },
  { path: '/aboutus', component: AboutUs },
  { path: '*', component: NOTFOUND404 },
];
