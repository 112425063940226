import React from 'react';
import './LoginSignup.css';
import './styleguide.css';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import LineImage from '../../../assets/sarath_IMG/line-18.png';

import { useState, useEffect } from 'react';
import {
  LoginSignup,
  LoginOtp,
  SignUp,
  FinalPage,
  ForGotPage,
  OtpLoginPage,
} from './components';

import { useDispatch } from 'react-redux';

import { setIsSignInOpen } from '../../../stateManagement/user/signInIsOpenSlice';

export const SignIn = (props) => {
  const LoginSignupData = {
    welcomeBack: 'Welcome Back',
    welcomeToBrakeup: 'Welcome to Brakeup',
    loremIpsumDolorSi: (
      <>
        {' '}
        <div>
          Find the perfect brakes <br />
          for your perfect car with Brakeup!
        </div>{' '}
      </>
    ),
    spanText1: 'Sign In',
    spanText2: ' to your account',
    inputType1: 'text',
    inputPlaceholder1: 'Enter Mobile Number/Email',
    inputType2: 'password',
    inputPlaceholder2: 'Enter Password',
    forgot: 'Forgot ?',
    spanText3: "By continuing, you agree to Brakeup's ",
    spanText4: 'Terms of Use ',
    spanText5: ' and ',
    spanText6: 'Privacy Policy',
    logIn: 'Log in',
    line18: LineImage,
    or: 'or',
    requestOtp: 'Request OTP',
    newToBrakeupCrea: (
      <p>
        {' '}
        New to Brakeup?{' '}
        <span style={{ fontWeight: '700', color: '#414bdb' }}>
          {' '}
          Create an account here
        </span>
      </p>
    ),
  };

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [otpData, setOtpData] = useState({});
  const otpNav = (data) => {
    setOtpData(data);
    setPage(2);
  };
  const onCloseModal = () => {
    setPage(1);
    props.onClose();
  };
  const [phone, setPhone] = useState('');
  const [dontclose, setDontclose] = useState(true);

  const continueToFinalPage = (phone) => {
    setPage(4);
    setPhone(phone);
    setDontclose(false);
  };

  const closeOtpSignIn = () => {
    props.onClose();
  };

  // for setting anonymous token

  const anonymous_token = JSON.parse(localStorage.getItem('token') || '{}').data
    ?.credAuthResult;

  useEffect(() => {
    anonymous_token;
  }, []);

  useEffect(() => {
    if (props.showOtpSignIn) {
      setPage(6);
    }
  }, [props.showOtpSignIn]);

  if (props.isOpen == true) {
    // console.log("issignup is opens");
    dispatch(setIsSignInOpen(true));
    // }else{
    // // if (props.onClose == true) {
    //   console.log("issignin is closed");
    //   dispatch(setIsSignInOpen(false))
  }

  return (
    <Modal
      isOpen={props.isOpen}
      closeOnOverlayClick={dontclose}
      onClose={() => {
        if (!page === 6) {
          setPage(1);
        }
        props.onClose();
        dispatch(setIsSignInOpen(false));
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        boxShadow={'none'}
        style={{ background: 'none' }}
        position="absolute"
        className="modal_position"
      >
        {(() => {
          switch (page) {
            case 1:
              return (
                <LoginSignup
                  {...LoginSignupData}
                  otpNav={otpNav}
                  onClose={onCloseModal}
                  setPage={setPage}
                  setPhone={setPhone}
                  anonymous_token={anonymous_token}
                />
              );
            case 2:
              return (
                <LoginOtp
                  {...LoginSignupData}
                  otpData={otpData}
                  onClose={onCloseModal}
                  setPage={setPage}
                  anonymous_token={anonymous_token}
                />
              );
            case 3:
              return (
                <SignUp
                  {...LoginSignupData}
                  otpNav={otpNav}
                  continueToFinalPage={continueToFinalPage}
                  setPage={setPage}
                  anonymous_token={anonymous_token}
                />
              );
            case 4:
              return (
                <FinalPage
                  {...LoginSignupData}
                  otpNav={otpNav}
                  onClose={onCloseModal}
                  setPage={setPage}
                  phone={phone}
                  anonymous_token={anonymous_token}
                />
              );
            case 5:
              return (
                <ForGotPage
                  {...LoginSignupData}
                  otpNav={otpNav}
                  onClose={onCloseModal}
                  setPage={setPage}
                  phone={phone}
                  anonymous_token={anonymous_token}
                />
              );
            case 6:
              return (
                <OtpLoginPage
                  {...LoginSignupData}
                  otpNav={otpNav}
                  onClose={closeOtpSignIn}
                  setPage={setPage}
                  anonymous_token={anonymous_token}
                  setPhone={setPhone}
                />
              );
            default:
              return null;
          }
        })()}
      </ModalContent>
    </Modal>
  );
};
