import {
  Box,
  Flex,
  List,
  Image,
  Text,
  Stack,
  Link,
  SimpleGrid,
  Container,
  GridItem,
  Grid,
  ListItem,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
// import { Copyright } from './Copyright'
// import { LinkGrid } from './LinkGrid'
// import { Logo } from './.Logo'
// import { SocialMediaLinks } from './SocialMediaLinks'
// import { SubscribeForm } from './SubscribeForm'
import './footer.css';
import bgimage from '../../assets/footer_img/Footer Blue.png';
import logo from '../../assets/footer_img/Logo.svg';
import playstore from '../../assets/footer_img/Playstore.png';
import applestore from '../../assets/footer_img/Applestore.png';
import playstoremob from '../../assets/footer_img/Playstore Mobile.png';
import applestoremob from '../../assets/footer_img/Applestore Mobile.png';
import razorpaylogo from '../../assets/footer_img/Razor Pay Badge copy.png';
import singleroundlogo from '../../assets/footer_img/Squar single rotor brakeup.png';

import card1 from '../../assets/footer_img/B_Visa.png';
import card2 from '../../assets/footer_img/B_Master Card.png';
import card3 from '../../assets/footer_img/B_Mastero.png';
import card4 from '../../assets/footer_img/B_Rupay.png';
import card5 from '../../assets/footer_img/B_American Express.png';
import card6 from '../../assets/footer_img/B_Discover.png';
import card7 from '../../assets/footer_img/B_Diners Club.png';
import card8 from '../../assets/footer_img/B_Cash on Delivery.png';
import card9 from '../../assets/footer_img/B_Net Banking.png';
import closemark from '../../assets/sarath_IMG/x.svg';
import { Dialog } from '@headlessui/react';

import { useHistory } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import ReactWhatsapp from 'react-whatsapp';
import Whatsapp_logo from '../../assets/sarath_IMG/Whatsapp_logo.png';
import { useEffect, useState, useRef } from 'react';
import EnquiryPopover from './EnquiryPopoverOnLogo';

export const Footer = () => {
  //policy mobilemodal
  const { isOpen, onOpen, onClose } = useDisclosure();

  let history = useHistory();

  let isloggedin = false;
  const token = JSON.parse(localStorage.getItem('token') || '{}').data?.credAuthResult ?? {};

  if (token.type == 'Access') {
    isloggedin = true;
  } else {
    isloggedin = false;
  }

  const onClickCategory = (value, label, name) => {
    let data = {
      category: value,
      label,
      name,
    };
    localStorage.setItem('filter', JSON.stringify(data));
    history.push(`/filter`);
  };

  let data = [
    {
      title: 'PRODUCTS',
      subelements: [
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() =>
            onClickCategory({
              value: 'Brake Pad',
              name: 'category',
              label: 'Brake Pad',
            })
          }>
          Brake Pads{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() =>
            onClickCategory({
              value: 'Brake Rotor',
              name: 'category',
              label: 'Brake Rotor',
            })
          }>
          Brake Rotor{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() =>
            onClickCategory({
              value: 'Brake Fluid',
              name: 'category',
              label: 'Brake Fluid',
            })
          }>
          Brake Fluid{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() =>
            onClickCategory({
              value: 'Other',
              name: 'category',
              label: 'Other',
            })
          }>
          Other Brake Products{' '}
        </Link>,
      ],
    },
    {
      title: 'BRANDS',
      subelements: [
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() =>
            onClickCategory({
              value: 'Motherson',
              name: 'brand',
              label: 'Motherson',
            })
          }>
          Motherson{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => onClickCategory({ value: 'Brembo', name: 'brand', label: 'Brembo' })}>
          Brembo{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => onClickCategory({ value: 'EBC', name: 'brand', label: 'EBC' })}>
          EBC{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => onClickCategory({ value: 'Hiq', name: 'brand', label: 'Hiq' })}>
          Hiq{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => onClickCategory({ value: 'Ate', name: 'brand', label: 'Ate' })}>
          Ate{' '}
        </Link>,
      ],
    },
    {
      title: 'ABOUT',
      subelements: [
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => history.push('/contactus')}>
          Contact Us{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => history.push('/aboutus')}>
          About Us{' '}
        </Link>,
        // <Link _hover={{ fontWeight: '600' }} className='font_Manrope footeritemsfont ' textTransform='capitalize' textOverflow='ellipsis' onClick={() => { }} >Career </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => {
            window.open('https://seller.brakeup.in/');
          }}>
          Sell on Brakeup{' '}
        </Link>,
      ],
    },
    {
      title: 'POLICY',
      subelements: [
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => history.push('/return&refundpolicy')}>
          Return & Refund Policy{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => history.push('/termsofuse')}>
          Terms Of Use{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => history.push('/privacypolicy')}>
          Privacy Policy{' '}
        </Link>,
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => history.push('/shipping')}>
          Shipping{' '}
        </Link>,
      ],
    },
    {
      title: 'LET US HELP YOU',
      subelements: [
        isloggedin && (
          <Link
            _hover={{ fontWeight: '600' }}
            className='font_Manrope footeritemsfont '
            textTransform='capitalize'
            textOverflow='ellipsis'
            onClick={() => history.push('/myprofile/6')}>
            Your Account{' '}
          </Link>
        ),
        <Link
          _hover={{ fontWeight: '600' }}
          className='font_Manrope footeritemsfont '
          textTransform='capitalize'
          textOverflow='ellipsis'
          onClick={() => history.push('/customercare')}>
          Customer Care{' '}
        </Link>,
        isloggedin && (
          <Link
            _hover={{ fontWeight: '600' }}
            className='font_Manrope footeritemsfont '
            textTransform='capitalize'
            textOverflow='ellipsis'
            onClick={() => history.push('/myprofile/4')}>
            Order Tracking{' '}
          </Link>
        ),
        // <Link _hover={{ fontWeight: '600' }} className='font_Manrope footeritemsfont ' textTransform='capitalize' textOverflow='ellipsis' onClick={() => { }} >FAQ </Link>,
      ],
    },
  ];

  let units = [];

  data.forEach((element) => {
    let listsubelements = [];
    element.subelements.forEach((elem, index) => {
      // console.log(elem,"footerlist");
      listsubelements.push(<div key={index}>{elem}</div>);
    });

    units.push(
      <Stack
        className='footerstackwidth'
        textAlign='left'
        _last={{ textAlign: 'right' }}>
        <Text
          className='font_Manrope footerheadfont'
          fontWeight='bold'
          textTransform='uppercase'>
          {element.title}
        </Text>
        <Flex flexDirection='column'>{listsubelements}</Flex>
      </Stack>
    );
  });

  let MyprofileMobileMarginBottom = ['/myprofilemobile'];
  let PolicyMobileMarginBottom = [
    '/shipping',
    '/termsofuse',
    '/privacypolicy',
    '/refundpolicy',
    '/replacemob',
    'contactus',
    '/customercare',
    '/aboutus',
    '/payment',
  ];
  const [isHovered, setIsHovered] = useState(false);
  let showWhatsAppURL = ['/cart', '/filter', '/product/']; //show whatsapp icon
  let showAssistanceMessage = ['/cart', '/filter', '/product/'];

  //show whatsapp icon

  const showmessageref = useRef();
  const onScreenmsg = useOnScreen(showmessageref, '0px');
  const showwhatsappref = useRef();
  const onScreen = useOnScreen(showwhatsappref, '-100px');

  function useOnScreen(ref, rootMargin = '0px') {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin,
        }
      );

      if (ref.current && window.IntersectionObserver) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current && observer) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, rootMargin]);

    return isIntersecting;
  }

  // enquiry chat popup

  const [displayMessage, setdisplayMessage] = useState(true);

  const closeMessage = () => {
    setdisplayMessage(false);
  };
  const onScreenmsgs = () => {
    if (showAssistanceMessage.some((el) => location.pathname.includes(el))) {
      return true;
    } else if (location.pathname == '/') return true;
    else return false;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setdisplayMessage(true);
    }, 50000); // Display the message after 50000 milliseconds (50 seconds)

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Box
        mt={7}
        boxShadow='0px -2px 20px #00000073'
        className='desktop_only DesktopFooter'>
        <Box
          className='footer1'
          bgColor='#FDFDFD'
          height='max-content'>
          <SimpleGrid
            className='gridgap'
            justifyItems='center'
            templateColumns={{ md: '1fr 1fr 1fr 1fr 1fr' }}>
            {units}
          </SimpleGrid>
        </Box>

        <Container
          maxW='100vw'
          backgroundImage={`url('${bgimage}')`}
          backgroundPosition='center'
          backgroundSize='cover'
          backgroundRepeat='no-repeat'
          ref={showmessageref}>
          <SimpleGrid
            alignItems='center'
            justifyItems='center'
            className='footer2'
            templateColumns='repeat(3, 1fr)'
            spacing={8}>
            <Stack>
              <Image
                onClick={() => history.push('/')}
                className='footerlogo'
                src={logo}
              />
            </Stack>
            <Stack width={'160px'}>
              <List
                className='font_Manrope footeradressfont'
                textColor='white'>
                <ListItem>
                  <Link
                    onClick={() => (window.location = 'mailto:care@brakeup.in')}
                    _hover={{ fontWeight: '600' }}>
                    Email: care@brakeup.in
                  </Link>
                </ListItem>
                <ListItem>
                  <Link _hover={{ fontWeight: '600' }}>Call: +91 70 2580 2580</Link>
                </ListItem>
                {/* <ListItem><Link _hover={{ fontWeight: '600' }} >Facebook</Link></ListItem>
                <ListItem><Link _hover={{ fontWeight: '600' }}>Instagram</Link></ListItem>
                <ListItem><Link _hover={{ fontWeight: '600' }}>Twitter</Link></ListItem>
                <ListItem><Link _hover={{ fontWeight: '600' }}>Youtube</Link></ListItem> */}
                {/* <ListItem >
                  <Image className='playstorelogo' mt={3} onClick={() => history.push('/')} src={playstore} />
                  <Image className='playstorelogo' mt={1} onClick={() => history.push('/')} src={applestore} />

                </ListItem> */}
              </List>
            </Stack>

            <Stack className='footeraddress'>
              <List
                className='font_Manrope footeradressfont'
                textAlign='right'
                textColor='white'>
                {/* <ListItem>REGISTERED ADDRESS    </ListItem> */}
                <ListItem>Brakeup Internet Technologies Private Limited</ListItem>
                <ListItem>42 High Street, 100 Feet Road,</ListItem>
                <ListItem>Indiranagar, Bangalore</ListItem>
                <ListItem>Karnataka 560038</ListItem>
                <ListItem>CIN: U72900KA2020PTC137670</ListItem>
              </List>
            </Stack>
          </SimpleGrid>
          {!onScreenmsg && onScreenmsgs() && <EnquiryPopover />}
        </Container>

        <Container
          height='4vh'
          maxW='100vw'>
          <SimpleGrid
            alignItems='center'
            justifyItems='center'
            h='4vh'
            templateColumns='repeat(3, 1fr)'
            spacing={8}>
            <Stack className='footer3_2020'>
              <Text className='font_NunitoUp footer_size'>
                {' '}
                © 2020 – {new Date().getFullYear()} BRAKEUP
              </Text>
            </Stack>
            <Stack>
              <Flex>
                <Image
                  className='footer_image'
                  src={card1}
                />
                <Image
                  className='footer_image'
                  src={card2}
                />
                <Image
                  className='footer_image'
                  src={card3}
                />
                <Image
                  className='footer_image'
                  src={card4}
                />
                <Image
                  className='footer_image'
                  src={card5}
                />
                <Image
                  className='footer_image'
                  src={card6}
                />
                <Image
                  className='footer_image'
                  src={card7}
                />
                <Image
                  className='footer_image'
                  src={card8}
                />
                <Image
                  className='footer_image'
                  src={card9}
                />
              </Flex>
            </Stack>
            <Stack className='footer3help'>
              <Flex>
                {' '}
                <Text
                  onClick={() => history.push('/helpcenter')}
                  cursor='pointer'
                  className='font_NunitoUp footer_size'>
                  HELP CENTER
                </Text>
                <Text
                  pl='2vw'
                  cursor='pointer'
                  className='font_NunitoUp footer_size footer_size_sell'
                  onClick={() => {
                    window.open('https://seller.brakeup.in/');
                  }}>
                  SELL ON BRAKEUP
                </Text>
              </Flex>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>

      <Box
        className='mobile_only footer_font'
        color='#B6B6B6'
        marginBottom={
          PolicyMobileMarginBottom.some((el) => location.pathname.includes(el))
            ? '0'
            : location.pathname == '/'
            ? '0'
            : '64px'
        }
        bgColor='#0C0E2D'
        borderTopRadius={'20px'}
        ref={showwhatsappref}>
        <Flex
          justifyContent='space-evenly'
          pt={'7vw'}>
          <Image
            maxHeight={'50px'}
            height={'10vw'}
            minHeight={'30px'}
            borderRadius='5px'
            onClick={() => history.push('/')}
            src={razorpaylogo}
          />
        </Flex>

        <Flex
          padding={'2vw'}
          justifyContent='space-evenly'
          alignItems='center'>
          {/* <Image
            // boxShadow="0px 0px 12px #f5f5f54d"
            maxHeight={"40px"}
            height={"10vw"}
            minHeight={"30px"}
            borderRadius='5px'
            onClick={() => history.push("/")}
            src={playstoremob}
          />
          <Image
            maxHeight={"40px"}
            height={"10vw"}
            minHeight={"30px"}
            borderRadius='5px'
            onClick={() => history.push("/")}
            src={applestoremob}
          /> */}
        </Flex>

        <Flex
          justifyContent='space-between'
          fontWeight={700}
          alignItems='center'
          className='font_Manrope'
          pl='5vw'
          pr='5vw'>
          <Text onClick={() => history.push('/contactus')}>CONTACT US</Text>
          <Text onClick={() => history.push('/aboutus')}>ABOUT US</Text>
          <Text
            onClick={() => {
              window.open('https://seller.brakeup.in/');
            }}>
            SELL ON BRAKEUP
          </Text>
        </Flex>

        <Flex
          pt={'3vw'}
          className='footer_font2 font_Manrope'
          justifyContent='space-between'
          fontWeight={500}
          alignItems='center'
          textAlign='center'
          pl='5vw'
          pr='5vw'>
          <Text onClick={() => history.push('/return&refundpolicy')}>Return & Refund</Text>
          <Text
            ml={2}
            mr={2}
            onClick={() => history.push('/termsofuse')}>
            Terms of Use
          </Text>
          <Text
            ml={2}
            mr={2}
            onClick={() => history.push('/privacypolicy')}>
            Privacy Policy
          </Text>
          <Text onClick={() => history.push('/shipping')}>Shipping Policy</Text>
        </Flex>

        <Box
          className='footer_font3'
          textAlign='center'
          textTransform='uppercase'
          mt={'7vw'}
          pb={'3vw'}>
          <Text>©2020–{new Date().getFullYear()}</Text>
          <Text>BRAKEUP Internet Technologies Private Limited </Text>
          <Text>42 High Street, Indiranagar, Bangalore, KA-CIN U72900KA2020PTC137670</Text>
        </Box>

        {/* <Flex justifyContent='space-between' alignItems='center' className='font_Nunito'
          height='26px' bgColor='#F1F1F1'
          pl="5vw" pr="5vw">
          <Text>©2020–22 BRAKEUP</Text>
          <Text onClick={() => { window.open("https://seller.brakeup.in/") }} >SELL ON BRAKEUP</Text>
        </Flex> */}

        {!onScreen && showWhatsAppURL.some((el) => location.pathname.includes(el)) && (
          <Box
            height={'40px'}
            borderRadius={'15px'}
            boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.75)'}
            position='fixed'
            zIndex={6}
            bottom={20}
            right={3}>
            <ReactWhatsapp
              number='+917025802580'
              message='Hi Brakeup...👋'>
              <Image
                height={'40px'}
                src={Whatsapp_logo}
              />
            </ReactWhatsapp>
          </Box>
        )}

        <AlertDialog
          motionPreset='slideInBottom'
          onClose={onClose}
          isOpen={isOpen}
          isCentered
          size={'xs'}>
          <AlertDialogOverlay />

          <AlertDialogContent padding={'.7rem'}>
            <AlertDialogCloseButton
              size='sm'
              color='var(--mountain-mist)'
            />

            <AlertDialogBody width='100%'>
              <VStack textAlign={'center'}>
                <Link
                  width='100%'
                  className='font_Manrope'
                  boxShadow='0px 2px 6px #00000029'
                  textTransform='capitalize'
                  textOverflow='ellipsis'
                  onClick={() => history.push('/return&refundpolicy')}>
                  Return & Refund Policy{' '}
                </Link>
                <Link
                  width='100%'
                  className='font_Manrope'
                  boxShadow='0px 2px 6px #00000029'
                  textTransform='capitalize'
                  textOverflow='ellipsis'
                  onClick={() => history.push('/termsofuse')}>
                  Terms Of Use{' '}
                </Link>
                <Link
                  width='100%'
                  className='font_Manrope'
                  boxShadow='0px 2px 6px #00000029'
                  textTransform='capitalize'
                  textOverflow='ellipsis'
                  onClick={() => history.push('/privacypolicy')}>
                  Privacy Policy{' '}
                </Link>
                <Link
                  width='100%'
                  className='font_Manrope'
                  boxShadow='0px 2px 6px #00000029'
                  textTransform='capitalize'
                  textOverflow='ellipsis'
                  onClick={() => history.push('/shipping')}>
                  Shipping{' '}
                </Link>
              </VStack>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      </Box>
    </div>
  );
};
