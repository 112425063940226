import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import NOTFOUNDweb from '../assets/sarath_IMG/404 Error page.jpg'
import NOTFOUNDmob from '../assets/sarath_IMG/404 Error page mobile.jpg'

export const NOTFOUND404 = (props) => {
    return (
        <>
            <Box width="100%" height='100vh'
                top={0} position='fixed' zIndex={10}>
                <Image objectFit='cover' width="100%" height="100vh" src={ window.innerWidth >= 799 ? NOTFOUNDweb: NOTFOUNDmob} />
            </Box>
        </>
    )
}
