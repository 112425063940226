import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { API_URL } from '../../../services/api';

export const MyGarageMob = (props) => {
  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <Text className="mob_address_head_font" mt="30px">
        My Garage
      </Text>
      <Box marginTop="50px" className="mob_rectangle-6045-qYBBoK">
        <lottie-player
          src={'https://api.dev.brakeup.in/api/v2/json/My_Garage.json'}
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </Box>
      <Box
        marginBottom="100px"
        className="mob_my-garage-will-be-rolled-out-soon-qYBBoK nunitosans-normal-shark-18px"
      >
        My Garage will be rolled out soon
      </Box>
    </Flex>
  );
};
