import React, { useEffect } from 'react';

import AddressData from '../Address/AddressData';
import { useSelector } from 'react-redux';
import { IncludeComaSeperator } from '../ProductDetailpage/pd';
import ProductData from '../cart/ProductData';
import { useHistory } from 'react-router';
import { get, post } from '../../services/api';
import {
  setFDticket,
  setcodDisabled,
} from '../../stateManagement/payment/paymentDetails';
import { useDispatch } from 'react-redux';
import { Meta, UUID } from '../../services/Meta-Pixel';
import loadFacebookPixels from '../../services/loadFacebookPixel';

const OrderSummary = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const selectedAddress = useSelector((state) => state.address.SelectedAddress);
  const priceDts = useSelector((state) => state.payment.paymentPriceDetails);
  const cartItems = useSelector((state) => state.payment.cartItems);

  useEffect(() => {
    let isMounted = true;
    const eventID = UUID();
    if (
      Object.keys(priceDts).length > 1 &&
      Object.keys(selectedAddress).length > 1 &&
      Object.keys(cartItems).length >= 1
    ) {
      window.scrollTo(0, 0);
      let data = {
        currency: 'INR',
        value: priceDts?.total,
        contents:
          cartItems &&
          cartItems.map((item) => ({
            id: item?.brakeup_part_no,
            quantity: item?.quantity,
            delivery_category: 'home_delivery',
          })),
      };
      loadFacebookPixels();

      isMounted && Meta('InitiateCheckout', data, eventID);

      if (typeof window !== 'undefined') {
        if (window.fbq != null && Object.keys(priceDts).length > 0) {
          window.fbq(
            'track',
            'InitiateCheckout',
            {
              value: priceDts?.total,
              currency: 'INR',
              contents:
                cartItems &&
                cartItems.map((item) => ({
                  id: item?.brakeup_part_no,
                  quantity: item?.quantity,
                })),
            },
            { eventID: eventID }
          );
        }
      }
    } else {
      isMounted && history.push('/address');
    }
    CheckCODdisabled();
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [priceDts]);

  const CheckCODdisabled = () => {
    get(`/api/v2/products/cod_users/`)
      .then((res) => {
        // console.log(res?.data[0]?.is_coddisabled, 'cod_userscod_users');
        dispatch(setcodDisabled(res?.data[0]?.is_coddisabled));
      })
      .catch((err) => {});
  };

  const IsCheckoutInitiated = () => {
    post(`/api/v2/products/reports_address/`, {
      cart_id: cartItems[0]['cart_id']['$oid'],
    })
      .then((res) => {
        // console.log(res, 'IsCheckoutInitiated');
        dispatch(setFDticket(res?.ticket_id));
      })
      .catch((err) => {});
  };

  let selctedAdress = [];
  selctedAdress.push(selectedAddress);

  return (
    <>
      <div className="max-w-full bg-white py-7 md:px-0">
        <div className="px-4 mx-auto md:px-16 lg:px-16 max-w-screen-2xl">
          <div className="pb-4 ">
            <div>
              <p className="block nxt_subheaders md:hidden">DELIVERY DETAILS</p>
              <p className="hidden nxt_subheaders md:block" id="checkoutTitle">
                ORDER SUMMARY
              </p>
              <div className="ccr_divider"></div>
              <div className="gap-5 md:flex md:w-full md:justify-between">
                <div className="hidden md:block">
                  <div
                    className=" flex flex-col gap-7
                  md:min-w-[330px] md:w-[46vw] md:max-w-[512px]
                  lg:min-w-[541px] lg:w-[53vw] lg:max-w-[718px]
                   xl:min-w-[717px] xl:w-[56vw] xl:max-w-[918px]
                  2xl:w-[833px]
                   "
                  >
                    <ProductData
                      title={['ORDER SUMMARY']}
                      cartItems={cartItems}
                      change_qty={false}
                    />
                  </div>
                </div>

                <div className="md:sticky md:top-0 md:h-max">
                  <div
                    className="add_font14   flex flex-col gap-3
                  md:w-[34vw] md:max-w-[349px]
                  lg:w-[31vw] lg:max-w-[400px]
                   xl:w-[29vw] xl:max-w-[442px]  2xl:w-[451px]  md:ml-auto"
                  >
                    <AddressData
                      title={'ORDER SUMMARY'}
                      addresses={selctedAdress}
                      center={false}
                      isSelectable={false}
                    />
                  </div>
                  <div className="block md:hidden">
                    <p className="block nxt_subheaders mt-14 md:hidden md:text-xl">
                      PRODUCT DETAILS
                    </p>
                    <div className="block ccr_divider md:hidden"></div>
                    <div className="flex flex-col mb-14 gap-7">
                      <ProductData
                        title={'DELIVERY DETAILS'}
                        cartItems={cartItems}
                        change_qty={false}
                      />
                    </div>
                  </div>

                  <div
                    className="hidden md:mt-10 md:block 
                   md:w-[34vw] md:max-w-[329px]
                  lg:w-[31vw] lg:max-w-[400px]
                   xl:w-[29vw] xl:max-w-[442px] 
                   2xl:w-[451px] md:ml-auto"
                  >
                    <p className="block nxt_subheaders mt-11 md:hidden md:text-xl">
                      PRICE DETAILS
                    </p>
                    <div className="block md:hidden ccr_divider"></div>
                    <div className="p-4 mb-6 cne_border add_font18">
                      <div className="grid gap-y-3.5">
                        <div className="flex justify-between">
                          <p>Total Items</p> <p>{priceDts?.total_items}</p>
                        </div>
                        <div className="flex justify-between">
                          <p>Total Price</p>{' '}
                          <p>{IncludeComaSeperator(priceDts?.mrp_total)}</p>
                        </div>

                        <div className="flex justify-between">
                          <p>Total Discount (-)</p>{' '}
                          <p>
                            {IncludeComaSeperator(priceDts?.total_discount)}
                          </p>
                        </div>

                        <div className="flex justify-between add_font20 font-bold">
                          <p>Total Payable</p>{' '}
                          <p className="font-bold">
                            {IncludeComaSeperator(priceDts?.total)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 w-full  ">
                      <div className="md:block">
                        <button
                          className="hidden md:px-2 large_button"
                          onClick={() => {
                            IsCheckoutInitiated(), history.push(`/payment`);
                          }}
                        >
                          <p className="add_font18-24 md:font-bold md:text-base lg:text-lg xl:text-xl md:pl-2.5 lg:pl-4 pl-6">
                            SELECT PAYMENT{' '}
                            <span className="font-normal">
                              ({IncludeComaSeperator(priceDts?.total)} Rs)
                            </span>
                          </p>
                          <svg
                            className="h-6 w-10 md:h-6 md:w-6 lg:h-8 lg:w-8 xl:h-9 xl:w-9 md:pr-1.5 lg:pr-3 pr-4 object-fit"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14 5l7 7m0 0l-7 7m7-7H3"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="block md:hidden">
                  <div className="md:mt-20  ">
                    <p className=" nxt_subheaders mt-14   md:text-xl">
                      PRICE DETAILS
                    </p>
                    <div className="  ccr_divider  "></div>

                    <div className="cne_border mb-6  p-4 add_font18">
                      <div className="grid gap-y-3.5">
                        <div className="flex justify-between">
                          <p>Total Items</p> <p>{priceDts?.total_items}</p>
                        </div>
                        <div className="flex justify-between">
                          <p>Total Price</p>{' '}
                          <p>{IncludeComaSeperator(priceDts?.mrp_total)}</p>
                        </div>

                        <div className="flex justify-between">
                          <p>Total Discount (-)</p>{' '}
                          <p>
                            {IncludeComaSeperator(priceDts?.total_discount)}
                          </p>
                        </div>

                        <div className="flex justify-between add_font20 font-bold">
                          <p>Total Payable</p>{' '}
                          <p className="font-bold">
                            {IncludeComaSeperator(priceDts?.total)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="add_font20 fixed_button md:hidden"
        onClick={() => {
          IsCheckoutInitiated(), history.push(`/payment`);
        }}
      >
        {' '}
        <p className="add_font18-24 font-bold md:text-base lg:text-lg xl:text-xl md:pl-2.5 lg:pl-4 pl-6">
          {' '}
          SELECT PAYMENT{' '}
          <span className="font-normal">
            ({IncludeComaSeperator(priceDts?.total)} Rs)
          </span>
        </p>
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          ></path>
        </svg>
      </button>
    </>
  );
};
export default OrderSummary;
