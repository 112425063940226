import { Flex, Image } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import AdBannerMob1 from '../assets/Ads/Mob/AdBannerMob1.jpg';
import AdBannerMob2 from '../assets/Ads/Mob/AdBannerMob2.jpg';
import AdBannerMob3 from '../assets/Ads/Mob/AdBannerMob3.jpg';
import AdBannerMob4 from '../assets/Ads/Mob/AdBannerMob4.jpg';
import AdBannerMob5 from '../assets/Ads/Mob/AdBannerMob5.jpg';

import YearEnd from '../assets/Ads/Year-End-Sale-Banner-Mobile.jpg';
import NewYear from '../assets/Ads/Newyear-Sale-Mobile-Banner.jpg';

export const AdsMob = (props) => {
  let history = useHistory();

  const token = JSON.parse(localStorage.getItem('token') || '{}').data
    ?.credAuthResult;

  // if (token && token.new_user_offer == true) {

  const promoAddClicked = () => {
    if (token && token.type == 'anonymous') {
      history.push(`/loginsignup`);
      setTimeout(() => {
        document.getElementById('createAccountMob').click();
      }, 100);
    }
  };

  if (token) {
    return (
      <></>
      // <Flex style={props.adStyle}
      // // onClick={promoAddClicked}
      // >
      //     {/* {props.AD2 ?
      //         <Image style={props.adStyleImg} loading='lazy' src={AdBannerMob2} />
      //         : props.AD3 ?
      //             <Image style={props.adStyleImg} loading='lazy' src={AdBannerMob3} />
      //             : props.AD4 ?
      //                 <Image style={props.adStyleImg} loading='lazy' src={AdBannerMob4} />
      //                 : props.AD5 ?
      //                     <Image style={props.adStyleImg} loading='lazy' src={AdBannerMob5} />
      //                     :
      //                     <Image style={props.adStyleImg} loading='lazy' src={AdBannerMob1} />
      //     } */}

      //     <Image style={props.adStyleImg} loading='lazy' src={NewYear} />
      // </Flex>
    );
  } else {
    return <></>;
  }
};
