import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Button,
  color,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
} from '@chakra-ui/react';
import { FormErrorMessage, FormLabel, FormControl } from '@chakra-ui/react';
import { API_URL, get, post } from '../../../services/api';
import Input, {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';

export const AddressFormMobile = (props) => {
  const [values, setValues] = useState();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({ mode: 'onChange', defaultValues: { address_type: 'other' } });
  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(props.editItems).length > 0) {
        setValue('address', props.editItems?.address);
        setValue('address2', props.editItems?.address2);
        setValue('address_type', props.editItems?.address_type);
        setValue('district', props.editItems?.district);
        setValue('email', props.editItems?.email);
        setValue('landmark', props.editItems?.landmark);
        setValue('mobile', props.editItems?.mobile);
        setValue('name', props.editItems?.name);
        setValue('pincode', props.editItems?.pincode);
        setValue('state', props.editItems?.state);
        // console.log(props.editItems, 'HEREEEEEEEEEEEEEEEEee', props)
      }
    }, 1000);
  }, [props.editItems]);

  return (
    <Drawer
      onClose={props.onClose}
      isOpen={props.isOpen}
      size="xl"
      closeOnOverlayClick={false}
      placement="bottom"
    >
      <DrawerOverlay style={{ backdropFilter: 'blur(2px)' }} />
      <DrawerContent className="mob_addressbox_1" padding="2vw">
        <DrawerCloseButton size="lg" zIndex={2} />
        <form>
          <DrawerBody marginTop="4rem">
            <Flex flexDirection="column">
              <Flex alignItems="flex-end">
                <Flex flexDirection="column">
                  <Flex justifyContent="space-between">
                    <Text whiteSpace="nowrap" className="mob_add_edit_adress">
                      ADD NEW ADDRESS
                    </Text>

                    <Box
                      position="absolute"
                      h="180px"
                      marginTop="-95px"
                      right="10px"
                      className="mob_lottie_address_moda"
                    >
                      <lottie-player
                        src={
                          'https://api.dev.brakeup.in/api/v2/json/Address_File.json'
                        }
                        background="transparent"
                        speed="1"
                        loop
                        autoplay
                        style={{ mixBlendMode: 'multiply' }}
                      ></lottie-player>
                    </Box>
                  </Flex>

                  <Flex>
                    <input
                      autofocus
                      width="60%"
                      className="mob_inputbox3"
                      type="number"
                      variant="outline"
                      placeholder="Pincode"
                      style={{ marginRight: '20px' }}
                      name="pincode"
                      ref={register({
                        required: true,
                        minLength: { value: 6 },
                        valueAsNumber: true,
                      })}
                    />

                    <Flex
                      boxShadow="0px 1px 4px #00000033"
                      alignItems="center"
                      zIndex={2}
                    >
                      <Box
                        bgColor="white"
                        borderRadius="2px"
                        paddingLeft="3px"
                        marginBottom="2px"
                        fontSize="0.8rem"
                      >
                        +{getCountryCallingCode('IN')}
                      </Box>

                      <input
                        style={{
                          paddingLeft: '5px',
                          marginTop: '0',
                          boxShadow: 'none',
                        }}
                        className="mob_inputbox2"
                        type="number"
                        variant="outline"
                        placeholder="Mobile number"
                        name="mobile"
                        ref={register({
                          required: true,
                          valueAsNumber: true,
                        })}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <VStack width="100%">
                <input
                  className="mob_inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Enter Full Name"
                  name="name"
                  ref={register({ required: true })}
                ></input>
                <input
                  className="mob_inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Address Line 1"
                  name="address"
                  ref={register({ required: true })}
                ></input>
                <input
                  className="mob_inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Address Line 2"
                  name="address2"
                  ref={register({ required: true })}
                ></input>
                <input
                  className="mob_inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Landmark"
                  name="landmark"
                  ref={register({ required: true })}
                ></input>
              </VStack>

              <Flex alignItems="flex-end">
                <input
                  style={{ marginRight: '20px' }}
                  className="mob_inputbox"
                  variant="outline"
                  placeholder="City"
                  name="district"
                  ref={register({ required: true })}
                ></input>
                <input
                  className="mob_inputbox"
                  variant="outline"
                  placeholder="State"
                  name="state"
                  ref={register({ required: true })}
                ></input>
              </Flex>
            </Flex>
          </DrawerBody>
          <DrawerFooter justifyContent="space-between">
            <FormControl>
              <Controller
                as={
                  <RadioGroup ref={register()} name="address_type">
                    <Stack direction="row">
                      <Radio
                        size="lg"
                        borderColor="#f9e107"
                        _before={{ border: '5px', borderColor: '#f9e107' }}
                        _focus={{ border: '5px', borderColor: '#f9e107' }}
                        value="home"
                      >
                        <Box
                          height="auto"
                          width="50px"
                          textAlign="center"
                          className="font_Nunito mob_radiobox"
                          borderRadius="8px"
                          bgColor={values == 'home' ? '#f9e107' : '#acacac'}
                          textColor={values == 'home' ? 'black' : 'white'}
                        >
                          {' '}
                          HOME
                        </Box>
                      </Radio>
                      <Radio
                        size="lg"
                        borderColor="#f9e107"
                        _before={{ border: '5px', borderColor: '#f9e107' }}
                        _focus={{ border: '5px', borderColor: '#f9e107' }}
                        value="office"
                      >
                        <Box
                          height="auto"
                          width="50px"
                          textAlign="center"
                          className="font_Nunito mob_radiobox"
                          borderRadius="8px"
                          bgColor={values == 'office' ? '#f9e107' : '#acacac'}
                          textColor={values == 'office' ? 'black' : 'white'}
                        >
                          {' '}
                          OFFICE
                        </Box>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                }
                name="address_type"
                control={control}
              />
            </FormControl>

            <Button
              className="mob_save_proceed_mob"
              bgColor="#ECE9D2"
              borderRadius="5px"
              fontWeight="400"
              h="35px"
              type="submit"
              onClick={handleSubmit((d) =>
                props.onSubmit(d, props?.editItems?._id?.$oid)
              )}
            >
              Save &amp; Proceed
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
};
