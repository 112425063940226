import React, { useState, useEffect } from 'react';
import { SavedList } from './SavedList';
import { GiftCoupons } from './Gift&Coupons';
import { MyGarage } from './MyGarage';
import { Notifications } from './Notifications';
import { MyProfileLayout } from './MyProfileLayout';
import { AddressBook } from './AddressBook';
import { OrderHistory } from './OrderHistory';
import { useHistory } from 'react-router-dom';
import { Profile } from './Profile';
import { useParams } from 'react-router-dom';

import { get } from '../../../services/api';

function MyProfile() {
  let history = useHistory();

  const [activeSection, setactiveSection] = useState(0);

  let { id } = useParams();
  useEffect(() => {
    if (id) {
      setactiveSection(id);
    }
  }, [id]);

  //    //bestfitforcart&saveditems
  //    const [bestItems, setBestItems] = useState([]);

  //    const getBestItems = (url) => {
  //     get(url).then(res => {
  //       let data = [...bestItems, ...res.data];
  //       setBestItems(data);
  //     }).catch(err => {
  //       setBestItems([]);
  //     })
  //   }

  //   const onRectBestClick = () => {
  //     let url = '/api/v2/products/cart/bestfit/';
  //     if (bestItems) {
  //       const lastItem = bestItems[bestItems.length - 1];
  //       url = `${url}?previousId=${lastItem["_id"]["$oid"]}`;
  //       url = `${url}&limit=10`;
  //       url = `${url}&order=1`;

  //     }
  //     getBestItems(url);
  //   }

  //recently_viewed

  const [reViewed, setReViewed] = useState([]);

  useEffect(() => {
    let recentUrl = '/api/v2/product/recently_viewed/';
    recentlyViewed(recentUrl);
    // let url='/api/v2/products/cart/bestfit/';
    // getBestItems(url)
  }, []);

  const recentlyViewed = (url) => {
    get(url)
      .then((res) => {
        let data = [...reViewed, ...res.data];
        setReViewed(data);
      })
      .catch((err) => {
        setReViewed([]);
      });
  };

  const onRectClick = () => {
    let url = '/api/v2/product/recently_viewed/';
    if (reViewed) {
      const lastItem = reViewed[reViewed.length - 1];
      url = `${url}?previousId=${lastItem['_id']['$oid']}`;
      url = `${url}&limit=10`;
      url = `${url}&order=1`;
    }
    recentlyViewed(url);
  };
  const ToProductDetailPage = (products) => {
    history.push(
      `/product/${products?.prod_id}${
        products.vehicle_details?.make_id
          ? '/' +
            products.vehicle_details?.make_id +
            '/' +
            products.vehicle_details?.model_id +
            '/' +
            products.vehicle_details?.year +
            (products.vehicle_details?.version
              ? '/' + products.vehicle_details?.version
              : '') +
            (products.vehicle_details?.fueltype
              ? '/' + products.vehicle_details?.fueltype
              : '')
          : ''
      }${
        products.vehicle_details?.type
          ? '/' + products.vehicle_details?.type
          : ''
      }`
    );
  };
  return (
    <MyProfileLayout
      activeSection={activeSection}
      setactiveSection={setactiveSection}
      onRectClick={onRectClick}
      reViewed={reViewed}
    >
      <SavedList />
      <AddressBook />
      <Notifications />
      <GiftCoupons />
      <OrderHistory ToProductDetailPage={ToProductDetailPage} />
      <MyGarage />
      <Profile />
    </MyProfileLayout>
  );
}

export default MyProfile;
