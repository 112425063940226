import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { API_URL } from '../../../services/api';
import { FiEdit, FiCalendar } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setAccess } from '../../../stateManagement/user/userSlice';

import moment from 'moment';

import rolledout from '../../../assets/sarath_IMG/bg_item_rolled_out_soon.png';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Deactivate1AreYou,
  Deactivate2OTP,
  Deactivate3Final,
  PasswordChanged,
  PasswordChangeError,
  ProfileModals,
  ToChangePassword,
} from './ProfileModals';

import { get, post } from '../../../services/api';
import { setUserName } from '../../../stateManagement/user/userSlice';

export const Profile = (props) => {
  const dispatch = useDispatch();

  const [value, onChange, formatLongDate] = useState(new Date());
  // const formatLongDate =()=>{
  //     (locale, date) => formatDate(date, 'dd MMM YYYY')

  // }
  // (locale, date) => formatDate(date, 'MMM')
  // modal content swapping

  const [modalContent, setModalContent] = useState(null);
  let history = useHistory();
  const changeModalContent = (value, type) => {
    setModalContent(type);
  };

  //Edit

  const [editDisplay, setEditDisplay] = useState(false);
  const [profile, setProfile] = useState({});
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({ mode: 'onChange' });

  //save data for editing

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(profile).length > 0) {
        setValue('name', profile?.name);
        setValue('email', profile?.email);
        setValue('dob', profile?.dob);
        setValue('mobile', profile?.mobile);
      }
    }, 1000);
  }, [editDisplay]);

  const onSubmit = (data) => {
    post('/api/v2/user/profileupdate/', data)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res));
        setEditDisplay(false);
        getProfile();
        dispatch(setUserName(data.name));
      })
      .catch((err) => {});
  };

  //modal

  const { isOpen, onOpen, onClose } = useDisclosure();

  //Alert Dialog PopUp
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const cancelRef = React.useRef();

  //toast

  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,

      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const getProfile = () => {
    get('/api/v2/user/profileupdate/')
      .then((res) => {
        setProfile(res);
      })
      .catch((err) => {
        setProfile({});
      });
  };
  useEffect(() => {
    getProfile();
  }, []);

  //OTP
  const [OTP, setOTP] = useState();

  const [errorMsg, seterrorMsg] = useState('');
  const changePassword = (d) => {
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    d.user_id = token?.data?.credAuthResult?.id;
    post('/api/v2/password_change/', d)
      .then((res) => {
        getProfile();
        setModalContent(2);
      })
      .catch((err) => {
        seterrorMsg(err?.response?.data?.message);
      });
    // console.log(errorMsg, "error");
  };

  return (
    <>
      <Flex flexDirection="column" width="100%">
        <Box className="profile">
          <Flex
            className="main_box_profile"
            justifyContent="space-between"
            boxShadow="0px 0px 15px #00000029"
          >
            <VStack alignItems="flex-start" width="100%">
              <HStack justifyContent="space-between" width="100%">
                <Text className="heading_profile manrope-bold-shark">
                  {' '}
                  Personal Information
                </Text>
                <Box>
                  {editDisplay ? (
                    <Button
                      _focus={{ bgColor: 'none' }}
                      onClick={handleSubmit((d) => onSubmit(d))}
                      className="button_edit_save"
                      bgColor="var(--royal-blue)"
                      _hover={{ bgColor: '#727be5' }}
                      color="white"
                    >
                      {' '}
                      Save
                    </Button>
                  ) : (
                    <Button
                      _focus={{ bgColor: 'none' }}
                      onClick={() => {
                        setEditDisplay(true);
                      }}
                      className="button_edit_save"
                      bgColor="var(--white)"
                    >
                      {' '}
                      <FiEdit style={{ marginRight: '10px' }} /> Edit
                    </Button>
                  )}
                </Box>
              </HStack>
              <Flex className="databox_profile" mt="100px" width="100%">
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  marginRight="20px"
                  className=" inputoutput nunitosans-normal-mountain-mist"
                >
                  <Text className="inputoutput_text">Name</Text>
                  <Text className="inputoutput_text">Email</Text>
                  <Text className="inputoutput_text">Dob</Text>
                  <Text className="inputoutput_text">Mobile</Text>
                </Flex>

                {editDisplay ? (
                  <form style={{ width: '100%' }}>
                    <Flex
                      flexDirection="column"
                      alignItems="flex-start"
                      className=" inputoutputbox nunitosans-normal-shark"
                    >
                      <input
                        name="name"
                        ref={register({ required: true })}
                        className="inputbox_profile"
                      />
                      <input
                        type="email"
                        name="email"
                        ref={register({ required: false })}
                        className="inputbox_profile"
                      />
                      <Flex width="100%" alignItems="center">
                        <input
                          type="date"
                          name="dob"
                          defaultValue="1990-01-01"
                          //  value={value}
                          ref={register({ required: false })}
                          className="inputbox_profile"
                          style={{ width: '40%' }}
                        />
                        {/* <FiCalendar style={{ marginLeft: '1rem', marginTop: '33px', fontSize: '1.4rem' }} /> */}
                        <Flex position="relative" width="350px">
                          {/* <Calendar formatLongDate={formatLongDate}  onChange={onChange} value={value} name="dob" ref={register({ required: false })} /> */}
                        </Flex>
                      </Flex>

                      <input
                        name="mobile"
                        ref={register({ required: true })}
                        className="inputbox_profile"
                      />
                    </Flex>
                  </form>
                ) : (
                  <Flex
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                    className=" inputoutput nunitosans-normal-shark"
                  >
                    <Text className="inputoutput_text">{profile?.name}</Text>
                    <Text className="inputoutput_text">{profile?.email}</Text>
                    <Text className="inputoutput_text">
                      {moment(profile?.dob).format('DD MMM YYYY')}
                    </Text>
                    <Text className="inputoutput_text">{profile?.mobile}</Text>
                  </Flex>
                )}
              </Flex>
            </VStack>
          </Flex>

          <Flex justifyContent="space-between" mt="3vw">
            <Button
              variant="ghost"
              colorScheme="blackAlpha"
              borderRadius="5px"
              className=" btn3 nunitosans-normal-mountain-mist"
              onClick={() => {
                onOpen();
                setModalContent(4);
              }}
            >
              Delete Account
            </Button>
            <Flex flexDirection="column" alignItems="flex-end">
              <Button
                borderRadius="5px"
                bgColor="var(--white)"
                boxShadow="0px 2px 6px #00000029"
                className="btn3 nunitosans-normal-mountain-mist"
                onClick={() => {
                  onOpen();
                  setModalContent(1);
                }}
              >
                Change Password{' '}
              </Button>
              <Button
                mt="1vw"
                borderRadius="5px"
                bgColor="var(--white)"
                boxShadow="0px 2px 6px #00000029"
                className="btn3 nunitosans-normal-mountain-mist"
                onClick={onOpenDelete}
              >
                Sign out
              </Button>
            </Flex>
          </Flex>
        </Box>

        <Box
          bgColor="var(--white)"
          flexDirection="column"
          mt="4vw"
          padding="30px"
        >
          <Text className="review_heading_profile manrope-bold-shark">
            Reviews
          </Text>
          <Flex
            bgImage={rolledout}
            bgSize="contain"
            bgRepeat="no-repeat"
            justifyContent="center"
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              className="review_box"
            >
              <Box width="60%">
                <lottie-player
                  src={
                    'https://api.dev.brakeup.in/api/v2/json/Gear_Spanner.json'
                  }
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </Box>
              <Text className="feature_rolled_out">
                THIS FEATURE WILL BE ROLLED OUT SOON
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      {/* all modal */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        isCentered
        autoFocus={false}
      >
        <ModalOverlay />
        {(() => {
          switch (modalContent) {
            case 1:
              return (
                <ToChangePassword
                  onClose={onClose}
                  cancelRef={cancelRef}
                  setModalContent={changeModalContent}
                  changePassword={changePassword}
                  errorMsg={errorMsg}
                  seterrorMsg={seterrorMsg}
                />
              );
            case 2:
              return (
                <PasswordChanged onClose={onClose} cancelRef={cancelRef} />
              );
            case 3:
              return (
                <PasswordChangeError onClose={onClose} cancelRef={cancelRef} />
              );
            case 4:
              return (
                <Deactivate1AreYou
                  onClose={onClose}
                  cancelRef={cancelRef}
                  setModalContent={setModalContent}
                />
              );
            case 5:
              return (
                <Deactivate2OTP
                  OTP={OTP}
                  setOTP={setOTP}
                  setModalContent={setModalContent}
                />
              );
            case 6:
              return (
                <Deactivate3Final onClose={onClose} cancelRef={cancelRef} />
              );
          }
        })()}
      </Modal>

      {/* delete popup */}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseDelete}
        isOpen={isOpenDelete}
        isCentered
        size={'lg'}
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor="var(--white-rock-2)">
          <AlertDialogCloseButton size="lg" color="var(--mountain-mist)" />

          <AlertDialogBody>
            <AlertDialogHeader className="nunitosans-normal-shark" mt="20px">
              Are you sure you want to Sign out ?
            </AlertDialogHeader>
          </AlertDialogBody>
          <AlertDialogFooter mb="2rem">
            <Button
              className=" alertbuttonfont nunitosans-normal-shark"
              onClick={() => {
                onCloseDelete();
                // if (!toast.isActive(id)) {
                //     toast({
                //         id,
                //         duration: 5000,
                //         position: "bottom",
                //         isClosable: true,
                //         containerStyle: {
                //             marginBottom: '15%',
                //         },

                //         render: () => (

                //             <Box color="blue" border="1px solid" className='toastbox' bgColor='white'>
                //                 <Flex justifyContent='space-between' alignItems='center'>
                //                     <FaInfoCircle className='toasticon' />
                //                     <Text className="font_NunitoA toastfont">Signed Out Successfully !</Text>
                //                     <IoClose className='toastclose' onClick={closeToast} />
                //                 </Flex>
                //             </Box>)
                //     })
                // };
                Toast2('Signed Out Successfully !', 'success', '#089520');
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('pincode');
                window.localStorage.removeItem('appliedpromocode');
                dispatch(setAccess(false));
                history.push('/');
                window.location.reload();
              }}
              bgColor="transparent"
              border="2px solid"
              borderRadius="5px"
              boxShadow="0px 3px 6px #00000029"
            >
              SIGN OUT
            </Button>

            <Button
              className="nunitosans-normal-white"
              ref={cancelRef}
              ml={3}
              borderRadius="5px"
              boxShadow={'0px 3px 6px #330d2f29'}
              bgColor={'var(--royal-blue)'}
              _hover={{ bgColor: '#727be5' }}
              onClick={() => {
                onCloseDelete();
              }}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
