import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom"
import App from "./App";
import "./index.css";
import { Provider } from "react-redux"
import { store } from "./stateManagement/store";

const rootElement = document.getElementById("root");
ReactDOM.render(
  
  <Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>,
  rootElement
);