import React, { useEffect, useState } from 'react';
import PaymentOptions from './payment_options';
import PayCODFee from './pay_on_verification2';
import PayOnVerification from './pay_on_verification';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setpaymentPriceDetails,
  setpaymentType,
  setorderDetails,
} from '../../stateManagement/payment/paymentDetails';
import { IncludeComaSeperator } from '../ProductDetailpage/pd';
import { post } from '../../services/api';

import logo from '../../assets/sarath_IMG/Brakeup Square Logo.png';
import loadFacebookPixels from '../../services/loadFacebookPixel';
import PayOnVerificationOTPdisabled from './pay_on_verification3';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';

const SelectPaymentMethod = (props) => {
  const [paymentMethod, setPaymentMethod] = useState(0);

  let history = useHistory();
  const dispatch = useDispatch();

  const selectedAddress = useSelector((state) => state.address.SelectedAddress);
  const priceDts = useSelector((state) => state.payment.paymentPriceDetails);
  const cartItems = useSelector((state) => state.payment.cartItems);
  const isCODdisabled = useSelector((state) => state.payment.codDisabled);
  const FDticket = useSelector((state) => state.payment.FDticket);
  const orderDetails = useSelector((state) => state.payment.orderDetails);

  // const isCODdisabled = true;

  const userName = useSelector((state) => state.user.name);
  const userMobile = useSelector((state) => state.user.mobile);
  const userEmail = useSelector((state) => state.user.email);

  useEffect(() => {
    let isMounted = true;

    if (
      Object.keys(priceDts).length > 1 &&
      Object.keys(selectedAddress).length > 1 &&
      Object.keys(cartItems).length >= 1
    ) {
      window.scrollTo(0, 0);
      loadFacebookPixels();
    } else {
      isMounted && history.push('/order-summary');
    }
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  let selctedAdress = [];
  selctedAdress.push(selectedAddress);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [orderID, setOrderID] = useState(null);

  const onlinePayment = () => {
    let data = {
      cart_id: cartItems[0]['cart_id']['$oid'],
      address_id: selectedAddress?._id['$oid'],
      payment_type: 'Online',
      promo_id: priceDts.promo_id ?? null,
      ticket_id: FDticket,
    };
    post('/api/v2/products/buynow/', data)
      .then((res) => {
        // console.log(data, 'onlinePayment')
        // history.push("/order-success");
        // console.log(res?.order_id, 'res?.order_id');
        setOrderID(res?.order_id);
        if (userMobile === '9895711494') {
          openPhonepay(res?.phone_pay_details);
        } else openRazorPayWindow(res?.razorpay_details?.id);
        dispatch(setpaymentType(data.payment_type));
        dispatch(setorderDetails(res));
      })
      .catch((err) => {});
  };

  const openPhonepay = (data) => {
    // console.log(data?.data, 'data?.instrumentResponse?.redirectInfo?.url');
    const redirectUrl = data?.data?.instrumentResponse?.redirectInfo?.url;
    window.location.href = redirectUrl;

    // onOpen();
    // setIframeSource(redirectUrl);
  };

  // useEffect(() => {
  //   window.addEventListener('message', handlePostMessage);
  //   return () => {
  //     window.removeEventListener('message', handlePostMessage);
  //   };
  // }, []);

  // const setIframeSource = (source) => {
  //   const iframe = document.getElementById('paymentIframe');
  //   if (iframe) {
  //     iframe.src = source;
  //   }
  // };

  // const handlePostMessage = (event) => {
  //   console.log(event, orderID, orderDetails.order_id, 'iframe event');

  //   if (event.origin === 'https://mercury-uat.phonepe.com') {
  //     const eventData = event.data;

  //     if (eventData.source === 'react-devtools-bridge' && eventData.payload) {
  //       console.log('Payment initiation event received!');
  //     } else if (typeof eventData === 'string') {
  //       try {
  //         const decodedData = JSON.parse(atob(eventData));
  //         console.log('reason:', decodedData);

  //         const bridgeContext = decodedData?.bridgeContext;

  //         if (bridgeContext && bridgeContext.code === 'USER_CANCEL') {
  //           console.log('User canceled the payment');
  //           onClose();
  //         }

  //         if (bridgeContext && bridgeContext.code === 'CONCLUDED') {
  //           // const decodedData = JSON.parse(atob(bridgeContext.token));
  //           onClose();
  //           console.log('payment done:', decodedData);
  //           paymentVerify();
  //         }
  //       } catch (error) {
  //         console.error('Error decoding cancellation data:', error);
  //       }
  //     }
  //   }
  // };

  // const paymentVerify = () => {
  //   console.log(orderID ?? orderDetails?.order_id, 'orderID ?? orderDetails?.order_id,');

  //   let id = orderID ? orderID : orderDetails?.order_id;

  //   post('/api/v2/payment/phonepe_paymentverification/', {
  //     order_id: id,
  //   })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         history.push('/order-success');
  //       } else {
  //         history.push('/order-failed');
  //       }
  //     })
  //     .catch((err) => {
  //       history.push('/order-failed');
  //     });
  // };

  const CODFeePayment = () => {
    // console.log(priceDts,"priceDtspriceDtspriceDts");
    let data = {
      cart_id: cartItems[0]['cart_id']['$oid'],
      address_id: selectedAddress?._id['$oid'],
      payment_type: 'COD',
      promo_id: priceDts.promo_id ?? null,
      ticket_id: FDticket,
    };
    post('/api/v2/products/buynow/', data)
      .then((res) => {
        // console.log(data, 'CODFeePayment');
        openRazorPayWindow(res?.razorpay_details?.id);
        dispatch(setpaymentType(data.payment_type));
        dispatch(setorderDetails(res));
      })
      .catch((err) => {});
  };
  const token = JSON.parse(localStorage.getItem('token') || '{}');

  const paymentSuccess = (data) => {
    data.cart_id = cartItems[0]['cart_id']['$oid'];
    data.user_id = token?.data?.credAuthResult?.id;
    dispatch(setpaymentPriceDetails(priceDts));
    post('/api/v2/payment/signature_check/', data)
      .then((res) => {
        // console.log(data, 'paymentSuccess')
        window.localStorage.removeItem('pincode');
        window.localStorage.removeItem('appliedpromocode');
        history.push('/order-success');
      })
      .catch((err) => {
        history.push('/order-failed');
      });
  };
  const openRazorPayWindow = (order_id) => {
    var options = {
      key: 'rzp_live_X3LkoOH3mjBza2', // Enter the Key ID generated from the Dashboard
      amount: '2500', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: 'Brakeup',
      description: 'Brakeup Online Transaction',
      image: logo,
      order_id: order_id,
      handler: (response) => {
        paymentSuccess(response);
      },
      prefill: {
        name: userName ? userName : '',
        email: userEmail ? userEmail : '',
        contact: userMobile ? userMobile : '',
      },
      notes: {
        address: 'BrakeUp, India',
      },
      theme: {
        color: '#ffe600',
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on('payment.authorized', function (response) {
      // console.log(response, 'Razorpay authorized')
    });
    rzp1.on('payment.failed', function (response) {
      // if (response.error?.description == "Network error"
      //   || response?.error?.reason == "input_validation_failed") {
      //   setPage(10)
      // } else {
      //   setPage(5)
      // }
      // alert(response.error.code);
      //   alert(response.error.description);
      //   alert(response.error.source);
      //   alert(response.error.step);
      //   alert(response.error.reason);
      //   alert(response.error.metadata.order_id);
      //   alert(response.error.metadata.payment_id);
      // console.log(response.error, 'Razorpay')
    });
  };

  const placeOrder = (otp) => {
    let data = {
      cart_id: cartItems[0]['cart_id']['$oid'],
      address_id: selectedAddress?._id['$oid'],
      payment_type: 'COD',
      otp: otp,
      promo_id: priceDts.promo_id ?? null,
      ticket_id: FDticket,
    };

    let priceDetails = { ...priceDts };
    priceDetails.total = priceDts.shiprocket_cod_total_amount;
    dispatch(setpaymentPriceDetails(priceDetails));
    dispatch(setpaymentType(data.payment_type));

    post('/api/v2/products/buynow/', data)
      .then((res) => {
        // console.log(res, "/api/v2/products/buynow/");
        // let data2 = { order_id: "123123", order: "456456" };
        // dispatch(setorderDetails(data2));

        dispatch(setorderDetails(res));
        window.localStorage.removeItem('pincode');
        window.localStorage.removeItem('appliedpromocode');
        history.push('/order-success');
      })
      .catch((err) => {
        history.push('/order-failed');
      });
  };

  const TriggerCODClick = () => {
    post('/api/v2/products/reports_cod_users/', {
      cart_id: cartItems[0]['cart_id']['$oid'],
    })
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <>
      {(() => {
        switch (paymentMethod) {
          case 1:
            return (
              <PayOnVerification
                selectedAddress={selctedAdress}
                cartItems={cartItems}
                priceDts={priceDts}
                onlinePayment={onlinePayment}
                CODFeePayment={CODFeePayment}
                placeOrder={placeOrder}
              />
            );
          default:
            return (
              <PaymentOptions
                onlinePayment={onlinePayment}
                setPaymentMethod={setPaymentMethod}
                priceDts={priceDts}
                isCODdisabled={isCODdisabled}
                IncludeComaSeperator={IncludeComaSeperator}
                TriggerCODClick={TriggerCODClick}
              />
            );
        }
      })()}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        // size='lg'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent bgColor="white">
          {/* <ModalCloseButton
            color='transparent'
            marginTop={'8px'}
          /> */}
          <iframe
            id="paymentIframe"
            style={{ minHeight: '70vh', borderRadius: '8px' }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectPaymentMethod;
