import { Box, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";

import FilterPageLayout from "./FilterPageLayout";
import { FilterPageLayoutMobile } from "./FilterPageLayoutMobile";
import { FilterPageLayoutMobile3 } from "./FilterPageLayoutMobile3";
import loadFacebookPixels from "../../services/loadFacebookPixel";

export const FilterPage = () => {
  const [mobileScreen, setmobileScreen] = useState(false);
  loadFacebookPixels();

  useEffect(() => {
    ReportWindowSize();
  }, []);
  function ReportWindowSize() {
    // console.log(window.innerWidth,);

    switch (true) {
      case window.innerWidth <= 799:
        setmobileScreen(true);
        break;

      default:
        setmobileScreen(false);
        break;
    }
  }
  window.onresize = ReportWindowSize;

  return (
    <>{mobileScreen ? <FilterPageLayoutMobile3 /> : <FilterPageLayout />}</>
  );
};
