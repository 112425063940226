import React, { useEffect, useState } from 'react';

import {
  Box,
  Input,
  Button,
  Image,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useToast,
  Heading,
  Textarea,
  Divider,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
} from '@chakra-ui/react';

import { FiEdit } from 'react-icons/fi';
import { CgClose } from 'react-icons/cg';

import './Replacement.css';
import {
  BankAccountDetails,
  BankAccountInput,
  RefundedMessage,
  RefundProceed,
  RequestMessage,
} from './ReplacementModals';
import { get, post } from '../../services/api';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useHistory } from 'react-router-dom';

export const ReplacementWeb = (props) => {
  let history = useHistory();

  // modal content swapping
  const [modalContent, setModalContent] = useState('');
  let { order_id, prod_id, vendor_id, user_id } = useParams();

  //modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [orderHistory, setOrderHistory] = useState([]);
  const getOrderHistory = () => {
    get(
      `/api/v2/products/buyer/buyer_replace_request/?order_id=${order_id}&prod_id=${prod_id}`
    )
      .then((res) => {
        setOrderHistory(res?.data);
        // console.log(res?.data, "setOrderHistory");
      })
      .catch((err) => {
        setOrderHistory([]);
      });
  };

  // Onsubmit

  const { handleSubmit, register, errors, control, reset } = useForm({
    mode: 'onSubmit',
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    setValue: setValue2,
  } = useForm({ mode: 'onSubmit' });

  // Refund Proceed popup

  const {
    isOpen: isOpenRefund,
    onOpen: onOpenRefund,
    onClose: onCloseRefund,
  } = useDisclosure();

  const {
    isOpen: isOpenEmailInput,
    onOpen: onOpenEmailInput,
    onClose: onCloseEmailInput,
  } = useDisclosure();

  const toast = useToast();

  // modal size

  let modalsize = '';
  if (modalContent == 1) {
    modalsize = '2xl';
  } else {
    modalsize = 'lg';
  }

  //is_onlineKey

  const [onlineKey, setonlineKey] = useState('');
  const [discountAmount, setdiscountAmount] = useState('');

  //save email for editing

  const token = JSON.parse(localStorage.getItem('token')).data.credAuthResult;
  const [descriptionData, setdescriptionData] = useState('');

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(token).length > 0) {
        setValue2('email', token?.email);
        // console.log(token?.email, "setEmail");
      }
    }, 1000);
  }, [descriptionData]);

  useEffect(() => {
    getOrderHistory();
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => {
    // Email  validations
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result == true) {
      return true;
    }
    return false;
  };

  const onSubmit = (des) => {
    setdescriptionData(des);
    onOpenEmailInput();
  };

  const ConfirmEmailAndData = (data) => {
    let input = {
      user_id: user_id,
      order_id: order_id,
      prod_id: prod_id,
      vendor_id: vendor_id,
      status: 'Request Placed',
      description: descriptionData.description,
      email: data.email,
    };
    setValue2('email', data?.email);
    // console.log(input, "ConfirmEmailAndData");
    post('/api/v2/products/buyer/buyer_replace_request/', input)
      .then((res) => {
        onCloseEmailInput();
        onOpen();
        setModalContent(1);
        getOrderHistory();
        reset({
          description: '',
        });
      })
      .catch((err) => {});
  };

  const checkPaymentMethod = () => {
    let input = {
      user_id: user_id,
      order_id: order_id,
      prod_id: prod_id,
      refund_process: 'true',
    };

    post('/api/v2/products/buyer/refund_process/', input)
      .then((res) => {
        onCloseRefund();
        if (onlineKey == true) {
          onOpen();
          setModalContent(4);
        } else {
          onOpen();
          setModalContent(2);
        }
      })
      .catch((err) => {});
  };

  const [accDtls, setAccDtls] = useState({});
  const getAccountDetails = (d) => {
    setAccDtls(d);
    setModalContent(3);
  };
  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        marginLeft={'10vw'}
        marginRight={'10vw'}
        mt="50px"
      >
        <Text className="replcmnt_headers">REPLACEMENT</Text>

        <Flex
          alignItems="center"
          bgColor="white"
          boxShadow=" 0px 2px 3px #00000029"
          minW="40%"
          borderRadius="5px"
          mt="30px"
        >
          <Text className="replcmnt_message">
            {' '}
            Replacement requested - product delivered is incompatible
          </Text>
        </Flex>

        <Flex mt="30px" mb="30px" width="100%">
          <form className="Replacement_form">
            <textarea
              size="md"
              className="descriptionbox"
              bgColor="white"
              border="none"
              placeholder="Type brief description..."
              name="description"
              ref={register({
                required: true,
              })}
            />

            <div style={{ textAlign: 'right', height: '30px' }}>
              {errors.description && (
                <span style={{ color: 'red' }}>This field is required</span>
              )}
            </div>

            <button
              disabled={
                orderHistory && orderHistory[0]?.replace_requested == true
                  ? true
                  : false
              }
              onClick={handleSubmit((d) => onSubmit(d))}
              className="submit_btn_replcmnt"
            >
              SUBMIT REQUEST
            </button>
          </form>
        </Flex>

        <Text mt="50px" className="replcmnt_headers">
          REPLACEMENT HISTORY
        </Text>

        <Table mt="100px" mb="50px" size={'lg'} colorScheme="black">
          <Thead fontSize="1.2rem">
            <Tr
              alignItems="center"
              bgColor="#959595"
              className="table_header_font"
            >
              <Th
                className="table_header_font"
                l
                whiteSpace="nowrap"
                padding="10px"
                borderRight="1px"
                borderRightColor="white"
                textTransform="capitalize"
              >
                Order ID
              </Th>
              <Th
                className="table_header_font"
                whiteSpace="nowrap"
                borderRight="1px"
                borderRightColor="white"
                textTransform="capitalize"
              >
                Product ID
              </Th>
              <Th
                className="table_header_font"
                whiteSpace="nowrap"
                borderRight="1px"
                borderRightColor="white"
                textTransform="capitalize"
              >
                Date & Time
              </Th>
              <Th
                className="table_header_font"
                whiteSpace="nowrap"
                borderRight="1px"
                borderRightColor="white"
                textTransform="capitalize"
              >
                Status
              </Th>
              <Th
                className="table_header_font"
                whiteSpace="nowrap"
                textTransform="capitalize"
              >
                Description
              </Th>
            </Tr>
          </Thead>

          {orderHistory.length != 0 ? (
            <Tbody className=" font_data_rpl nunitosans-semi-bold-shark">
              {orderHistory &&
                orderHistory.map((items) => {
                  return (
                    <Tr>
                      <Td
                        className="row_height"
                        height="5rem"
                        paddingInlineStart="20px"
                        paddingInlineEnd="20px"
                        whiteSpace="nowrap"
                      >
                        {items.order_id}
                      </Td>
                      <Td
                        className="row_height"
                        paddingInlineStart="20px"
                        paddingInlineEnd="20px"
                        whiteSpace="nowrap"
                      >
                        {' '}
                        {items.prod_name}
                      </Td>
                      <Td
                        className="row_height"
                        paddingInlineStart="20px"
                        paddingInlineEnd="20px"
                        whiteSpace="nowrap"
                      >
                        {moment(
                          items?.order_status_history[0]?.created_at['$date']
                        ).format('DD MMM YYYY hh:mm a')}
                      </Td>
                      <Td
                        className="row_height"
                        paddingInlineStart="20px"
                        paddingInlineEnd="none"
                      >
                        {items?.order_status_history[0]?.status}
                      </Td>
                      {/* <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='20px' ></Td> */}
                      <Td
                        className="row_height"
                        paddingInlineStart="20px"
                        paddingInlineEnd="none"
                      >
                        <Flex justifyContent="space-between">
                          <Text>
                            {items?.order_status_history[0]?.description}
                          </Text>
                          <button
                            onClick={() => {
                              onOpenRefund();
                              setonlineKey(items.is_online);
                              setdiscountAmount(items.refund_amount);
                            }}
                            className="btn_in"
                          >
                            {' '}
                            Proceed
                          </button>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}

              {/* <Tr >
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='20px' whiteSpace='nowrap'>09-33883883-0999</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='20px' whiteSpace='nowrap' > BRQ332233</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='20px' whiteSpace='nowrap' >12-01-2022 12:50PM</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='none' > Replacement order placed</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='none' >
                            <Flex justifyContent='space-between'>
                                <Text >
                                    Ordered product has been cancelled and we are initiating a refund
                                </Text>
                                <button className="btn_in"> view</button>

                            </Flex>
                        </Td>
                    </Tr>

                    <Tr >
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='20px' whiteSpace='nowrap'>09-33883883-0999</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='20px' whiteSpace='nowrap' > BRQ332233</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='20px' whiteSpace='nowrap' >12-01-2022 12:50PM</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='none' > Replacement order placed</Td>
                        <Td className="row_height" paddingInlineStart='20px' paddingInlineEnd='none' >
                            <Flex justifyContent='space-between'>
                                <Text >
                                    Ordered product has been cancelled and we are initiating a refund
                                </Text>
                                <button onClick={onOpenRefund} className="btn_in"> Proceed</button>

                            </Flex>
                        </Td>
                    </Tr> */}
            </Tbody>
          ) : (
            <TableCaption mt="20px">
              <Text
                className="row_height  font_data_rpl"
                fontFamily="var(--font-family-nunito_sans)"
                fontWeight={600}
                color="black"
              >
                Product was never replaced{' '}
              </Text>
              <Divider mt="20px" borderColor="black" border="0.5px solid" />
            </TableCaption>
          )}
        </Table>

        <Flex width="100%" mb="100px" justifyContent="flex-end">
          <button
            onClick={() => history.push('/customercare')}
            className="btn_cus_care"
          >
            CONTACT CUSTOMER CARE
          </button>
        </Flex>
      </Flex>

      {/* all modal */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={modalsize}
        isCentered
        autoFocus={false}
      >
        <ModalOverlay />
        {(() => {
          switch (modalContent) {
            case 1:
              return <RequestMessage onClose={onClose} cancelRef={cancelRef} />;
            case 2:
              return (
                <BankAccountInput
                  onClose={onClose}
                  cancelRef={cancelRef}
                  setModalContent={setModalContent}
                  getAccountDetails={getAccountDetails}
                />
              );
            case 3:
              return (
                <BankAccountDetails
                  onClose={onClose}
                  cancelRef={cancelRef}
                  setModalContent={setModalContent}
                  accDtls={accDtls}
                />
              );
            case 4:
              return (
                <RefundedMessage
                  onClose={onClose}
                  cancelRef={cancelRef}
                  discountAmount={discountAmount}
                />
              );
          }
        })()}
      </Modal>

      {/* Refund Proceed popup */}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseRefund}
        isOpen={isOpenRefund}
        isCentered
        size={'md'}
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor="var(--white-rock-2)">
          <AlertDialogCloseButton size="sm" color="var(--mountain-mist)" />

          <AlertDialogBody
            mb="40px"
            mt="10px"
            display="flex"
            justifyContent="center"
          >
            <Text className="m1font nunitosans-normal-melanzane" mt="40px">
              Refund amount Rs {discountAmount} to source of payment?
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter mb="2rem">
            <Button
              className=" refundCancelBtn nunitosans-normal-shark"
              onClick={() => {
                onCloseRefund();
              }}
              size="sm"
              bgColor="transparent"
              border="2px solid"
              borderRadius="5px"
              boxShadow="0px 3px 6px #00000029"
            >
              CANCEL
            </Button>

            <Button
              className=" refundProceedBtn nunitosans-normal-white"
              size="sm"
              ref={cancelRef}
              ml={3}
              borderRadius="5px"
              boxShadow={'0px 3px 6px #330d2f29'}
              bgColor={'var(--royal-blue)'}
              _hover={{ bgColor: '#727be5' }}
              onClick={() => {
                checkPaymentMethod();
              }}
            >
              PROCEED
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onCloseEmailInput}
        isOpen={isOpenEmailInput}
        isCentered
        size={'md'}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor="var(--white-rock-2)" alignItems="center">
          <AlertDialogCloseButton size="sm" color="var(--mountain-mist)" />
          <AlertDialogHeader mt="20px">Confirm Email ?</AlertDialogHeader>

          <AlertDialogBody
            style={{ width: '90%' }}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <form style={{ width: '100%', height: '71px' }}>
              <input
                type="email"
                name="email"
                autoFocus={true}
                ref={register2({ required: true, validate: validateEmail })}
                className="inputbox_addAccDetails"
                placeholder="Enter Email"
              ></input>

              {errors2.email &&
              errors2.email.type &&
              errors2.email.type == 'required' ? (
                <span style={{ color: 'red' }}>email is required</span>
              ) : (
                ''
              )}
              {errors2.email &&
              errors2.email.type &&
              errors2.email.type == 'validate' ? (
                <span style={{ color: 'red' }}>invalid email</span>
              ) : (
                ''
              )}
            </form>
          </AlertDialogBody>
          <AlertDialogFooter mb="2rem">
            <Button
              className=" refundProceedBtn nunitosans-normal-white"
              size="sm"
              ref={cancelRef}
              ml={3}
              borderRadius="5px"
              boxShadow={'0px 3px 6px #330d2f29'}
              bgColor={'var(--royal-blue)'}
              _hover={{ bgColor: '#727be5' }}
              onClick={handleSubmit2((d) => ConfirmEmailAndData(d))}
            >
              SUBMIT
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
