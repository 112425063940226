import React, { useEffect, useState, useRef } from 'react';

import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Select,
  useDisclosure,
  InputGroup,
  Input,
  InputRightElement,
} from '@chakra-ui/react';

import './css/MyProfileMobileLayout.css';
import '../MyProfilePageLayout/css/myprofilestyleguide.css';

import { VscAccount } from 'react-icons/vsc';
import { IoBookmarkOutline } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { FiGift } from 'react-icons/fi';
import { VscHistory } from 'react-icons/vsc';
import { GiHomeGarage } from 'react-icons/gi';
import { FiLogOut } from 'react-icons/fi';

import { CgClose } from 'react-icons/cg';

import logo from '../../../assets/sarath_IMG/logo_icon.svg';
import search from '../../../assets/sarath_IMG/search2.png';
import orderbox from '../../../assets/sarath_IMG/Mobilefooter_orderbox.svg';
import customercare from '../../../assets/sarath_IMG/Mobilefooter_customer_care.svg';

import BestFitForItems from '../../BestFitForItems/BestFitForItems';
import RecentView from '../../FilterPage/RecentView';
import { useHistory } from 'react-router-dom';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { Mobilesearch } from '../../HomePage/Mobilesearch';

export const MyProfileMobileLayout = (props) => {
  let history = useHistory();

  var active_child = props.children[props.activeSection];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <>
      <Box width={'100%'} height={'max-content'} bgColor='white'>
        <Flex>{active_child}</Flex>

        <Flex
          mb='4vh'
          mt='20px'
          gridArea='recent_items'
          justifyContent='center'
        >
          <BestFitForItems />
        </Flex>

        <Flex
          className='recent'
          alignSelf='center'
          gridArea='recent_items'
          justifyContent='center'
        >
          <RecentView
            width='80%'
            reViewed={props.reViewed}
            onRectClick={props.onRectClick}
          />
        </Flex>

        <Flex
          width='100%'
          position='fixed'
          bottom='0'
          bg={'white'}
          justifyContent='space-evenly'
          height={'64px'}
        >
          <Flex
            onClick={() => history.push('/')}
            borderTopLeftRadius='8px'
            justifyContent='space-between'
            flexDirection='column'
            alignItems='center'
          >
            <Box
              borderBottomLeftRadius={'5px'}
              borderBottomRightRadius='5px'
              bgColor='var(--turbo)'
              height={'4px'}
              width='60px'
            />

            <Image marginBottom={'15px'} width={'20px'} src={logo} />
          </Flex>

          <Flex
            onClick={onOpen}
            borderTopLeftRadius='8px'
            justifyContent='space-between'
            flexDirection='column'
            alignItems='center'
          >
            <Box
              borderBottomLeftRadius={'5px'}
              borderBottomRightRadius='5px'
              bgColor='var(--turbo)'
              height={'4px'}
              width='60px'
            />

            <Image
              marginBottom={'15px'}
              objectFit={'contain'}
              width={'20px'}
              src={search}
            />
          </Flex>
          <Flex
            onClick={() => history.push('/myprofilemobile/4')}
            borderTopLeftRadius='8px'
            justifyContent='space-between'
            flexDirection='column'
            alignItems='center'
          >
            <Box
              borderBottomLeftRadius={'5px'}
              borderBottomRightRadius='5px'
              bgColor='var(--turbo)'
              height={'4px'}
              width='60px'
            />

            <Image
              marginBottom={'15px'}
              objectFit={'contain'}
              width={'20px'}
              src={orderbox}
            />
          </Flex>
          <Flex
            onClick={() => history.push('/customercare')}
            borderTopLeftRadius='8px'
            justifyContent='space-between'
            flexDirection='column'
            alignItems='center'
          >
            <Box
              borderBottomLeftRadius={'5px'}
              borderBottomRightRadius='5px'
              bgColor='var(--turbo)'
              height={'4px'}
              width='60px'
            />

            <Image
              marginBottom={'15px'}
              objectFit={'contain'}
              width={'20px'}
              src={customercare}
            />
          </Flex>
        </Flex>
      </Box>

      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
      >
        <AlertDialogOverlay style={{ backdropFilter: 'blur(3px)' }} />

        <AlertDialogContent m='30px 0px' bgColor='transparent' boxShadow='none'>
          <AlertDialogBody bgColor='none' position='fixed' width='100%'>
            <Mobilesearch onClose={onClose} />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
