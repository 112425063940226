import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isSignInOpen: false,
}

export const signInIsOpenSlice = createSlice({
    name: 'isSignInOpen',
    initialState,
    reducers: {
        setIsSignInOpen: (state, action) => {
            state.isSignInOpen = action.payload
        }

    },
})

// Action creators are generated for each case reducer function
export const { setIsSignInOpen } = signInIsOpenSlice.actions

export default signInIsOpenSlice.reducer