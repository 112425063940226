import React, { useEffect, useState } from "react";

import { Box, Button, Image, Flex, InputGroup, InputLeftElement, Text, useDisclosure, Radio, RadioGroup, Stack, VStack, useToast, Heading, Textarea, Divider } from "@chakra-ui/react";




export const ReturnRefundPolicy = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <Flex mt="100px" mb="100px" className="refundPolicy font_NunitoA" textAlign='justify' mr="15vw" ml="15vw" flexDirection='column'>
            <Text alignSelf='center'>
                <p><b>RETURN AND REFUND POLICY</b></p>
            </Text>

            <Text mt="30px" mb='10px'>
                <p>RETURNS</p>
            </Text>
            <p>Our policy lasts 14 days. If 14 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange. Since some products we sell are wear and tearable, to be eligible for a return, your item must be incompatible to vehicle selected, unused, unsoiled and in the same condition that you received it. It must be resell able and also be in the original brand packaging. Brakeup reserves the right to refuse any returns that do not meet these requirements, or that are otherwise not eligible for return. Returns will not be applicable if the product would function as intended and any issues it may have do not affect its intended performance.</p>
            {/* <p>Our policy lasts 14 days. If 14 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange. Since some products we sell are wear and tearable, to be eligible for a return, your item must be incompatible to vehicle selected, unused, unsoiled and in the same condition that you received it. It must be resell able and also be in the original brand packaging.</p> */}
            {/* <p>To initiate and complete your return, use the process on the orders page. Please do not send your purchase back to the manufacturer.</p> */}

            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>REFUNDS (if applicable)</p>
            </Text>
            <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, onward and return shipping will be deducted and a credit will automatically be applied to your original method of payment, or to your bank account in case of cash on delivery within 30 days, or as a non transferable credit coupon.</p>
            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Late or missing refunds (if applicable)</p>
            </Text>
            <p>If you haven’t received a refund yet, first check your bank account again. It may take some time before your refund is officially posted. Next contact your bank. There is often some processing time before a refund is posted. If you’ve done all of this and you still have not received your refund yet, please contact us at care@brakeup.in.</p>
            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Sale items (if applicable)</p>
            </Text>
            <p>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</p>
            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Exchanges (if applicable)</p>
            </Text>
            <p>We only replace items if they are incompatible with your vehicle, defective or damaged while delivered.  If you need to exchange it for the same item, follow the process on the orders page</p>

            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Shipping</p>
            </Text>
            <p>Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund. Depending on where you live, the time it may take for your exchanged product to reach you, may vary.</p>

        </Flex>
    )
}