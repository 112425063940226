import React, { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Grid,
  Text,
  Image,
  Spacer,
  IconButton,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'
import { FaStar } from 'react-icons/fa'
import './BestFitForItems.css'
import { AddIcon } from '@chakra-ui/icons'

import { useHistory } from 'react-router-dom'
import { get, post } from '../../services/api'

import { useSelector, useDispatch } from 'react-redux'
import { setupdateCart } from '../../stateManagement/user/cartCountSlice'

import REAR from '../../assets/sarath_IMG/REAR_FLIPPED.png'
import FRONT from '../../assets/sarath_IMG/FRONT_FLIPPED.png'

function BestFitForItemDisplay(props) {
  let items = []

  props.bestItems &&
    props.bestItems.forEach((element) => {
      items.push(
        <Box
          className="item_box recent_box"
          bg="#FFFFFF"
          height="max-content"
          position="relative"
          _hover={{
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
            transitionDelay: '.5s',
          }}
          mr={5}
          mt={3}
          mb={3}
          p="1rem"
          borderRadius="lg"
        >
          {element?.axle && (
            <Flex
              className="FrontRearIconBox desktop_only"
              justify="flex-end"
              position="absolute"
              left={0}
              zIndex={1}
              mt={1}
            >
              {element?.axle == 'Front' ? (
                <Image
                  minWidth="40px"
                  width="4vw"
                  maxWidth="70px"
                  src={FRONT}
                />
              ) : element?.axle == 'Rear' ? (
                <Image minWidth="40px" width="4vw" maxWidth="70px" src={REAR} />
              ) : (
                ''
              )}
            </Flex>
          )}

          <Flex justify="flex-end" className="desktop_only">
            {element?.vendor_products?.selling_price ? (
              <IconButton
                zIndex={4}
                className="plus_icon"
                size="md"
                textAlign="center"
                fontSize="7px"
                bgColor="#FFFFFF"
                borderRadius="4px"
                boxShadow="0px 0px 4px #00000033"
                aria-label="Search database"
                icon={<AddIcon />}
                onClick={(e) => {
                  e.stopPropagation(), props.Add2Cart(element)
                }}
              />
            ) : (
              <Box className="plus_icon" height={10}></Box>
            )}
          </Flex>
          <Box
            className="recent_image image_transition"
            onClick={() => props.onBoxClick(element)}
            p={1}
            position="relative"
            justifyContent="center"
            backgroundImage={`url('${element.image[0]}')`}
            backgroundPosition="center"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
          >
            <Flex justify="flex-end" className="plus_icon_recent">
              {element?.vendor_products?.selling_price && (
                <IconButton
                  className="plus_icon"
                  size="md"
                  textAlign="center"
                  fontSize="7px"
                  bgColor="#FFFFFF"
                  borderRadius="4px"
                  boxShadow="0px 0px 4px #00000033"
                  aria-label="Search database"
                  icon={<AddIcon />}
                  onClick={(e) => {
                    e.stopPropagation(), props.Add2Cart(element)
                  }}
                />
              )}
              {element?.axle && (
                <Box className="mobile_only">
                  <Flex
                    className="FrontRearIconBox"
                    justify="flex-end"
                    position="absolute"
                    left={0}
                    zIndex={1}
                  >
                    {element?.axle == 'Front' ? (
                      <Image
                        minWidth="40px"
                        width="4vw"
                        maxWidth="70px"
                        src={FRONT}
                      />
                    ) : element?.axle == 'Rear' ? (
                      <Image
                        minWidth="40px"
                        width="4vw"
                        maxWidth="70px"
                        src={REAR}
                      />
                    ) : (
                      ''
                    )}
                  </Flex>
                </Box>
              )}
            </Flex>
          </Box>
          <Box>
            <Spacer />
            <Grid
              visibility={'hidden'}
              className="rating_box"
              justifyItems="flex-end"
            >
              <Flex
                width="auto"
                justifyContent="end"
                alignItems="center"
                className="ratingbox"
                bgColor={element.rating > 3.0 ? '#279043' : '#905827'}
              >
                <Box>{element.rating ? element?.rating.toFixed(1) : ''}</Box>
                <Box pl="2px" pr="2px">
                  {' '}
                  <FaStar />
                </Box>
              </Flex>
              <Box className="reviewCount"> {element.reviewCount} Ratings</Box>
            </Grid>
            <Flex alignItems="end" className="companyname">
              {element.brand}
            </Flex>

            <Box className="title">{element.prod_name}</Box>
            <Flex alignItems="center" h="30px" overflow="hidden">
              <Image
                className="recentlogo"
                src={element?.vehicle_details?.logo}
              />
              <Text className="titledetails">
                {' '}
                {element?.vehicle_details?.model}{' '}
                {element?.vehicle_details?.version}{' '}
                {element?.vehicle_details?.year}{' '}
                {element?.vehicle_details?.fueltype}
              </Text>
            </Flex>
            <Flex
              className="item_box_price"
              alignItems="center"
              width="100%"
              justifyContent="flex-start"
              whiteSpace={'nowrap'}
            >
              <Box className="sellingPrice">
                Rs. {element?.vendor_products?.selling_price ?? element.mrp}{' '}
              </Box>
              {element?.vendor_products?.discount ? (
                <>
                  <Box pl={2} className="costPrice">
                    {' '}
                    ₹{element.mrp}
                  </Box>
                  {element?.vendor_products?.discount.toFixed(0) != 0 && (
                    <Box pl={2} className="discountPercentage">
                      (
                      {element?.vendor_products?.discount
                        ? element?.vendor_products?.discount.toFixed(0)
                        : ''}
                      % OFF)
                    </Box>
                  )}
                </>
              ) : (
                ''
              )}
            </Flex>
            <Flex className="MobSellingPrice font_Nunito" display="none">
              <Box>
                Rs. {element?.vendor_products?.selling_price ?? element.mrp}{' '}
              </Box>
            </Flex>
          </Box>
        </Box>,
      )
    })

  return <Flex gridTemplateColumns="1fr 1fr 1fr 1fr">{items}</Flex>
}

export default BestFitForItemDisplay
