import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("#F5F5F5")(props),
        fontFamily: "normal normal normal 16px/15px Nunito Sans",

        // scrollbarColor: "#6b6b6b #2b2b2b",
        // "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        //   backgroundColor: "#2b2b2b",
        // },
        // "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        //   borderRadius: 8,
        //   backgroundColor: "#6b6b6b",
        //   minHeight: 24,
        //   border: "3px solid #2b2b2b",
        // },
        // "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        //   backgroundColor: "#959595",
        // },
        // "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
        //   backgroundColor: "#959595",
        // },
        // "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        //   backgroundColor: "#959595",
        // },
        // "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        //   backgroundColor: "#2b2b2b",
        // },
      },
      html: {
        fontFamily: "normal normal normal 16px/15px Nunito Sans"

      }
    }),
  },
};
