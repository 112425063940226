export function Add2CartGtag(item, vehicle) {
  if (window.gtag != null) {
    // //     // Google tag (gtag.js) 15JUN2023 //
    window.gtag(
      //   console.log(
      "event",
      "add_to_cart",
      {
        currency: "INR",
        value: item?.vendor_products?.selling_price,
        items: [
          {
            item_id: item?.brakeup_part_no,
            item_name: item?.prod_name,
            affiliation: "Brakeup",
            discount: item?.vendor_products?.discount_price ?? 0,
            item_brand: item?.brand,
            item_category: item?.category ?? "",
            item_list_name: item?.vehicle_details?.make
              ? item?.vehicle_details?.make +
                " " +
                item?.vehicle_details?.model +
                " " +
                item?.vehicle_details?.year
              : vehicle
              ? vehicle
              : "",
            price: item?.mrp ?? item?.MRP,
            quantity: 1,
          },
        ],
      }
    );
  }
}

export function Save4LaterGtag(item, vehicle) {
  //   console.log(item, "amountamountt");

  if (window.gtag != null) {
    // //     // Google tag (gtag.js) 15JUN2023 //
    window.gtag(
      // console.log(
      "event",
      "add_to_wishlist",
      {
        currency: "INR",
        value: item?.vendor_products?.selling_price ?? item?.MRP,
        items: [
          {
            item_id: item?.brakeup_part_no,
            item_name: item?.prod_name,
            affiliation: "Brakeup",
            discount: item?.vendor_products?.discount_price ?? 0,
            item_brand: item?.brand,
            item_category: item?.category ?? "",
            item_list_name: item?.vehicle_details?.make
              ? item?.vehicle_details?.make +
                " " +
                item?.vehicle_details?.model +
                " " +
                item?.vehicle_details?.year
              : vehicle != undefined
              ? vehicle
              : "",
            price: item?.mrp ?? item?.MRP,
            quantity: 1,
          },
        ],
      }
    );
  }
}

export function Cart_Add2CartGtag(item) {
  //   console.log(item, "amountamountt");

  if (window.gtag != null) {
    // //     // Google tag (gtag.js) 15JUN2023 //
    window.gtag(
      // console.log(
      "event",
      "add_to_cart",
      {
        currency: "INR",
        value: item?.order_prod_price,
        items: [
          {
            item_id: item?.brakeup_part_no,
            item_name: item?.prod_name,
            affiliation: "Brakeup",
            discount: item?.discount_price,
            item_brand: item?.brand,
            item_category: item?.category,
            item_list_name: item?.vehicle_details?.make
              ? item?.vehicle_details?.make +
                " " +
                item?.vehicle_details?.model +
                " " +
                item?.vehicle_details?.year
              : "",
            price: item?.total_mrp,
            quantity: item?.quantity,
          },
        ],
      }
    );
  }
}

export function Cart_Save4LaterGtag(item) {
  //   console.log(item, "amountamountt");

  if (window.gtag != null) {
    // //     // Google tag (gtag.js) 15JUN2023 //
    window.gtag(
      // console.log(
      "event",
      "add_to_wishlist",
      {
        currency: "INR",
        value: item?.order_prod_price,
        items: [
          {
            item_id: item?.brakeup_part_no,
            item_name: item?.prod_name,
            affiliation: "Brakeup",
            discount: item?.discount_price,
            item_brand: item?.brand,
            item_category: item?.category,
            item_list_name: item?.vehicle_details?.make
              ? item?.vehicle_details?.make +
                " " +
                item?.vehicle_details?.model +
                " " +
                item?.vehicle_details?.year
              : "",
            price: item?.total_mrp,
            quantity: item?.quantity,
          },
        ],
      }
    );
  }
}
