// Chakra imports
import { Footer } from "./components/Footer/Footer.js";
import { NavigationBar } from "./components/NavigationBar/NavigationBar.jsx";

import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";

// Custom Chakra theme
import theme from "./theme/theme.js";

import AdsPopUpModal from "./ZAds/AdsPopUpModal.jsx";

import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { setCategoryFilter } from "./stateManagement/user/categoryFilterSlice.js";
import EnquiryPopup from "./ZAds/EnquiryPopup.jsx";

export const ComponentWrapper = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title =
          "Brakeup - Buy Brake Pads, Brake Rotors, Brake Fluids online for your Car";
        break;
      case "/filter":
        document.title =
          "Filter - Buy Best Brakepads, Brake Rotors, Brake Fluids Online at Lowest Price, Best Brands. ";
        break;
      case "/cart":
        document.title =
          "Cart - Buy Best Brake Pads, Brake Rotors, Brake Fluid Online at Best Price - Brakeup! ";
        break;
      case "/address":
        document.title = "Address - Brakeup ";
        break;
      case "/order-summary":
        document.title = "Order Summary - Brakeup ";
        break;
      case "/payment":
        document.title = "Payment - Brakeup ";
        break;
      case "/order-success":
        document.title = "Order Confirmed - Brakeup ";
        break;
      case "/ordernot":
        document.title = "Order not Confirmed - Brakeup ";
        break;
      case "/return&refundpolicy":
        document.title = "Return and Refund Policy - Brakeup ";
        break;
      case "/privacypolicy":
        document.title = "Privacy Policy - Brakeup ";
        break;
      case "/termsofuse":
        document.title = "Terms of Use - Brakeup ";
        break;
      case "/shipping":
        document.title = "Shipping - Brakeup ";
        break;
      case "/customercare":
        document.title = "Customer Care - Brakeup ";
        break;
      case "/contactus":
        document.title = "Contact Us - Brakeup ";
        break;
      case "/aboutus":
        document.title = "About Us - Brakeup ";
        break;
      // case "/pay":
      //   document.title = "Payment Final Details - Brakeup "
      //   break;
      // case "/paym":
      //   document.title = "Payment Final Details  - Brakeup "
      //   break;
      // case "/paymc":
      //   document.title = "Payment COD - Brakeup "
      //   break;
      // case "/paywebcod":
      //   document.title = "Payment COD - Brakeup "
      //   break;
      // case "/paywebselect":
      //   document.title = "Select Payment Method - Brakeup "
      //   break;
      case "/loginsignup":
        document.title = "Starting - Brakeup ";
        break;
      default:
        break;
    }

    if (location.pathname.includes("/buynow")) {
      document.title = "Buy Now - Brakeup";
    }
    if (location.pathname.includes("/myprofile")) {
      document.title = "Profile - Brakeup";
    }
    if (location.pathname.includes("/myprofilemobile")) {
      document.title = "Profile - Brakeup";
    }
    if (location.pathname.includes("/replace")) {
      document.title = "Replacement - Brakeup";
    }
    if (location.pathname.includes("/replacemob")) {
      document.title = "Replacement - Brakeup";
    }

    if (!location.pathname.includes("/filter")) {
      dispatch(setCategoryFilter({}));
    }
  }, []);

  //advertisement

  const { isOpen, onOpen, onClose } = useDisclosure();

  let history = useHistory();

  const isSignInOpen = useSelector((state) => state.isSignInOpen.isSignInOpen);

  // let IsSignInOpenn = false

  // useEffect(() => {
  //   IsSignInOpenn = isSignInOpen
  //   console.log(IsSignInOpenn,isSignInOpen, "useEffectisOpenIsSignInOpen");

  // }, [isSignInOpen]);

  // BRAKEUP10 POPUP

  // useEffect(() => {

  //   const token = JSON.parse(localStorage.getItem("token")).data.credAuthResult

  //   if (token && token.type == "anonymous") {
  //     if (!location.pathname.includes("/loginsignup")) {
  //       const timer = setTimeout(() => {
  //         CheckIsSignInOpen(isSignInOpen)
  //       }, 10000);
  //       return () => clearTimeout(timer);
  //     }
  //   }
  // }, [isSignInOpen]);

  // const CheckIsSignInOpen = (key) => {
  //   if (key == false) {
  //     // console.log(key, "IsopSignInOpenIsSignInOpen");
  //     onOpen()
  //   }
  // }

  // const promoAddClicked = () => {
  //   if (window.innerWidth >= 800) {
  //     document.getElementById('openWebSignPopUp').click();
  //     onClose();
  //     setTimeout(() => {
  //       document.getElementById('createAccountWeb').click();
  //     }, 100);
  //     // console.log(document.getElementById('openWebSignPopUp'), "promo advertisement");

  //   } else {
  //     history.push(`/loginsignup`);
  //     const timer = setTimeout(() => {
  //       document.getElementById('createAccountMob').click();
  //     }, 100);
  //     return () => clearTimeout(timer);
  //   }
  // }

  //Enquiry popup

  let EnqNeedUrl = ["/filter", "/product/"];

  useEffect(() => {
    if (EnqNeedUrl.some((el) => location.pathname.includes(el))) {
      setTimeout(() => {
        onOpen();
      }, 30000);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <ChakraProvider theme={theme} resetCss={true}>
            {/* <AdsPopUpModal isOpen={isOpen} onClose={onClose} promoAddClicked={promoAddClicked} /> */}
            {/* <EnquiryPopup isOpen={isOpen} onOpen={onOpen} onClose={onClose} /> */}
            <NavigationBar />

            <div id="components">
              <Component {...props} />
            </div>
            {location.pathname == "/loginsignup" ? "" : <Footer />}
          </ChakraProvider>
        );
      }}
    />
  );
};
