import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Spacer,
  Text,
  Grid,
  Radio,
  RadioGroup,
  useDisclosure,
} from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { BsArrowDownUp } from 'react-icons/bs';
import { FaArrowUp } from 'react-icons/fa';
import ItemDisplay from './ItemDisplay';
import Filterbox from './filterbox';
import RecentView from './RecentView';
import { get, post } from '../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import { API_URL } from '../../services/api';
import { useQuery } from '../../utilities';
import { useSelector, useDispatch } from 'react-redux';
import { setCategoryFilter } from '../../stateManagement/user/categoryFilterSlice';
import { AdsWeb } from '../../ZAds/AdsWeb';
import contactUs from '../../assets/sarath_IMG/Customer-care-banner-web-low.jpg';
import ProductCardScroll from './ProductCardScroll';

const FilterPageLayout = () => {
  let history = useHistory();
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState({});
  const [mobfilter, setMobFilter] = useState({});

  const [sortTitle, setsortTitle] = useState('Recommended');
  const [sortValue, setsortValue] = useState('1');
  const [sortedOption, setsortedOption] = useState();
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [version, setVersion] = useState([]);
  const [year, setYear] = useState([]);
  const [fuel, setFuel] = useState([]);
  const [category, setCat] = useState([
    {
      label: 'Brake Rotor',
      id: 'Brake Rotor',
    },
    {
      label: 'Brake Pad',
      id: 'Brake Pad',
    },
    {
      label: 'Brake Fluid',
      id: 'Brake Fluid',
    },
    {
      label: 'Other',
      id: 'Other',
    },
  ]);
  const [reViewed, setReViewed] = useState([]);
  const [brand, setBrandDts] = useState([]);
  const [showShowMore, setShowShowMore] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  const token = JSON.parse(localStorage.getItem('token')).data?.credAuthResult;

  let query = useQuery();

  const dispatch = useDispatch();

  const getQueries = () => {
    const categoryQUERY = query.get('category');
    const brandQUERY = query.get('brand');
    const makeQUERY = query.get('make');
    const makeidQUERY = query.get('make_id');
    const modelQUERY = query.get('model');
    const modelidQUERY = query.get('model_id');
    const yearQUERY = query.get('year');
    const versionQUERY = query.get('version');
    const fuelQUERY = query.get('fuel_type');
    const typeQUERY = query.get('axle');

    let newFilter = { ...filter };
    newFilter = { ...filter };

    if (makeQUERY) {
      newFilter.make = {
        name: makeQUERY,
        value: makeidQUERY,
        label: makeQUERY,
      };
    }
    if (modelQUERY) {
      newFilter.model = {
        name: modelQUERY,
        value: modelidQUERY,
        label: modelQUERY,
      };
    }
    if (yearQUERY) {
      newFilter.year = { name: yearQUERY, value: yearQUERY, label: yearQUERY };
    }
    if (versionQUERY) {
      newFilter.version = {
        name: versionQUERY,
        value: versionQUERY,
        label: versionQUERY,
      };
    }
    if (fuelQUERY) {
      newFilter.fuel = { name: fuelQUERY, value: fuelQUERY, label: fuelQUERY };
    }
    if (typeQUERY) {
      newFilter.type = { name: typeQUERY, value: typeQUERY, label: typeQUERY };
    }
    if (categoryQUERY) {
      newFilter.category = {
        name: categoryQUERY,
        value: categoryQUERY,
        label: categoryQUERY,
      };
      // dispatch(setCategoryFilter(newFilter.category));
    }
    if (brandQUERY) {
      newFilter.brand = {
        name: brandQUERY,
        value: brandQUERY,
        label: brandQUERY,
      };
    }

    const url = getFilter(newFilter, true);
    getProducts(url, true);
  };

  useEffect(() => {
    getQueries();

    // const filter = JSON.parse(localStorage.getItem("filter") || "{}");
    // const url = getFilter(filter);
    // getProducts(url, true);
    get('/api/v2/vehiclemake/')
      .then((res) => {
        let make = [];
        res &&
          res.data &&
          res.data.map((items) => {
            make.push({ label: items.make, id: items._id['$oid'] });
          });
        setMake(make);
      })
      .catch((err) => {
        setMake([]);
      });
    get('/api/v2/products/add_brand/')
      .then((res) => {
        let make = [];
        res &&
          res.data &&
          res.data.map((items) => {
            make.push({ label: items.brand_name, id: items.brand_name });
          });
        setBrandDts(make);
      })
      .catch((err) => {
        setBrandDts([]);
      });
    let recentUrl = `/api/v2/product/recently_viewed/?id=${token.id}`;
    recentlyViewed(recentUrl);
    dispatch(setCategoryFilter({}));
  }, []);

  const filterCategory = useSelector((state) => state.categoryFilter.filter);

  useEffect(() => {
    if (Object.keys(filterCategory).length != 0) {
      filterCategoryChanged();
    }
    // console.log(Object.keys(filterCategory).length, "filterCategory.length");
  }, [filterCategory]);

  const categoryQUERY = query.get('category');

  const filterCategoryChanged = () => {
    if (Object.keys(filterCategory).length >= 1 && filterCategory?.value) {
      onChange(filterCategory.value, filterCategory.name, filterCategory.label);
    }

    // if (Object.keys(filter)) {

    //     if (filterCategory && filterCategory?.value) {
    //         onChange(filterCategory.value, filterCategory.name, filterCategory.label)
    //     }
    //     console.log(filter, filterCategory, "categoryfilter");

    // }
  };

  const recentlyViewed = (url) => {
    get(url)
      .then((res) => {
        let data = [...res.data, ...reViewed];
        setReViewed(data);
      })
      .catch((err) => {
        setReViewed([]);
      });
  };

  const getMake = (value, filter) => {
    let model = [];
    get(`/api/v2/vehiclemodel/?id=${value}`)
      .then((res) => {
        res &&
          res.data &&
          res.data.map((items) => {
            model.push({ label: items.model, id: items._id['$oid'] });
          });
        setModel(model);
        if (window.innerWidth >= 800 && model && model.length == 1) {
          let newFilter = { ...filter };
          newFilter.model = {
            value: model[0]?.id,
            label: model[0]?.label,
            name: 'model',
          };
          setFilter(newFilter);
          getModel(model[0].id, newFilter);
        }
      })
      .catch((err) => {
        setModel([]);
        setVersion([]);
        setYear([]);
      });
  };

  const getModel = (value, filter) => {
    get(`/api/v2/make_details/?id=${value}`)
      .then((res) => {
        let year = [];
        res &&
          res.year &&
          res.year.map((items) => {
            year.push({ label: items, id: items });
          });
        setYear(year);
        if (window.innerWidth >= 800 && year && year.length == 1) {
          let newFilter = { ...filter };
          newFilter.year = {
            value: year[0]?.id,
            label: year[0]?.label,
            name: 'year',
          };
          setFilter(newFilter);
          getYear(year[0].id, newFilter);
        }
      })
      .catch((err) => {
        setYear([]);
      });
  };

  const getYear = (value, filter) => {
    get(`/api/v2/veh_version/?model_id=${filter.model.value}&year=${value}`)
      .then((res) => {
        let version = [];
        let fuel = [];

        res &&
          res.version.map((items) => {
            if (!items.includes('Version')) {
              version.push({ label: items, id: items });
            }
          });
        setVersion(version);
        res &&
          res.fuel_type &&
          res.fuel_type.map((items) => {
            fuel.push({ label: items, id: items });
          });
        setFuel(fuel);
        if (
          (window.innerWidth >= 800 && version && version.length == 1) ||
          (window.innerWidth >= 800 && fuel && fuel.length == 1)
        ) {
          let newFilter = { ...filter };
          if (version.length == 1) {
            newFilter.version = {
              value: version[0]?.id,
              label: version[0]?.label,
              name: version[0]?.id,
            };
          }
          if (fuel.length == 1) {
            newFilter.fuel = {
              value: fuel[0]?.id,
              label: fuel[0]?.label,
              name: fuel[0]?.id,
            };
          }
          setFilter(newFilter);
        }

        // getFilter(newFilter)
      })
      .catch((err) => {
        setVersion([]);
        setFuel([]);
      });
  };

  const onChange = (value, name, label) => {
    // window.history.pushState(null, '', `${paramurl}`);

    let newFilter = { ...filter };
    newFilter = { ...filter };
    if (name == 'make') {
      // getMake(value)
      delete newFilter['model'];
      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
      seterrorFlexboxstyle1(false);
      setYear([]);
      setVersion([]);
      setFuel([]);
    } else if (name == 'model') {
      // getModel(value);
      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
      seterrorFlexboxstyle2(false);
      setVersion([]);
      setFuel([]);
    } else if (name == 'year') {
      // getYear(value);
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
      seterrorFlexboxstyle3(false);
    } else if (name == 'version') {
      delete newFilter['fuel'];
      delete newFilter['type'];
    } else if (name == 'fuel') {
      delete newFilter['type'];
    }

    newFilter[name] = { value, name, label };
    const url = getFilter(newFilter);
    getProducts(url, true);
  };

  let paramurl = '/filter';
  const seller_part_no = JSON.parse(
    localStorage.getItem('seller_part_no') || '""'
  );

  const getFilter = (filter, fromQuery) => {
    setFilter(filter);
    // console.log(filter, "filterfilter");
    // console.trace("getFiltergetFilter")
    let url = '/api/v2/search/buyer_search/?q=Homepage_filter';
    if (filter.make) {
      url = `${url}&make_id=${filter.make.value}`;
      getMake(filter.make.value, filter);
      paramurl = `${paramurl}?make=${filter.make.label}&make_id=${filter.make.value}`;
    }
    if (filter.model) {
      url = `${url}&model_id=${filter.model.value}`;
      getModel(filter.model.value, filter);
      paramurl = `${paramurl}&model=${filter.model.label}&model_id=${filter.model.value}`;
    }
    if (filter.year) {
      url = `${url}&year=${filter.year.value}`;
      getYear(filter.year.value, filter);
      paramurl = `${paramurl}&year=${filter.year.label}`;
    }
    if (filter.version) {
      url = `${url}&version=${filter.version.value}`;
      getYear(filter.year.value, filter);
      paramurl = `${paramurl}&version=${filter.version.value}`;
    }
    if (filter.fuel) {
      url = `${url}&fuel_type=${filter.fuel.value}`;
      getYear(filter.year.value, filter);
      paramurl = `${paramurl}&fuel_type=${filter.fuel.value}`;
    }
    if (filter.type) {
      let seperator =
        Object.keys(filter).length == 3 && filter.category
          ? '?'
          : Object.keys(filter).length == 2 && filter.brand
          ? '?'
          : Object.keys(filter).length == 1
          ? '?'
          : '&';
      url = `${url}&axle=${filter.type.value}`;
      paramurl = `${paramurl}${seperator}axle=${filter.type.value}`;
    }
    if (filter.category) {
      let seperator =
        Object.keys(filter).length == 2 && filter.brand
          ? '?'
          : Object.keys(filter).length == 1
          ? '?'
          : '&';
      url = `${url}&category=${filter.category.value}`;
      paramurl = `${paramurl}${seperator}category=${filter.category.value}`;
    }
    if (filter.brand) {
      let seperator = Object.keys(filter).length == 1 ? '?' : '&';
      url = `${url}&brand=${filter.brand.value}`;
      paramurl = `${paramurl}${seperator}brand=${filter.brand.value}`;
    }
    // console.log(Object.keys(filter).length, "filtersfilters");

    // console.log(paramurl, "filterfilterfilter");
    // console.log(paramurl.split('?')[1], "filterfilterfiltersssss");

    if (!fromQuery) {
      window.history.pushState(null, '', `${paramurl}`);
      // localStorage.setItem("filter", JSON.stringify(filter));
    }
    if (seller_part_no && seller_part_no != '') {
      // console.log(seller_part_no, "seller_part_no");
      url = `${url}&seller_part_no=${seller_part_no}`;
      setTimeout(() => {
        window.localStorage.removeItem('seller_part_no');
      }, 3000);
    }
    return url;
  };

  const getProducts = (url, load) => {
    let sorturl = `${url}&&sort_by=${
      sortedOption ? sortedOption.sort_by : 'brand'
    }&order=${sortedOption ? sortedOption.order : '1'}`;
    get(sorturl)
      .then((res) => {
        let data = [];
        if (load) {
          data = [...res?.data];
        } else {
          data = [...products, ...res?.data];
        }
        setProducts(data);
        setIsLoading(false);

        if (res?.data?.length == 0) {
          setShowShowMore(false);
        } else {
          setShowShowMore(true);
        }

        if (data?.length >= 24 && res?.data?.length != 0) {
          setShowShowMore(true);
        } else {
          setShowShowMore(false);
        }
      })
      .catch((err) => {
        setProducts([]);
        setIsLoading(false);
      });
  };

  //scroll to top & Back to Top

  const ScrolltoTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const next = () => {
    let url = getFilter(filter);
    if (products) {
      const lastItem = products[products.length - 1];
      url = `${url}&previousId=${lastItem['_id']['$oid']}`;
      url = `${url}&limit=12`;
      url = `${url}&order=1`;
    }
    getProducts(url, false);
  };

  const onRectClick = () => {
    let url = `/api/v2/product/recently_viewed/?id=${token.id}`;
    if (reViewed) {
      const lastItem = reViewed[reViewed.length - 1];
      url = `${url}&previousId=${lastItem.prod_id}`;
      url = `${url}&limit=10`;
      url = `${url}&order=1`;
    }
    recentlyViewed(url);
  };

  // S O R T

  const Sort = (element) => {
    let url = getFilter(filter);
    setsortedOption(element);
    getProducts(url, true);
  };

  let sortdata = [
    {
      title: 'New Arrivals',
      value: '4',
      sort_by: 'created_at',
      order: '1',
    },
    {
      title: 'Price Low to High',
      value: '2',
      sort_by: 'vendor_products.selling_price',
      order: '1',
    },
    {
      title: 'Price High to Low',
      value: '3',
      sort_by: 'vendor_products.selling_price',
      order: '-1',
    },
    {
      title: 'Recommended',
      value: '1',
      sort_by: 'brand',
      order: '1',
    },
    {
      title: 'Discounts',
      value: '5',
      sort_by: 'vendor_products.discount',
      order: '-1',
    },
  ];

  let sortoptions = [];
  sortdata.forEach((element) => {
    sortoptions.push(
      <MenuItem
        onClick={() => {
          setsortTitle(element.title), Sort(element);
        }}
        _hover={{ bgColor: '#ECE9D2' }}
        _focus={{ bgColor: 'none', boxShadow: 'none' }}
      >
        <Radio
          border="2px"
          borderColor="black"
          colorScheme="white"
          _before={{ color: 'black' }}
          value={element.value}
        >
          {element.title}
        </Radio>
      </MenuItem>
    );
  });

  const onclose = (id) => {
    let newFilter = { ...filter };
    delete newFilter[id];

    if (id == 'make') {
      delete newFilter['model'];
      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
    } else if (id == 'model') {
      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
    } else if (id == 'year') {
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
    } else if (id == 'version') {
      delete newFilter['fuel'];
      delete newFilter['type'];
    } else if (id == 'fuel') {
      delete newFilter['type'];
    } else if (id == 'category') {
      dispatch(setCategoryFilter({}));
    }

    const url = getFilter(newFilter);
    getProducts(url, true);
  };

  const onClear = (type) => {
    let newFilter = { ...filter };
    if (type == 'main') {
      delete newFilter['make'];
      delete newFilter['model'];
      delete newFilter['version'];
      delete newFilter['year'];
      delete newFilter['type'];
      delete newFilter['fuel'];
    } else if (type == 'all') {
      delete newFilter['make'];
      delete newFilter['model'];
      delete newFilter['version'];
      delete newFilter['year'];
      delete newFilter['type'];
      delete newFilter['fuel'];
      delete newFilter['category'];
      delete newFilter['brand'];
      dispatch(setCategoryFilter({}));
      setModel([]);
      setYear([]);
      setVersion([]);
      setFuel([]);
    } else if (type == 'sub') {
      delete newFilter['category'];
      delete newFilter['brand'];
      dispatch(setCategoryFilter({}));
    }
    const url = getFilter(newFilter);
    getProducts(url, true);
  };

  const [errorFlexboxstyle1, seterrorFlexboxstyle1] = useState(false);
  const [errorFlexboxstyle2, seterrorFlexboxstyle2] = useState(false);
  const [errorFlexboxstyle3, seterrorFlexboxstyle3] = useState(false);

  const onBoxClick = (element) => {
    if (element.category == 'Brake Fluid') {
      history.push(`/product/${element['_id']['$oid']}`);
    } else if (filter.hasOwnProperty('make') == false) {
      seterrorFlexboxstyle1(true);
      seterrorFlexboxstyle2(true);
      seterrorFlexboxstyle3(true);
    } else if (filter.hasOwnProperty('model') == false) {
      seterrorFlexboxstyle2(true);
      seterrorFlexboxstyle3(true);
    } else if (filter.hasOwnProperty('year') == false) {
      seterrorFlexboxstyle3(true);

      // } else if (!filter.make.label) {
      //     seterrorFlexboxstyle1(true)
      // } else if (!filter.model.label) {
      //     seterrorFlexboxstyle2(true)
      // } else if (!filter.year.label) {
      //     seterrorFlexboxstyle3(true)
    }

    // !filter.make.label ||
    // !filter.model.label ||
    // !filter.year.label
    else {
      let CapitalFuelType =
        filter?.fuel?.value.charAt(0).toUpperCase() +
        filter?.fuel?.value.slice(1);

      history.push(
        `/product/${element['_id']['$oid']}/${filter.make?.value}/${
          filter?.model?.value
        }/${filter?.year?.value}${
          (filter?.version?.value ? '/' + filter?.version?.value : '') +
          (filter?.fuel?.value ? '/' + CapitalFuelType : '') +
          (filter?.type?.value ? '/' + filter?.type?.value : '')
        }`
      );

      // if (filter.version && filter.fuel && filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}/${filter?.version?.value}/${CapitalFuelType}/${filter?.type?.value}`);
      // }
      // else if (filter.version && filter.fuel && !filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}/${filter?.version?.value}/${CapitalFuelType}`);
      // }
      // else if (filter.version && !filter.fuel && filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}/${filter?.version?.value}/${filter?.type?.value}`);
      // }
      // else if (filter.version && !filter.fuel && !filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}/${filter?.version?.value}`);
      // }
      // else if (!filter?.version && !filter.fuel && !filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}`);
      // }
      // else if (!filter?.version && filter.fuel && filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}/${CapitalFuelType}/${filter?.type?.value}`);
      // }
      // else if (!filter?.version && filter.fuel && !filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}/${CapitalFuelType}`);
      // }
      // else if (!filter?.version && !filter.fuel && filter.type) {
      //     history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter?.model?.value}/${filter?.year?.value}/${filter?.type?.value}`);
      // }

      // history.push(`/product/${element["_id"]["$oid"]}/${filter.make?.value}/${filter.model?.value ? filter.model?.value : filter.model?.id}/${filter.year.value ? filter.year?.value : filter.year?.id}/${filter.version ? (filter.version?.value ? filter.version?.value : filter.version?.id) : "null"}/${filter.fuel ? (filter.fuel?.value ? filter.fuel?.value : filter.fuel?.id) : "null"}/${filter.type ? filter.type?.value : "null"}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Test_shiplabel = () => {
    let data = { prod_id: '64671d6b77063b071141eb63', order_id: 'ORE81179' };
    //    {
    //        "success": 1,
    //        "awb": "17883010200056",
    //        "sort_code": "AWY/PEE",
    //        "parcel_type": "COD",
    //        "parcel_value": "₹ 1,500.00",
    //        "barcode_img": "https://www.icarry.in/image/delhivery-awbs/17883010200056.png",
    //        "courier_name": "DELHIVERY",
    //        "courier_id": "46",
    //        "return_address": "Enarges Labs, Mars Arcade,Thrissur 680005,India",
    //        "consignee_address": "ABC,123jfjfkf,Ernakulam 686670,KR,IN",
    //        "consignee_mobile": "9895711494"
    //    }

    post('/api/v2/seller/generatelabel/', data)
      .then((res) => {
        window.open(
          'https://api.dev.brakeup.in/media/labels/63ef150fb3a9b79f039b3b75-64671d6b77063b071141eb63-ORE81179.pdf'
        );
      })
      .catch((err) => {});
  };

  return (
    <Container
      className="layout_bg"
      maxW="100%"
      m={0}
      p={0}
      backgroundColor="#F5F5F5"
    >
      {/* <button onClick={() => Test_shiplabel()}>
  Test shiplabel
</button> */}

      <Flex className="filter_itemDisplay">
        <Filterbox
          filter={filter}
          onclose={onclose}
          onClear={onClear}
          onChange={onChange}
          model={model}
          year={year}
          version={version}
          fuel={fuel}
          make={make}
          cat={category}
          brandDts={brand}
          errorFlexboxstyle1={errorFlexboxstyle1}
          errorFlexboxstyle2={errorFlexboxstyle2}
          errorFlexboxstyle3={errorFlexboxstyle3}
        />

        {products.length != 0 ? (
          <Box
            className="item_grid"
            gridArea="item_grid"
            pl="2rem"
            pt="2rem"
            borderRadius="lg"
            width="100%"
          >
            <Flex
              className="desktop_only"
              mb="1vh"
              p={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                className="font_Manrope"
                mb={0}
                fontWeight="700"
                fontSize="22px"
              >
                {sortTitle}
              </Text>

              <Flex>
                <Box>
                  <Menu bg="#0000001A">
                    <MenuButton
                      textAlign="left"
                      className="font_NunitoA sort_box"
                      pl={5}
                      bg="#FFFFFF"
                      w="max-content"
                      _active={{ border: 'none' }}
                      _focus={{ border: 'none' }}
                      _hover={{ fontWeight: '600' }}
                      border="1px"
                      borderColor="gray.300"
                      borderRadius="6px"
                      as={Button}
                      rightIcon={
                        <BsArrowDownUp
                          className="sort_box_font"
                          color="#9D9EA4"
                        />
                      }
                    >
                      <Flex className="sort_box_font" alignItems="baseline">
                        <Text fontWeight="normal" textColor="#9D9EA4">
                          Sort by :{' '}
                        </Text>
                        <Text pl="3px"> {sortTitle} </Text>
                      </Flex>
                    </MenuButton>
                    <MenuList transitionDelay=".2s" pt={0} pb={0} zIndex={3}>
                      <RadioGroup defaultValue="1">{sortoptions}</RadioGroup>
                    </MenuList>
                  </Menu>
                </Box>
              </Flex>
            </Flex>

            <AdsWeb AD2={true} adStyle={{ marginTop: '2vw' }} />

            <Grid className="items_grid" gap={5} pt="1.6rem">
              <ItemDisplay
                justifyContent="center"
                products={products}
                onBoxClick={onBoxClick}
              />
            </Grid>

            <Flex justifyContent="center" textAlign="center">
              {products.length >= 24 && showShowMore && (
                <Box
                  className="font_Nunito desktop_only"
                  fontWeight="600"
                  w="9rem"
                  p={3}
                  fontSize="1rem"
                  bgColor="white"
                  mt="6vh"
                  mb="2vh"
                  borderRadius="4px"
                  textAlign="center"
                  boxShadow="0px 3px 10px #00000040"
                  _hover={{
                    bgColor: '#ECE9D2',
                    boxShadow: '0px 3px 10px #00000040',
                  }}
                  _active={{
                    bgColor: '#FFFFFF',
                    boxShadow: '0px 3px 10px #00000040',
                  }}
                  onClick={() => next()}
                >
                  <Text> Show More </Text>
                </Box>
              )}

              {products.length >= 40 && (
                <Box
                  className="font_NunitoA desktop_only"
                  fontWeight="600"
                  w="9rem"
                  p={3}
                  fontSize="1rem"
                  bgColor="white"
                  mt="6vh"
                  mb="2vh"
                  ml="3vh"
                  borderRadius="4px"
                  textAlign="center"
                  boxShadow="0px 3px 10px #00000040"
                  _hover={{
                    bgColor: '#ECE9D2',
                    boxShadow: '0px 3px 10px #00000040',
                  }}
                  _active={{
                    bgColor: '#FFFFFF',
                    boxShadow: '0px 3px 10px #00000040',
                  }}
                  onClick={() => ScrolltoTop()}
                >
                  <Flex alignItems="center" justifyContent="space-evenly">
                    <FaArrowUp fontSize="1.1rem" />
                    <Text whiteSpace="nowrap">Back to Top</Text>
                  </Flex>
                </Box>
              )}
            </Flex>
          </Box>
        ) : (
          !isloading && (
            <Flex
              className="item_grid"
              gridArea="item_grid"
              pl="2rem"
              pt="2rem"
              width="100%"
              bg=""
              alignItems="center"
              flexDirection="column"
              justifyItems="center"
            >
              <Box
                width="30vw"
                height="500px"
                className="emptylottiebox"
                maxW="500px"
              >
                <lottie-player
                  src={
                    'https://api.dev.brakeup.in/api/v2/json/Filter_no_item.json'
                  }
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </Box>
              <Box
                pb="40px"
                className="font_Manrope"
                fontWeight="600"
                marginTop="-5vw"
              >
                <Text className="sorry" fontSize="30px" textAlign="center">
                  Products may be sold out!
                </Text>
                <Text className="sorry2" fontSize="16px" textAlign="center">
                  Please make a different search
                </Text>
              </Box>
            </Flex>
          )
        )}
      </Flex>

      {/* <AdsWeb AD3={true} adStyle={{ margin: " 2vw 10vw" }} adStyleImg={{ borderRadius: '16px', boxShadow: "0px 1px 1px #00000026" }} /> */}

      {/* <Flex
        className='recent'
        gridArea='recent_items'
        justifyContent='center'>
      <RecentView reViewed={reViewed} onRectClick={onRectClick} />
      </Flex> */}
      <div className="px-8 mx-auto md:w-full lg:px-12 2xl:px-4 max-w-screen-2xl">
        <div className="p-8">
          <ProductCardScroll title={'RECENTLY VIEWED'} reViewed={reViewed} />
        </div>
      </div>

      <Flex
        mt="6vh"
        mb="6vh"
        position="relative"
        // boxShadow='0px 0px 99px #00000026'
        justifyContent="center"
      >
        <Image src={contactUs} loading="lazy" width="80vw" />
        <Button
          className="hpbtnAdDark"
          _hover={{ bgColor: '#72b5cf', color: '#000' }}
          border="2px"
          borderColor="#000"
          position="absolute"
          bottom="12%"
          left="37%"
          bgColor="black"
          color="#72b5cf"
          borderRadius="3xl"
          onClick={() => history.push('/customercare')}
        >
          Contact Us
        </Button>
      </Flex>
    </Container>
  );
};

export default FilterPageLayout;
