import { Image } from '@chakra-ui/react';

import dCross from '../../assets/sarath_IMG/x.svg';
import { useEffect, useState } from 'react';

import { setSelectedAddress } from '../../stateManagement/user/showAddressSlice';
import { useDispatch, useSelector } from 'react-redux';
import { post } from '../../services/api';
import Removemodal from '../../components/Modals/Removemodal';
import { useHistory } from 'react-router';
import Successmodal from '../../components/Modals/Successmodal';

function AddressData(props) {
  let history = useHistory();

  const dispatch = useDispatch();

  const selectedAddress = useSelector((state) => state.address.SelectedAddress);

  const selectedPincode = JSON.parse(localStorage.getItem('pincode') || '{}');

  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);

  const [deleteAdd, setdeleteAdd] = useState({});

  const openDeleteWindow = (id, user_id) => {
    setdeleteAdd({ id: id, user_id: user_id });
    setIsOpenRemove(true);
  };

  const deleteAddress = () => {
    post('/api/v2/products/deleteaddress/', {
      address_id: deleteAdd.id,
      user_id: deleteAdd.user_id,
    })
      .then((res) => {
        props.getAddress();
        setIsOpenRemove(false);
        setIsOpenSuccess(true);
      })
      .catch((err) => {});

    // console.log("delette add", deleteAdd);
  };

  return (
    <>
      <div className="  flex flex-col  w-full">
        {/* <span>{props.title}</span> */}
        {props.addresses &&
          props.addresses.map((add) => {
            return (
              <div
                key={add?._id?.$oid}
                className={`grid first-of-type:ml-0.5   ${
                  props.isSelectable &&
                  selectedAddress?._id?.$oid != add._id?.$oid &&
                  'opacity-75 text-greyB5'
                }`}
                onClick={() =>
                  props.isSelectable && dispatch(setSelectedAddress(add))
                }
              >
                <div
                  className={`cne_border  gap-1 flex  py-7 pl-4 pr-3.5 ${
                    props.center
                      ? 'pr-7 md:justify-center justify-start'
                      : 'pr-3.5 justify-between'
                  } add_font14 relative  `}
                >
                  <div
                    className={`flex flex-col ${
                      props.center
                        ? 'md:items-center items-start'
                        : 'items-start'
                    } justify-start`}
                  >
                    <p className="font-bold  break-all">{add?.name}</p>
                    <p className="font-normal break-all">{add?.address} </p>
                    <p className="font-normal break-all">{add?.address2} </p>
                    <p className="font-normal break-all">{add?.landmark}</p>
                    <p className="font-normal">{add?.district}</p>
                    <p className="font-normal">
                      {add?.state}, {add?.pincode}
                    </p>
                    {/* <p className="font-normal">{add.pincode}</p> */}
                  </div>

                  <div className="flex items-center justify-end">
                    <div className="absolute inset-y-2 text-greyB3">
                      {props.title == 'SAVED ADDRESSES' ? (
                        <button
                          autoFocus={false}
                          className="opacity-60 md:hover:opacity-100"
                          onClick={(e) => {
                            openDeleteWindow(
                              add?._id['$oid'],
                              add?.user_id['$oid']
                            );
                            e.stopPropagation();
                          }}
                        >
                          <Image
                            width={25}
                            height={25}
                            src={dCross}
                            alt=""
                            color="greyB5"
                          />
                        </button>
                      ) : (
                        ''
                      )}
                    </div>

                    {props.title == 'SAVED ADDRESSES' ? (
                      <button
                        className="flex  px-5  grey_button"
                        type="submit"
                        onClick={() => props.EditAddress(add)}
                      >
                        EDIT
                      </button>
                    ) : props.title == 'SAVED ADDRESSES' ||
                      props.title == 'ORDER SUMMARY' ||
                      props.title == 'PAY ON DELIVERY VERIFICATION' ? (
                      <button
                        className="flex px-4 py-1 font-normal  grey_button"
                        type="submit"
                        onClick={() => history.push(`/address`)}
                      >
                        CHANGE
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {props.title == 'SAVED ADDRESSES' ? (
                  <div style={{ minHeight: '37px', width: '100%' }}>
                    {props.isSelectable &&
                      selectedAddress?._id?.$oid == add._id?.$oid &&
                      selectedAddress?.pincode != selectedPincode && (
                        <div className="add_font12 font-normal text-redB opacity-50  ml-2">
                          Pincode of this address and pincode in previous page
                          is different.Price and delivery date may vary
                        </div>
                      )}
                  </div>
                ) : (
                  // )
                  // : props.title == 'PAY ON DELIVERY VERIFICATION'  ? (
                  //   <div style={{ minHeight: "40px", width: "100%" }}>
                  //   <span className="add_font12 ml-2 font-bold text-redB">
                  //     COD Charges: {props.priceDts?.cod_charges} Rs
                  //   </span>
                  //   </div>
                  ''
                )}
              </div>
            );
          })}
      </div>
      <Removemodal
        isOpen={isOpenRemove}
        setIsOpen={setIsOpenRemove}
        message={'Remove Address ?'}
        remove={deleteAddress}
      />
      <Successmodal
        isOpen={isOpenSuccess}
        setIsOpen={setIsOpenSuccess}
        message={'Address removed'}
      />
    </>
  );
}
export default AddressData;
