import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const sha256 = async (input) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, "0"))
    .join("");
  return hash;
};

const token = JSON.parse(localStorage.getItem("token")) ?? null;
const UserData = token?.data?.credAuthResult;

export function UUID() {
  const eventID = uuidv4();
  // console.log(eventID);
  if (eventID) {
    return eventID;
  }
  return null;
}

export async function Meta(event, custom_data, event_id) {
  const getIP = () => {
    const res = axios.get("https://api.ipify.org/?format=json");
    // console.log(res.data);
    if (res) {
      return res?.data?.ip;
    }
    return null;
  };

  const getFBC = () => {
    const cookies = document.cookie.split("; ");
    const fbcCookie = cookies.find((cookie) => cookie.startsWith("_fbc="));
    // console.log(cookies.find((cookie) => cookie), "cookies.find((cookie)");
    if (fbcCookie) {
      const fbcValue = fbcCookie.split("=")[1];
      return fbcValue;
    }
    return null;
  };

  const getFBP = () => {
    const cookies = document.cookie.split("; ");
    const fbpCookie = cookies.find((cookie) => cookie.startsWith("_fbp="));
    if (fbpCookie) {
      const fbpValue = fbpCookie.split("=")[1];
      return fbpValue;
    }
    return null;
  };

  //encrypting
  const email = UserData?.email ? await sha256(UserData?.email) : null;
  const mobile = UserData?.mobile ? await sha256(UserData?.mobile) : null;
  const ip = await getIP();
  const clientUserAgent = navigator.userAgent;

  const fbc = getFBC();
  const fbp = getFBP();

  // console.log("fbc:", fbc);
  // console.log("fbp:", fbp);

  let time = Math.floor(Date.now() / 1000);
  // let currentURL = 'https://brakeup.in/product/6476d067057214eb7763dd6e/63087a8278de19ec4482d496/63087a9278de19ec4482d571/2013/Limousine'
  let currentURL = window.location.href;
  var Fb_TOKEN =
    "EAAKTj0jfWz8BAA4R0YMqP4l6WdMOhl2KulCTgnzPNhEDcHkhm1ZANQQwVpByICRsdbK6Fzl6tTjjRGaZCYZAE8yDrBlUfJZAmCUyDwPx6UhaseDY20AUEZBtQWUBjd0HFPSbLmfxD2aHNYrjWl7FOt39pjVrvvbPXKxnLnPUUFhau5XQqMgDec341ZCJC2ZAZCsZD";
  var PIXEL_ID = "789246286109215";

  let data = {
    data: [
      {
        event_name: event,
        event_time: time,
        user_data: {
          em: [email ?? null],
          ph: [mobile ?? null],
          client_ip_address: ip ?? null,
          client_user_agent: clientUserAgent ?? null,
          fbc: fbc,
          fbp: fbp,
        },
        custom_data: custom_data,
        event_source_url: currentURL,
        action_source: "website",
        event_id: event_id,
      },
    ],
  };

  console.log("pixel");

  const postData = async () => {
    try {
      const response = await axios.post(
        `https://graph.facebook.com/v17.0/${PIXEL_ID}/events?access_token=${Fb_TOKEN}`,
        data
      );
      // console.log(response.data); // Handle the response data
    } catch (error) {
      // console.error(error); // Handle any errors that occurred during the request
    }
  };

  postData();
}
