import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Image,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import './CustomerCare.css';

import { API_URL, post } from '../../services/api';

export const CustomerCare = (props) => {
  const location = useLocation();
  const header = location.pathname;

  const [heading, setheading] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    // const token = JSON.parse(localStorage.getItem("token")).data?.credAuthResult
    if (location.pathname == '/customercare') {
      setheading('Customer Care');
    } else if (location.pathname == '/helpcenter') {
      setheading('Help Center');
    } else {
      setheading('Contact Us');
    }
  }, []);

  let isanonymous = true;
  const token = JSON.parse(localStorage.getItem('token') || '{}').data
    ?.credAuthResult;

  if (token?.type == 'Access') {
    // console.log(header, "cuscareMessage");

    isanonymous = false;
  } else {
    isanonymous = true;
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Mobile PopUp
  const {
    isOpen: isOpenMobile,
    onOpen: onOpenMobile,
    onClose: onCloseMobile,
  } = useDisclosure();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onSubmit' });

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({ mode: 'onSubmit' });

  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[6-9]\d{9}$/;
    return re.test(number);
  };

  const validateEmail = (mail) => {
    // Email validations
    // var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(mail.toLowerCase());
  };

  const onSubmit = (data) => {
    data.type = '';
    // alert(JSON.stringify(data, "I am an alert box!"));
    if (!isanonymous) {
      data.name = token?.name;
      data.mobile = token?.mobile;
      data.email = token?.email;
    }
    post('/api/v2/seller/customer_care/', data)
      .then((res) => {
        if (window.innerWidth >= 800) {
          onOpen();
        } else {
          onOpenMobile();
        }
        reset();
        reset2();
      })
      .catch((err) => {});
  };

  return (
    <>
      <Flex
        className="desktop_only"
        flexDirection="column"
        marginLeft={'10vw'}
        marginRight={'10vw'}
        mt="60px"
        mb="60px"
        alignItems="center"
      >
        <Flex width="100%">
          <Flex flexDirection="column" alignItems="flex-start" width="100%">
            <Text className="cuscare_headers">{heading}</Text>
            <Text className="cuscare_header_2" mt="1rem">
              {' '}
              Get in touch with us!
            </Text>

            <Flex mt="10px" mb="30px" width="80%">
              <form className="cuscare_form">
                {isanonymous && (
                  <Flex>
                    <Box width={'100%'}>
                      <input
                        className="cuscare_input_small"
                        placeholder="Name"
                        name="name"
                        ref={register({
                          required: true,
                        })}
                      />
                      <div style={{ height: '1.5rem', textAlign: 'left' }}>
                        {errors &&
                          errors.name &&
                          errors.email.type == 'required' && (
                            <div className="errormsg">Name is required !</div>
                          )}
                      </div>
                    </Box>
                    <Box width={'100%'} marginLeft={'10px'}>
                      <div className="cuscare_inputbox_phone">
                        <span className="prefix">+91</span>
                        <input
                          className="cuscare_input_phone"
                          placeholder="Mobile No"
                          name="mobile"
                          type="number"
                          ref={register({
                            required: true,
                            validate: validatePhone,
                            valueAsNumber: true,
                          })}
                        />
                      </div>

                      <div style={{ height: '1.5rem', textAlign: 'left' }}>
                        {errors &&
                          errors.mobile &&
                          errors.mobile.type == 'required' && (
                            <div className="errormsg">Mobile is required !</div>
                          )}
                        {errors &&
                          errors.mobile &&
                          errors.mobile.type == 'validate' && (
                            <div className="errormsg"> Invalid input</div>
                          )}
                      </div>
                    </Box>
                  </Flex>
                )}
                {isanonymous && (
                  <Flex>
                    <Box width={'100%'}>
                      <input
                        className="cuscare_input_big"
                        placeholder="Email"
                        name="email"
                        ref={register({
                          required: true,
                          validate: validateEmail,
                        })}
                      />
                      <div
                        style={{
                          height: '1.5rem',
                          width: '252px',
                          textAlign: 'left',
                        }}
                      >
                        {errors &&
                          errors.email &&
                          errors.email.type == 'required' && (
                            <div className="errormsg">Email is required !</div>
                          )}
                        {errors &&
                          errors.email &&
                          errors.email.type == 'validate' && (
                            <div className="errormsg"> Invalid input</div>
                          )}
                      </div>
                    </Box>
                  </Flex>
                )}

                <textarea
                  className="cuscare_descriptionbox"
                  placeholder="Type your message here!"
                  name="desc"
                  ref={register({
                    required: false,
                  })}
                />

                <button
                  type="submit"
                  style={{ alignSelf: 'flex-end' }}
                  onClick={handleSubmit((d) => onSubmit(d))}
                  className="submit_msg_cuscare"
                >
                  SEND MESSAGE
                </button>
              </form>
            </Flex>
          </Flex>

          <Box minWidth={'330px'} maxWidth={'750px'} className="cuscare_lottie">
            <lottie-player
              src={
                'https://api.dev.brakeup.in/api/v2/json/Brakeup_conatct_us.json'
              }
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </Box>
        </Flex>
        <Flex alignSelf="flex-start" flexDirection="column" mb="60px">
          <Text className="cuscare_header_2" mt="1rem">
            {' '}
            Email : care@brakeup.in
          </Text>
          <Text className="cuscare_header_2" mt="0.3rem">
            {' '}
            Phone : +91 70 2580 2580
          </Text>
        </Flex>
      </Flex>

      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="md"
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent bgColor="var(--white-rock-2)">
          <AlertDialogCloseButton />
          <Flex justifyContent="space-evenly" padding="20px">
            <Flex flexDirection="column">
              <AlertDialogBody padding="20px">
                <Text
                  textAlign="center"
                  fontWeight="bolder"
                  className="nunitosans-normal-shark-21px"
                >
                  Thank you for your query.
                </Text>
                <Text
                  textAlign="center"
                  fontWeight="bolder"
                  className="nunitosans-normal-shark-21px"
                  mb="20px"
                >
                  {' '}
                  We will reply to your enquiry as soon as possible.
                </Text>
              </AlertDialogBody>
              <AlertDialogFooter justifyContent={'center'}>
                <Button
                  className="nunitosans-normal-shark-14px"
                  onClick={onClose}
                  bgColor="transparent"
                  border="2px solid"
                  borderRadius="5px"
                  width="100px"
                  boxShadow="0px 3px 6px #00000029"
                >
                  OK
                </Button>

                {/* <Button className="nunitosans-normal-white-14px"
                                    ml={3}
                                    borderRadius="5px"
                                    boxShadow={"0px 3px 6px #330d2f29"}
                                    bgColor={"var(--royal-blue)"}
                                    _hover={{ bgColor: "#727be5" }}
                                    onClick={() => { }}
                                >
                                    DELETE
                                </Button> */}
              </AlertDialogFooter>
            </Flex>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>

      {/* MOBILE */}

      <Box className="mobile_only" bg="white" pr={2} pl={2}>
        <Flex
          flexDirection="column"
          alignItems="center"
          mt="50px"
          mb="60px"
          width="100%"
        >
          <Text className="cuscare_headers">{heading}</Text>
          <Text className="cuscare_header_2" mt="5px">
            {' '}
            Get in touch with us!
          </Text>

          <Box className="cuscare_lottie">
            <lottie-player
              src={
                'https://api.dev.brakeup.in/api/v2/json/Brakeup_conatct_us.json'
              }
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </Box>

          <Flex mt="30px" mb="30px" alignItems="center" width="95%">
            <form className="cuscare_form">
              {isanonymous && (
                <>
                  <Flex>
                    <Box width={'100%'}>
                      <input
                        className="cuscare_input_small"
                        placeholder="Name"
                        name="name"
                        ref={register2({
                          required: true,
                        })}
                      />
                      <div style={{ height: '1.5rem', textAlign: 'left' }}>
                        {errors2 &&
                          errors2.name &&
                          errors2.email.type == 'required' && (
                            <div className="errormsg">Name is required !</div>
                          )}
                      </div>
                    </Box>
                    <Box width={'100%'} marginLeft={'10px'}>
                      <div className="cuscare_inputbox_phone">
                        <span className="prefix">+91</span>
                        <input
                          className="cuscare_input_phone"
                          placeholder="Mobile No"
                          name="mobile"
                          type={'number'}
                          ref={register2({
                            required: true,
                            validate: validatePhone,
                            valueAsNumber: true,
                          })}
                        />
                      </div>

                      <div style={{ height: '1.5rem', textAlign: 'left' }}>
                        {errors2 &&
                          errors2.mobile &&
                          errors2.mobile.type == 'required' && (
                            <div className="errormsg">Mobile is required !</div>
                          )}
                        {errors2 &&
                          errors2.mobile &&
                          errors2.mobile.type == 'validate' && (
                            <div className="errormsg"> Invalid input</div>
                          )}
                      </div>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={'100%'}>
                      <input
                        className="cuscare_input_big"
                        placeholder="Email"
                        name="email"
                        ref={register2({
                          required: true,
                          validate: validateEmail,
                        })}
                      />
                      <div style={{ height: '1.5rem', textAlign: 'left' }}>
                        {errors2 &&
                          errors2.email &&
                          errors2.email.type == 'required' && (
                            <div className="errormsg">Email is required !</div>
                          )}
                        {errors2 &&
                          errors2.email &&
                          errors2.email.type == 'validate' && (
                            <div className="errormsg"> Invalid input</div>
                          )}
                      </div>
                    </Box>
                  </Flex>
                </>
              )}

              <textarea
                className="cuscare_descriptionbox"
                placeholder="Type your message here!"
                name="desc"
                ref={register2({
                  required: true,
                })}
              />
              <button
                style={{ alignSelf: 'center' }}
                onClick={handleSubmit2((d) => onSubmit(d))}
                className="submit_msg_cuscare"
              >
                SEND MESSAGE
              </button>
            </form>
          </Flex>

          <Text className="cuscare_header_2" mt="0.8rem">
            {' '}
            Email : care@brakeup.in
          </Text>
          <Text className="cuscare_header_2" mt="0.3rem">
            {' '}
            Phone : +91 70 2580 2580
          </Text>
        </Flex>
      </Box>

      <AlertDialog
        onClose={onCloseMobile}
        isOpen={isOpenMobile}
        isCentered
        size={'sm'}
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent borderRadius="2px">
          <AlertDialogCloseButton />

          <AlertDialogBody>
            <AlertDialogHeader
              textAlign={'center'}
              className="mob_nunitosans-normal-shark-14px"
              fontSize="14px"
              mt="20px"
            >
              <Text>Thank you for your query.</Text>
              <Text> We will reply to your enquiry as soon as possible.</Text>
            </AlertDialogHeader>
          </AlertDialogBody>
          <AlertDialogFooter justifyContent={'center'} pt={0}>
            <Button
              className="mob_nunitosans-normal-shark-14px"
              fontSize="14px"
              bgColor="var(--white)"
              borderRadius="3px"
              width="70px"
              size="xs"
              onClick={() => {
                onCloseMobile();
              }}
              boxShadow="0px 3px 6px #00000029"
            >
              OK
            </Button>

            {/*        <Button className="mob_nunitosans-normal-shark-14px"
                            ml={3}
                            width='70px'
                            size="xs"
                            fontSize='14px'
                            borderRadius="3px"
                            boxShadow={"0px 0px 3px #050E2740"}
                            bgColor={"var( --white-rock)"}
                            onClick={() => { }}
                        >
                            Edit
                        </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
