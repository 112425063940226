import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Select,
  Text,
  Image,
  VStack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { API_URL } from '../../../services/api';
import { get, post } from '../../../services/api';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const OrderHistory = (props) => {
  let history = useHistory();
  const [order, setOrder] = useState([]);
  const getOrders = () => {
    post('/api/v2/order/replacementbutton/')
      .then((res) => {
        get('/api/v2/order/orderprodlist/')
          .then((res) => {
            setOrder(res);
            // console.log(res, "orderprops");
          })
          .catch((err) => {
            setOrder([]);
          });
      })
      .catch((err) => {
        setOrder([]);
      });
  };
  useEffect(() => {
    getOrders();
  }, []);
  const moveToReplacement = (item) => {
    history.push(
      `/replace/${item.order_id}/${item.prod_id}/${item.vendor_id}/${item.user_id['$oid']}`
    );
  };

  const downloadInvoice = (invoiceurl) => {
    window.open(invoiceurl, 'invoice');
  };

  //toast
  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const cancelOrder = (ord) => {
    // console.log(ord,"ordddddddds");
    post('/api/v2/seller/ordercancel_buyer/', ord)
      .then((res) => {
        Toast2('Order cancelled Successfully !', 'success', '#089520');
        getOrders();
      })
      .catch((err) => {
        Toast2(err?.response?.data.message, 'error', '#f22020');
        getOrders();
      });
  };
  return (
    <Box className="order-items">
      {order.length == 0 ? (
        <Flex
          alignSelf="center"
          flexDirection="column"
          alignItems="center"
          className="flexbox_Empty_State"
        >
          <Box className="savederrorjson">
            <lottie-player
              src={
                'https://api.dev.brakeup.in/api/v2/json/Brakeup_Empty_State.json'
              }
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </Box>

          <Flex
            className="textbox_Empty_State"
            flexDir="column"
            alignItems="center"
          >
            <Text
              className="bold_text_Empty_State nunitosans-bold-shark "
              whiteSpace="nowrap"
              textAlign="center"
            >
              {' '}
              No orders yet
            </Text>
            <Text
              className="text2_Empty_State nunitosans-normal-melanzane"
              whiteSpace="nowrap"
              textAlign="center"
            >
              Go and find products you like{' '}
            </Text>
            <button
              className="btn_empty_state nunitosans-bold-shark"
              onClick={() => history.push('/')}
            >
              Start Shopping
            </button>
          </Flex>
        </Flex>
      ) : (
        <>
          {order &&
            order.map((items) => {
              return (
                <>
                  <Flex
                    bgColor="#ECE9D2"
                    justifyContent="space-between"
                    className="headerdisplayweb order_header"
                  >
                    <Flex className="flexorderplaced" flexDirection="column">
                      <Text className=" order_headings manrope-normal-shark">
                        ORDER PLACED
                      </Text>
                      <Text className=" order_head_sub nunitosans-bold-shark">
                        {moment(items?.created_at['$date']).format(
                          'DD MMM YYYY'
                        )}
                        <span className=" order_head_sub_time nunitosans-semi-bold-shark">
                          {moment(items?.created_at['$date']).format(
                            ' hh:mm a'
                          )}
                        </span>
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center">
                      <Text className="order_headings manrope-normal-shark">
                        TOTAL AMOUNT
                      </Text>
                      <Text className="order_head_sub nunitosans-bold-shark">
                        ₹ {items.order_total}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center">
                      <Text className="order_headings manrope-normal-shark">
                        BUYER
                      </Text>
                      <Text className="order_head_sub nunitosans-bold-shark">
                        {items?.buyer_details?.customer_Name}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center">
                      <Text className="order_headings manrope-normal-shark">
                        ORDER #
                      </Text>
                      <Text className="order_head_sub nunitosans-bold-shark">
                        {items?.buyer_details?.order_Id}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex
                    bgColor="#ECE9D2"
                    justifyContent="space-between"
                    className="headerdisplaytab order_header"
                  >
                    <Flex flexDirection="column">
                      <Flex className="flexorderplaced">
                        <Text className=" order_headings manrope-normal-shark">
                          ORDER PLACED
                        </Text>
                        <Text className=" order_head_sub nunitosans-bold-shark">
                          {moment(items?.created_at['$date']).format(
                            'DD MMM YYYY'
                          )}{' '}
                          <span className=" order_head_sub_time nunitosans-semi-bold-shark">
                            {moment(items?.created_at['$date']).format(
                              ' hh:mm a'
                            )}
                          </span>
                        </Text>
                      </Flex>
                      <Flex className="flexorderplaced" mt="10px">
                        <Text className="order_headings manrope-normal-shark">
                          TOTAL AMOUNT
                        </Text>
                        <Text className="order_head_sub nunitosans-bold-shark">
                          ₹ {items.order_total}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex flexDirection="column">
                      <Flex
                        className="flexorderplaced"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text className="order_headings manrope-normal-shark">
                          BUYER
                        </Text>
                        <Text
                          textAlign="right"
                          maxW="100px"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          className="order_head_sub nunitosans-bold-shark"
                        >
                          {items?.buyer_details?.customer_Name}
                        </Text>
                      </Flex>
                      <Flex
                        className="flexorderplaced"
                        alignItems="center"
                        justifyContent="space-between"
                        mt="10px"
                      >
                        <Text className="order_headings manrope-normal-shark">
                          ORDER #
                        </Text>
                        <Text className="order_head_sub nunitosans-bold-shark">
                          {items?.buyer_details?.order_Id}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>

                  {items.order_prod_details &&
                    items.order_prod_details.map((pro) => {
                      return (
                        <Flex
                          boxShadow="0px 3px 6px #00000029"
                          flexDirection="column"
                          width="100%"
                        >
                          <Flex className="orderdata">
                            <Image
                              onClick={() => props.ToProductDetailPage(pro)}
                              className="order_image"
                              cursor="pointer"
                              objectFit="contain"
                              src={pro?.image[0]}
                            />
                            <Flex flexDirection="column" width="100%">
                              <Flex justifyContent="space-between">
                                <VStack
                                  alignItems="flex-start"
                                  marginLeft="2vw"
                                >
                                  <div
                                    onClick={() =>
                                      props.ToProductDetailPage(pro)
                                    }
                                    className="orderredirect"
                                  >
                                    <Text className=" order_brand nunitosans-black-venus">
                                      {pro.brand}
                                    </Text>
                                    <Box alignItems="baseline">
                                      <Text className=" order_product nunitosans-normal-melanzane">
                                        {pro.prod_name}
                                      </Text>
                                      {pro.vehicle_details && (
                                        <Text
                                          className=" order_product_data nunitosans-normal-melanzane"
                                          overflow="hidden"
                                          textOverflow="ellipsis"
                                        >
                                          {pro?.vehicle_details?.make}{' '}
                                          {pro?.vehicle_details?.model}{' '}
                                          {pro?.vehicle_details?.version}{' '}
                                          {pro?.vehicle_details?.year}{' '}
                                          {pro?.vehicle_details?.fueltype}
                                        </Text>
                                      )}
                                    </Box>
                                  </div>
                                  <Flex className=" order_quantity_dis nunitosans-normal-mountain-mist">
                                    <Text marginRight="2vw">
                                      Quantity : {pro.quantity}{' '}
                                    </Text>
                                    <Text className="dispatchorder">
                                      Dispatch from : {pro.vendor_city}{' '}
                                    </Text>
                                  </Flex>
                                </VStack>
                                <Box>
                                  {pro.buyer_status == 'DELIVERED' ? (
                                    <Text
                                      whiteSpace="nowrap"
                                      className="order_dotted_text nunitosans-normal-mountain-mist"
                                      borderStyle="dashed"
                                      borderColor="#959595"
                                      border="1px dashed"
                                      color="#959595"
                                    >
                                      DELIVERY COMPLETED
                                    </Text>
                                  ) : (
                                    ''
                                  )}
                                </Box>
                                <VStack>
                                  <Box>
                                    <Button
                                      display="none"
                                      className="order_button_big"
                                      bgColor="var(--baby-powder)"
                                      borderRadius="5px"
                                      boxShadow="0px 2px 6px #00000029"
                                    >
                                      {' '}
                                      Replacement Accepted
                                    </Button>
                                  </Box>
                                  {pro?.replacement == true ? (
                                    <Button
                                      className="order_button_big"
                                      mt="0px"
                                      bgColor="var(--baby-powder)"
                                      borderRadius="5px"
                                      boxShadow="0px 2px 6px #00000029"
                                      onClick={() => moveToReplacement(pro)}
                                    >
                                      {pro.replacement_status
                                        ? pro.replacement_status
                                        : 'Replacement'}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="order_button_big"
                                      disabled
                                      mt="0px"
                                      bgColor="#00000029"
                                      borderRadius="5px"
                                      boxShadow="0px 2px 6px #00000029"
                                    >
                                      {pro.replacement_status
                                        ? pro.replacement_status
                                        : 'Replacement'}
                                    </Button>
                                  )}
                                  <Button
                                    className="order_button_big"
                                    disabled
                                    bgColor="#00000029"
                                    borderRadius="5px"
                                    boxShadow="0px 2px 6px #00000029"
                                  >
                                    {' '}
                                    Write Review
                                  </Button>
                                </VStack>
                              </Flex>
                              <Flex
                                alignSelf="flex-end"
                                className="order_bot_btn"
                                alignItems="center"
                              >
                                {pro.isInvoiced && (
                                  <Button
                                    className="order_button_sml"
                                    bgColor="var(--baby-powder)"
                                    borderRadius="5px"
                                    boxShadow="0px 2px 6px #00000029"
                                    mr="10px"
                                    onClick={() => {
                                      downloadInvoice(pro?.invoice);
                                    }}
                                  >
                                    Invoice
                                  </Button>
                                )}
                                {!pro.isInvoiced && !pro.isCancelled && (
                                  <Button
                                    className="order_button_sml"
                                    bgColor="var(--baby-powder)"
                                    borderRadius="5px"
                                    boxShadow="0px 2px 6px #00000029"
                                    mr="10px"
                                    onClick={() => {
                                      cancelOrder({
                                        order_id: pro.order_id,
                                        prod_id: pro.prod_id,
                                      });
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                )}
                                {/* <Button className="order_button_med"
                          bgColor="var(--baby-powder)" borderRadius='5px'
                          boxShadow='0px 2px 6px #00000029'
                          mr='10px'  >{pro.order_status} </Button> */}
                                <Flex
                                  flexDir="column"
                                  marginLeft="1vw"
                                  marginRight="1vw"
                                >
                                  <Text className=" order_quantity_dis nunitosans-normal-mountain-mist">
                                    Order status :
                                  </Text>
                                  <Text className=" order_headings nunitosans-normal-melanzane">
                                    {pro?.buyer_status}
                                  </Text>
                                </Flex>
                                {pro?.track_id && (
                                  <Button
                                    bgColor="#414bdb"
                                    className="order_button_track"
                                    padding={1}
                                    color="white"
                                    borderRadius="3px"
                                    onClick={() => {
                                      downloadInvoice(pro?.track_id);
                                    }}
                                  >
                                    TRACK{' '}
                                  </Button>
                                )}
                              </Flex>
                            </Flex>
                          </Flex>
                          <Box className="order_divider">
                            <Divider color="#C9C9C9" />
                          </Box>
                        </Flex>
                      );
                    })}
                </>
              );
            })}
        </>
      )}
    </Box>
  );
};
