import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react';
// import topAd from '../../assets/sarath_IMG/Mobile Advertisment 3.png'
// import bottomAd from '../../assets/sarath_IMG/Mobile Advertisment 1.png'
import { FaCheck } from 'react-icons/fa';
import { HiChevronDoubleUp } from 'react-icons/hi';
import { SearchIcon } from '@chakra-ui/icons';
import { FaChevronDown } from 'react-icons/fa';

import bottomAd1 from '../../assets/Ads/Mob/Brembo Banner.jpg';
// import bottomAd2 from '../../assets/Ads/Mob/ATE Banner.jpg'
// import AdM4 from '../../assets/Ads/Mob/Banner 4.jpg'

import { get } from '../../services/api';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../../services/api';

import Loader from '../../assets/sarath_IMG/animated car.gif';

import { useQuery } from '../../utilities';
import ItemDisplayMob3 from './ItemDisplayMob3';
import ProductCardScroll from './ProductCardScroll';
// import { AdsWeb } from '../../ZAds/AdsWeb';

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

// const useIntersection = (element, rootMargin) => {
//     const [isVisible, setState] = useState(false);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 setState(entry.isIntersecting);
//             }, { rootMargin }
//         );

//         element.current && observer.observe(element.current);

//         return () => observer.unobserve(element.current);
//     }, []);

//     return isVisible;
// };

export const CustomSelectFilterMobile3 = (props) => {
  // console.log(props, "CustomSelectCustomSelect");

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [textInput, settextInput] = useState('');

  let options = [];
  props.options &&
    props.options.forEach((element) => {
      if (element?.value?.toLowerCase().includes(textInput)) {
        options.push(
          <Text
            p={2.5}
            className="customselectFont"
            fontWeight="bold"
            bgColor={props.value == element.value ? '#ECE9D2' : ''}
            textTransform={props.name == 'fuel' ? 'capitalize' : 'none'}
            onClick={() => {
              props.onChange(
                props.name == 'make'
                  ? element.id
                  : props.name == 'model'
                  ? element.id
                  : element.value,
                props.name,
                props.name == 'make'
                  ? element.value
                  : props.name == 'model'
                  ? element.value
                  : ''
              );
              settextInput('');
            }}
          >
            {element.value}
          </Text>
        );
      }
    });

  return (
    <Box className="font_Nunito" textTransform="none" position="relative">
      {/* {isComponentVisible ?

                <Flex bgColor='white' borderRadius={5} boxShadow='0px 1px 1px #00000029' cursor={'pointer'}
                    className={props.errorFlexboxstyle == 1 ? 'alerts_border' : ''}
                    style={props.flexboxStyle} pr={3} alignItems='center' ref={ref} id={props.id}
                    onClick={() => { setIsComponentVisible(true); }} >

                    <input autoFocus={isComponentVisible} style={{ fontSize: '16px', width: '40%' }} className="customselectFont"
                        onChange={e => { settextInput(e.target.value.toLowerCase()); console.log(e.target.value, "e.target.value.toLowerCase()"); }}
                        placeholder={props.placeholder}
                        value={textInput ?? ""}
                        id={props.id}
                        type="text" />

                </Flex>

                : */}
      <Flex
        bgColor={props.value ? '#f9e1078f' : 'white'}
        borderRadius={5}
        boxShadow="0px 1px 1px #00000029"
        cursor={'pointer'}
        className={props.errorFlexboxstyle == 1 ? 'alerts_border' : ''}
        style={props.flexboxStyle}
        pr={3}
        alignItems="center"
        ref={ref}
        justifyContent="space-between"
        onClick={() => {
          setIsComponentVisible(true);
        }}
      >
        <input
          style={
            props.value != ''
              ? {
                  backgroundColor: '#f9e10700',
                  fontSize: '16px',
                  fontWeight: '600',
                  width: '55%',
                }
              : {
                  background: 'white',
                  fontSize: '16px',
                  fontWeight: '400',
                  width: '50%',
                }
          }
          className="customselectFont"
          placeholder={props.placeholder}
          value={props.value ?? ''}
          readOnly
          id={props.id}
          type="text"
        />
        {props?.value ? (
          ''
        ) : (
          <FaChevronDown
            className="FaChevronDownsize"
            color="#9D9EA4"
            d={isComponentVisible ? 'none' : 'block'}
            onClick={() => {
              setIsComponentVisible(false);
            }}
          />
        )}
      </Flex>

      {/* } */}

      <Box
        position={'absolute'}
        top={'0px'}
        // style={props.name == "brand" ? { top: "-230px", minHeight: "220px" } : {}}
        zIndex={5}
        width="100%"
        mt={0}
        bgColor="white"
        boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2)"
        borderRadius={5}
        d={isComponentVisible ? 'flex' : 'none'}
        maxHeight={props.boxheight ?? '220px'}
        flexDirection="column"
        overflowX="hidden"
        overflowY="scroll"
      >
        {options}
      </Box>
    </Box>
  );
};

export const FilterPageLayoutMobile3 = () => {
  let history = useHistory();
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState({});
  const [mobfilter, setMobFilter] = useState({});

  const [sortTitle, setsortTitle] = useState('Recommended');
  const [sortedOption, setsortedOption] = useState();
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [version, setVersion] = useState([]);
  const [year, setYear] = useState([]);
  const [apiURL, setApiURL] = useState('');
  const [drawerType, setdrawerType] = useState('filter');
  const [fuel, setFuel] = useState([]);
  const [type, setType] = useState([]);
  const [reViewed, setReViewed] = useState([]);
  const [brands, setBrands] = useState([]);
  const [showShowMore, setShowShowMore] = useState(false);
  const [showBacktoTop, setshowBacktoTop] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [hideVersion, sethideVersion] = useState(true);
  const [brandArray, setbrandArray] = useState([]);
  const [categoryArray, setcategoryArray] = useState([]);
  const [filteredoptions, setFilteredoptions] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const [isSelected3, setisSelected3] = useState(false);
  const [isSelected2, setisSelected2] = useState(false);
  const [isSelected1, setisSelected1] = useState(false);
  const [SearchButtonLOader, setSearchButtonLOader] = useState(false);

  const token = JSON.parse(localStorage.getItem('token')).data.credAuthResult;

  let query = useQuery();

  const getQueries = () => {
    const categoryQUERY = query.getAll('category');
    const brandQUERY = query.getAll('brand');
    const makeQUERY = query.get('make');
    const makeidQUERY = query.get('make_id');
    const modelQUERY = query.get('model');
    const modelidQUERY = query.get('model_id');
    const yearQUERY = query.get('year');
    const versionQUERY = query.get('version');
    const fuelQUERY = query.get('fuel_type');
    const axleQUERY = query.get('axle');

    let newFilter = { ...filter };
    let categoryarray = newFilter.category || [];
    let brandarray = newFilter.brand || [];

    if (makeQUERY) {
      newFilter.make = [makeQUERY];
    }
    if (makeidQUERY) {
      newFilter.make_id = [makeidQUERY];
      getModel(makeidQUERY);
      setisSelected1(true);
    }
    if (modelQUERY) {
      newFilter.model = [modelQUERY];
    }
    if (modelidQUERY) {
      newFilter.model_id = [modelidQUERY];
      getYear(modelidQUERY);
      setisSelected2(true);
    }
    if (yearQUERY) {
      newFilter.year = [yearQUERY];
      getVersionFuel(yearQUERY, modelidQUERY);
      setisSelected3(true);
    }
    if (versionQUERY) {
      newFilter.version = [versionQUERY];
    }
    if (fuelQUERY) {
      newFilter.fuel = [fuelQUERY.charAt(0).toUpperCase() + fuelQUERY.slice(1)];
    }
    if (axleQUERY) {
      newFilter.type = [axleQUERY];
    }
    if (categoryQUERY.length != 0) {
      newFilter.category = categoryQUERY;
      setcategoryArray(categoryQUERY || []);
    }
    if (brandQUERY.length != 0) {
      newFilter.brand = brandQUERY;
      setbrandArray(brandQUERY || []);
    }

    // setFilter(newFilter);
    // console.log(brandQUERY, "brandQUERYbrandQUERY");
    getFilter(newFilter);
  };

  useEffect(() => {
    getQueries();

    const filter = JSON.parse(localStorage.getItem('mobfilter') || '{}');
    // const url = getFilter(filter);
    // getProducts(url, true);
    // getFilter(filter)
    getMake();
    getBrands();
    let recentUrl = `/api/v2/product/recently_viewed/?id=${token.id}`;
    recentlyViewed(recentUrl);
  }, []);

  const recentlyViewed = (url) => {
    get(url)
      .then((res) => {
        let data = [...res.data, ...reViewed];
        setReViewed(data);
      })
      .catch((err) => {
        setReViewed([]);
      });
  };

  const getBrands = () => {
    get('/api/v2/products/add_brand/')
      .then((res) => {
        let brand = [];
        res &&
          res.data &&
          res.data.map((items) => {
            brand.push({ value: items.brand_name });
          });
        setBrands(brand);
      })
      .catch((err) => {
        setBrands([]);
      });
  };

  const getMake = () => {
    get('/api/v2/vehiclemake/')
      .then((res) => {
        let make = [];
        res &&
          res.data &&
          res.data.map((items) => {
            make.push({ value: items.make, id: items._id['$oid'] });
          });
        setMake(make);
      })
      .catch((err) => {
        setMake([]);
      });
  };

  const getModel = (value) => {
    setspinner(true);
    // console.log(value, name, "getModelgetModel");

    // let newFilter = { ...filter };
    // newFilter.make = [name];
    // newFilter.make_id = [value];
    // getFilter(newFilter);

    //     newFilter.model = [model[0].value];
    //     newFilter.model_id = [model[0].id];
    //     setFilter(newFilter);
    //     getYear(model[0].id)

    let model = [];
    let newFilter = { ...filter };

    get(`/api/v2/vehiclemodel/?id=${value}`)
      .then((res) => {
        res &&
          res.data &&
          res.data.map((items) => {
            model.push({ value: items.model, id: items._id['$oid'] });
          });
        setModel(model);
        setspinner(false);

        if (model && model.length == 1) {
          setFilter((filter) => ({ ...filter, model: [model[0].value] }));
          setFilter((filter) => ({ ...filter, model_id: [model[0].id] }));
          getYear(model[0].id);
        }
      })
      .catch((err) => {
        setModel([]);
        setVersion([]);
        setYear([]);
      });
  };
  const getYear = (value, model, name) => {
    setspinner(true);

    let newFilter = { ...filter };
    // newFilter.model = [name];
    // newFilter.model_id = [value];
    // getFilter(newFilter);
    get(`/api/v2/make_details/?id=${value}`)
      .then((res) => {
        let year = [];
        res &&
          res.year &&
          res.year.map((items) => {
            year.push({ value: items });
          });
        setYear(year);

        let checkIfVersion = [];

        res &&
          res.version.map((items) => {
            if (!items.includes('Version')) {
              checkIfVersion.push({ value: items });
              if (checkIfVersion.length != 0) {
                sethideVersion(false);
              }
            } else {
              sethideVersion(true);
            }
          });

        if (year && year.length == 1) {
          setFilter((filter) => ({ ...filter, year: [year[0].value] }));
          getVersionFuel(year[0].value, filter.model_id);
        }

        setspinner(false);
      })
      .catch((err) => {
        setYear([]);
      });
  };

  const getVersionFuel = (value, model_id) => {
    setspinner(true);

    get(`/api/v2/veh_version/?model_id=${model_id}&year=${value}`)
      .then((res) => {
        let version = [];
        let fuel = [];

        res &&
          res.version.map((items) => {
            if (!items.includes('Version')) {
              version.push({ value: items });
            }
          });
        setVersion(version);

        res &&
          res.fuel_type &&
          res.fuel_type.map((items) => {
            fuel.push({
              value: items.charAt(0).toUpperCase() + items.slice(1),
            });
          });
        setFuel(fuel);
        setType([{ value: 'Front' }, { value: 'Rear' }]);
        setspinner(true);

        // console.log(filter, version[0], [fuel[0].value], "fuel_typefuel_type2");

        if ((version && version.length == 1) || (fuel && fuel.length == 1)) {
          if (version.length == 1) {
            setFilter((filter) => ({ ...filter, version: [version[0].value] }));
          }
          if (fuel.length == 1) {
            // console.log("fuel_typefuel_type");
            setFilter((filter) => ({ ...filter, fuel: [fuel[0].value] }));
          }

          // console.log(filter, "fuel_typefuel_type3");
          // let newFilter = {};s
          // newFilter.fuel = [fuel[0].value]
          // setFilter(newFilter);
        }
      })
      .catch((err) => {
        setVersion([]);
        setFuel([]);
      });
  };

  const onChange = (id, value, name) => {
    // console.log(id, value, name, filter, "onChangevsluesfilert");

    let newFilter = { ...filter };
    if (value == 'make') {
      newFilter.make = [name];
      newFilter.make_id = [id];

      delete newFilter['model'];
      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];

      getModel(id, value, name);
      setModel([]);
      setYear([]);
      setVersion([]);
      setFuel([]);
      setType([]);

      setisSelected1(true);
      setisSelected2(false);
      setisSelected3(false);
    } else if (value == 'model') {
      newFilter.model = [name];
      newFilter.model_id = [id];

      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];

      getYear(id, value, name);

      setYear([]);
      setVersion([]);
      setFuel([]);
      setType([]);

      setisSelected2(true);
      setisSelected3(false);
    } else if (value == 'year') {
      newFilter.year = [id];

      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
      seterrorFlexboxstyle(false);

      getVersionFuel(id, newFilter.model_id);

      setVersion([]);
      setFuel([]);
      setType([]);

      setisSelected3(true);
    } else if (value == 'version') {
      newFilter.version = [id];
      delete newFilter['fuel'];
      delete newFilter['type'];
      // getFilter(newFilter);

      // setFuel([])
    } else if (value == 'fuel') {
      newFilter.fuel = [id];
      delete newFilter['type'];
      // getFilter(newFilter);
    } else if (value == 'type') {
      newFilter.type = [id];
      // getFilter(newFilter);
    }

    getFilter(newFilter);
  };

  let paramurl = '/filter';
  const seller_part_no = JSON.parse(
    localStorage.getItem('seller_part_no') || '""'
  );

  const getFilter = (filter) => {
    setFilter(filter);
    //     console.trace("getFiltergetFilter")
    let url = '/api/v2/search/buyer_search/?q=Homepage_filter';
    if (filter.make) {
      // todo add seperator
      url = `${url}&make_id=${filter.make_id}`;
      // getModel(filter.make_id, "make", filter.make);
      paramurl = `${paramurl}?make=${filter.make}&make_id=${filter.make_id}`;
    }
    if (filter.model) {
      url = `${url}&model_id=${filter.model_id}`;
      // getYear(filter.model_id, "model". filter.model);
      paramurl = `${paramurl}&model=${filter.model}&model_id=${filter.model_id}`;
    }
    if (filter.year) {
      url = `${url}&year=${filter.year}`;
      // getVersionFuel(filter.year);
      paramurl = `${paramurl}&year=${filter.year}`;
    }
    if (filter.version) {
      url = `${url}&version=${filter.version}`;
      // getVersionFuel(filter.year);
      paramurl = `${paramurl}&version=${filter.version}`;
    }
    if (filter.fuel) {
      url = `${url}&fuel_type=${filter?.fuel.toString().toLowerCase()}`;
      // getVersionFuel(filter.year, filter);
      paramurl = `${paramurl}&fuel_type=${filter?.fuel
        .toString()
        .toLowerCase()}`;
    }
    if (filter.type) {
      let typeExceptions = ['category', 'brand'];
      let seperator =
        Object.keys(filter).length == 1
          ? '?'
          : Object.keys(filter).includes('make')
          ? '&'
          : typeExceptions.some((el) => Object.keys(filter).includes(el))
          ? '?'
          : '&';
      url = `${url}&axle=${filter.type}`;
      paramurl = `${paramurl}${seperator}axle=${filter.type}`;
    }
    if (filter.category) {
      // console.log(Object.keys(filter), "filter.category");
      let seperator = '&';
      if (Object.keys(filter).length == 1) {
        seperator = '?';
      } else if (
        Object.keys(filter).length == 2 &&
        Object.keys(filter).includes('brand')
      ) {
        seperator = '?';
      }
      let categoryurl = `category=`;
      filter.category.forEach((elem, index) => {
        categoryurl += elem;
        if (index != filter.category.length - 1) {
          categoryurl += '&category=';
        }
      });
      if (filter?.category?.length == 0) {
        categoryurl = ' ';
      }
      url = `${url}&${categoryurl}`;
      paramurl = `${paramurl}${seperator}${categoryurl}`;
    }
    if (filter.brand) {
      // console.log(Object.keys(filter).length, "filter.lengthfilter.length");
      let seperator = Object.keys(filter).length == 1 ? '?' : '&';
      let brandurl = `brand=`;
      filter.brand.forEach((elem, index) => {
        brandurl += elem;
        if (index != filter.brand.length - 1) {
          brandurl += '&brand=';
        }
      });
      if (filter?.brand?.length == 0) {
        brandurl = ' ';
      }
      url = `${url}&${brandurl}`;
      paramurl = `${paramurl}${seperator}${brandurl}`;
    }

    if (seller_part_no && seller_part_no != '') {
      url = `${url}&seller_part_no=${seller_part_no}`;
      setTimeout(() => {
        window.localStorage.removeItem('seller_part_no');
      }, 3000);
    }

    // console.log(filter,paramurl, "filter");

    window.history.pushState(null, '', `${paramurl}`);

    // console.log(url, "filterfilterfilter");
    setApiURL(url);
    getProducts(url, true);
  };

  const getProducts = (url, load) => {
    // console.log(url, filter, "filter mobile");
    let sorturl = `${url}&&sort_by=${
      sortedOption ? sortedOption.sort_by : 'brand'
    }&order=${sortedOption ? sortedOption.order : '1'}`;
    get(sorturl)
      .then((res) => {
        let data = [];
        if (load) {
          data = [...res.data];
        } else {
          data = [...products, ...res.data];
        }
        setProducts(data);
        setIsLoading(false);
        if (data?.length >= 24 && res?.data?.length != 0) {
          // console.log(data.length, res.data.length, "items.length");
          setShowShowMore(true);
        } else {
          setShowShowMore(false);
        }
      })
      .catch((err) => {
        setProducts([]);
        setIsLoading(false);
      });
  };

  //scroll to top & Back to Top

  const ScrolltoTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setshowBacktoTop(false);
  };

  const [scrolling, setscrolling] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const [height, setHeight] = useState(false);

  const handleScroll = () => {
    // console.log(window.pageYOffset, "handleScrollhandleScroll");

    if (window.pageYOffset >= 6000) {
      setshowBacktoTop(true);
    } else {
      setshowBacktoTop(false);
    }
  };

  //show back to top icon

  const showbacktotopref = useRef();
  const onScreen = useOnScreen(showbacktotopref, '-10px');

  function useOnScreen(ref, rootMargin = '0px') {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin,
        }
      );
      if (ref.current) {
        observer?.observe(ref.current);
      }
      return () => {
        if (ref.current && observer) {
          observer?.unobserve(ref.current);
        }
      };
    }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount
    return isIntersecting;
  }

  const handleVisibleButton = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);

    if (scrollPosition > 50) {
      return setshowGoTop('goTop');
    } else if (scrollPosition < 50) {
      return setshowGoTop('goTopHidden');
    }
  };

  // useEffect(() => {
  //     const element = document.getElementById('element-id');
  //     if (element && element.getBoundingClientRect().height >= 2000) {
  //         setHeight(true)
  //     }
  //     console.log(height, element.getBoundingClientRect().height, "getBoundingClientRectgetBoundingClientRect");
  // }, [window.scrollY]);

  const onNext = () => {
    let url = apiURL;
    if (products) {
      const lastItem = products[products.length - 1];
      url = `${url}&previousId=${lastItem['_id']['$oid']}`;
      url = `${url}&limit=12`;
      url = `${url}&order=1`;
    }
    getProducts(url, false);
  };

  const onRectClick = () => {
    let url = `/api/v2/product/recently_viewed/?id=${token.id}`;
    if (reViewed) {
      const lastItem = reViewed[reViewed.length - 1];
      url = `${url}&previousId=${lastItem['_id']['$oid']}`;
      url = `${url}&limit=10`;
      url = `${url}&order=1`;
    }
    recentlyViewed(url);
  };

  // S O R T

  const Sort = (element) => {
    getProducts(apiURL, true);
    onClose();
    if (element) {
      setsortTitle(element.title);
    }
  };

  let sortdata = [
    {
      title: 'New Arrivals',
      value: '4',
      sort_by: 'created_at',
      // sort_by: "mrp",
      order: '1',
    },
    {
      title: 'Price Low to High',
      value: '2',
      sort_by: 'vendor_products.selling_price',
      order: '1',
    },
    {
      title: 'Price High to Low',
      value: '3',
      sort_by: 'vendor_products.selling_price',
      order: '-1',
    },
    {
      title: 'Recommended',
      value: '1',
      sort_by: 'brand',
      order: '1',
    },
    {
      title: 'Discounts',
      value: '5',
      sort_by: 'vendor_products.discount',
      order: '-1',
    },
  ];

  let mobsortoptions = [];
  sortdata.forEach((element) => {
    mobsortoptions.push(
      <Flex
        onClick={() => {
          setsortTitle(element.title), setsortedOption(element);
        }}
        bgColor={element.title == sortTitle ? '#ECE9D2' : 'none'}
        borderRadius="md"
        _focus={{ bgColor: 'none', boxShadow: 'none' }}
        fontWeight={element.title == sortTitle ? 700 : 400}
        justifyContent="space-between"
        alignItems="center"
        p="10px"
      >
        <Text>{element.title}</Text>
        <FaCheck
          visibility={element.title == sortTitle ? 'visible' : 'hidden'}
          fontWeight={600}
          color="#1ABC35"
        />
      </Flex>
    );
  });

  let brandList = [];
  brands.forEach((element) => {
    brandList.push(
      <Flex
        onClick={() => {
          onClickCategoryBrand(element.value, 'brand');
        }}
        bgColor={brandArray.includes(element.value) ? '#ECE9D2' : 'none'}
        borderRadius="md"
        _focus={{ bgColor: 'none', boxShadow: 'none' }}
        fontWeight={brandArray.includes(element.value) ? 600 : 500}
        justifyContent="space-between"
        alignItems="center"
        textTransform={'uppercase'}
        mb="3px"
        p="10px"
        pr={6}
      >
        <Text fontSize="14px" pl={6}>
          {element.value}
        </Text>
        <FaCheck
          visibility={brandArray.includes(element.value) ? 'visible' : 'hidden'}
          fontWeight={600}
          color="#1ABC35"
        />
      </Flex>
    );
  });

  useEffect(() => {
    // console.log(props.filter["category"], "props.filter");
    let filteredoptions = [];
    filter &&
      Object.keys(filter).map((element) => {
        if (
          element == 'category' ||
          element == 'brand'
          // && element != "category" && element != "brand"
        ) {
          // console.log(element, "elementprops.filter");
          if (element && filter[element]) {
            filter[element].map((value) => {
              filteredoptions.push(
                // <Flex p='4px 12px' mr={2} boxShadow='xs' borderRadius='2xl' fontSize='12px' bgColor='white' alignItems='center' onClick={() => OnDrawerOpen('filters')} >
                //     <Text pr={1} className='font_NunitoA' whiteSpace={'nowrap'} >{value}</Text>
                //     <FaCheck fontSize='10px' fontWeight={600} color='#1ABC35' />
                // </Flex >

                value
              );
            });
          }
        }
      });
    // console.log(props.filter,);
    setFilteredoptions(filteredoptions);
    // if (filteredoptions.length == 0) {
    //     setFilteredValues([])
    // }
  }, [filter]);

  const onCloseFilter = (id) => {
    let newFilter = { ...filter };
    delete newFilter[id];
    // console.log("onclose", id);

    if (id == 'make') {
      delete newFilter['make_id'];
      delete newFilter['model'];
      delete newFilter['model_id'];
      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
      setModel([]);
      setYear([]);
      setVersion([]);
      setFuel([]);
      setType([]);
    } else if (id == 'model') {
      delete newFilter['model_id'];
      delete newFilter['year'];
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];

      setYear([]);
      setVersion([]);
      setFuel([]);
      setType([]);
    } else if (id == 'year') {
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
      setVersion([]);
      setFuel([]);
      setType([]);
    } else if (id == 'version') {
      delete newFilter['fuel'];
      delete newFilter['type'];
      setFuel([]);
      setType([]);
    } else if (id == 'fuel') {
      delete newFilter['type'];
    }
    getFilter(newFilter);
  };

  const onClear = (type) => {
    let newFilter = { ...filter };
    if (type == 'all') {
      delete newFilter['make'];
      delete newFilter['make_id'];
      delete newFilter['model'];
      delete newFilter['model_id'];
      delete newFilter['version'];
      delete newFilter['year'];
      delete newFilter['type'];
      delete newFilter['fuel'];
      // delete newFilter['category'];
      // delete newFilter['brand'];
      setModel([]);
      setYear([]);
      setVersion([]);
      setFuel([]);
      setType([]);
      // setcategoryArray([])
      // setbrandArray([])
      setisSelected1(false);
      setisSelected2(false);
      setisSelected3(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    getFilter(newFilter);
  };

  const [errorFlexboxstyle, seterrorFlexboxstyle] = useState(false);

  const onBoxClick = (element) => {
    if (element.category == 'Brake Fluid') {
      history.push(`/product/${element['_id']['$oid']}`);
    } else if (
      filter.hasOwnProperty('make') == false ||
      filter.hasOwnProperty('model') == false ||
      filter.hasOwnProperty('year') == false
    ) {
      seterrorFlexboxstyle(true);
      OnDrawerOpen('select_car');
    } else {
      history.push(
        `/product/${element['_id']['$oid']}/${filter?.make_id[0]}/${
          filter?.model_id[0]
        }/${filter?.year[0]}${
          (filter?.version ? '/' + filter?.version[0] : '') +
          (filter?.fuel ? '/' + filter?.fuel[0] : '') +
          (filter?.type ? '/' + filter?.type[0] : '')
        }`
      );

      // if (filter.version) {
      //     if (filter.fuel && filter.type) {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}/${filter?.version[0]}/${filter?.fuel[0]}/${filter?.type[0]}`);
      //     } else if (filter.fuel && !filter.type) {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}/${filter?.version[0]}/${filter?.fuel[0]}`);
      //     } else if (!filter.fuel && filter.type) {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}/${filter?.version[0]}/${filter?.type[0]}`);
      //     } else {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}/${filter?.version[0]}`);
      //     }
      // } else {
      //     if (filter.fuel && filter.type) {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}/${filter?.fuel[0]}/${filter?.type[0]}`);
      //     } else if (filter.fuel && !filter.type) {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}/${filter?.fuel[0]}`);
      //     } else if (!filter.fuel && filter.type) {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}/${filter?.type[0]}`);
      //     } else {
      //         history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter?.model_id[0]}/${filter?.year[0]}`);
      //     }
      // }
      // history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter.model_id[0]}/${filter.year[0]}/${filter.version ? filter.version[0] : "null"}/${filter.fuel ? filter.fuel[0] : "null"}/${filter.type ? filter.type[0] : "null"}`);
    }
  };

  //got mobile drawer open

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const OnDrawerOpen = (type) => {
    setdrawerType(type);
    onOpen();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeCategory = (event) => {
    let newFilter = { ...filter };

    // console.log(newFilter, "filteronchangecat");
    let paramurl = '';
    let categoryarray = filter.category || [];
    if (event.target.checked) {
      // console.log(event.target.value, event.target.name, "eventsssss");
      if (event.target.name == 'category') {
        newFilter.category = [...categoryarray, event.target.value];
      }
    } else {
      if (event.target.name == 'category') {
        // console.log('⛔️ Checkbox is NOT checked');
        // console.log(event.target.value, event.target.name, "eventsssss");
        // console.log(location.href);

        paramurl = '?category=';
        newFilter.category = categoryarray.filter(
          (item) => item !== event.target.value
        );
        newFilter.category.forEach((elem, index) => {
          paramurl += elem;
          if (index != newFilter.category.length - 1) {
            paramurl += '&category=';
          }
        });
        if (newFilter.category.length == 0) {
          delete newFilter['category'];
        }
      }
    }
    getFilter(newFilter);
  };

  const onClickBrandAd = (value) => {
    let newFilter = { ...filter };

    if (brandArray.includes(value)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      newFilter.brand = [...brandArray, value];
      setbrandArray([...brandArray, value]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    getFilter(newFilter);
  };

  const onClickCategoryBrand = (value, type) => {
    // setbrandArray(filter.brand || [])
    // setcategoryArray(filter.category || [])
    // console.log(value, type, "onClickCategoryBrandonClickCategoryBrand");
    if (type == 'brand') {
      if (brandArray.includes(value)) {
        let brandarrayy = brandArray.filter((item) => item !== value);
        setbrandArray(brandarrayy);
      } else {
        setbrandArray([...brandArray, value]);
      }
    }
    if (type == 'category') {
      if (categoryArray.includes(value)) {
        let categoryarrayy = categoryArray.filter((item) => item !== value);
        setcategoryArray(categoryarrayy);
      } else {
        setcategoryArray([...categoryArray, value]);
      }
    }
  };

  const onSubmitCategoryBrand = (type) => {
    let newFilter = { ...filter };
    newFilter.brand = brandArray;
    newFilter.category = categoryArray;

    if (type == 'clear') {
      setbrandArray([]);
      newFilter.brand = [];
      delete newFilter['brand'];

      setcategoryArray([]);
      newFilter.category = [];
      delete newFilter['category'];
    }
    if (newFilter?.brand?.length == 0) {
      delete newFilter['brand'];
    }
    if (newFilter?.category?.length == 0) {
      delete newFilter['category'];
    }
    getFilter(newFilter);
  };

  useEffect(() => {
    if (isSelected3) {
      setSearchButtonLOader(true);
      setTimeout(() => {
        setSearchButtonLOader(false);
      }, 1100);
    }
  }, [isSelected3]);

  const onClickFilterProceedButton = (value) => {
    // console.log(filter, Object.keys(filter).length, "onsearchhhhh");

    function isEmptyObject(obj) {
      for (var name in obj) {
        return false;
      }
      return true;
    }
    if (isEmptyObject(filter)) {
      document.getElementById('CustomSelectMake').click();
    } else if (!isSelected3) {
      if (isSelected2) {
        document.getElementById('CustomSelectYear').click();
      } else {
        document.getElementById('CustomSelectModel').click();
      }
    } else {
      onClose();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Flex
      className="layout_bg"
      flexDir={'column'}
      alignItems="center"
      backgroundColor="#F5F5F5"
      overflowX="hidden"
    >
      <Box mt="1px" width="100%">
        <Flex
          width="100%"
          height="64px"
          justifyContent="space-evenly"
          zIndex="5"
          boxShadow={'0px 3px 6px #00000026'}
        >
          <Flex
            fontSize="16px"
            className="font_NunitoUp"
            fontWeight="bold"
            alignItems="center"
            justifyContent="center"
            width="50%"
            p="10px"
            boxShadow="xs"
            bgColor="#FFFFFF"
            onClick={() => OnDrawerOpen('sort')}
          >
            <Text>SORT</Text>
          </Flex>

          <Flex
            fontSize="16px"
            className="font_NunitoUp"
            fontWeight="bold"
            alignItems="center"
            justifyContent="center"
            width="50%"
            p="10px"
            boxShadow="xs"
            bgColor="#FFFFFF"
            onClick={() => OnDrawerOpen('filters')}
          >
            {' '}
            {filteredoptions.length == 0 ? (
              <Text>FILTERS</Text>
            ) : (
              <Text>FILTERS ({filteredoptions.length})</Text>
            )}
          </Flex>
        </Flex>
        {/* <Box display={filteredoptions.length == 0 ? "none" : "flex"} padding={"0 1rem"} justifyContent='space-between'>
                    <Flex p='2px' flexWrap='nowrap' overflow={'scroll'} className="noscrollbar" >

                        {filteredoptions}

                    </Flex>
                    // {filteredoptions.length != 0 &&
                        <Box padding={'1px'} borderRadius='2xl' onClick={() => { onClear("all")}}>
                            <Flex p='5px 12px' boxShadow='xs' borderRadius='2xl' fontSize='12px' bgColor='#F5F5F5' >
                                <Text className='font_NunitoA' whiteSpace={'nowrap'} >Clear</Text>
                            </Flex>
                        </Box>} //
                </Box> */}

        <div className="flex ml-4 hidefilteredoptions flex-nowrap">
          {filteredoptions.map((elem) => (
            <div
              key={elem}
              style={{ border: '1px solid #F9E107' }}
              className="filteredoptions"
              onClick={() => OnDrawerOpen('filters')}
            >
              <p>{elem}</p>
            </div>
          ))}
        </div>

        <Drawer
          isOpen={isOpen}
          placement="bottom"
          onClose={() => {
            onClose(), Sort(sortedOption);
          }}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay style={{ backdropFilter: 'blur(2px)' }} />
          {drawerType == 'sort' ? (
            <DrawerContent bg="#F5F5F5">
              <DrawerHeader p="1rem 2rem 5px 2rem">
                <Text className="font_Manrope" fontWeight="bold">
                  SORT BY
                </Text>
                <DrawerCloseButton _focus={{ border: 'none' }} />
              </DrawerHeader>
              <DrawerBody pt={0}>
                <Flex flexDirection={'column'}>{mobsortoptions}</Flex>
              </DrawerBody>
              <DrawerFooter justifyContent="center" p={0}>
                <Button
                  onClick={() => {
                    Sort(sortedOption);
                  }}
                  className="font_Manrope"
                  width="100%"
                  p="10px"
                  fontWeight="600"
                  bgColor="#F9E107"
                  borderTopRadius="5px"
                  borderBottomRadius={0}
                  variant="outline"
                >
                  APPLY
                </Button>
              </DrawerFooter>
            </DrawerContent>
          ) : drawerType == 'filters' ? (
            <DrawerContent>
              <Flex flexDirection={'column'}>
                <Flex
                  bg="#F5F5F5"
                  justifyContent="space-between"
                  alignItems="center"
                  p="10px"
                  height="50px"
                >
                  <Text className="font_NunitoA" fontWeight="bold" pl="28px">
                    CATEGORY
                  </Text>
                  <Button
                    className="font_NunitoA"
                    bgColor="#ECECEC"
                    color="#27292E"
                    fontSize="12px"
                    borderRadius="4px"
                    h="25px"
                    width="70px"
                    fontWeight="normal"
                    variant="outline"
                    onClick={() => {
                      onSubmitCategoryBrand('clear');
                    }}
                  >
                    Clear all
                  </Button>
                </Flex>

                <Flex p="10px 5px" flexDir={'column'}>
                  <Flex
                    onClick={() => {
                      onClickCategoryBrand('Brake Pad', 'category');
                    }}
                    bgColor={
                      categoryArray.includes('Brake Pad') ? '#ECE9D2' : 'none'
                    }
                    borderRadius="md"
                    _focus={{ bgColor: 'none', boxShadow: 'none' }}
                    fontWeight={categoryArray.includes('Brake Pad') ? 600 : 500}
                    justifyContent="space-between"
                    alignItems="center"
                    p="10px"
                    mb="3px"
                    pr={6}
                  >
                    <Text fontSize="14px" pl={6}>
                      BRAKE PADS
                    </Text>
                    <FaCheck
                      visibility={
                        categoryArray.includes('Brake Pad')
                          ? 'visible'
                          : 'hidden'
                      }
                      fontWeight={600}
                      color="#1ABC35"
                    />
                  </Flex>
                  <Flex
                    onClick={() => {
                      onClickCategoryBrand('Brake Rotor', 'category');
                    }}
                    bgColor={
                      categoryArray.includes('Brake Rotor') ? '#ECE9D2' : 'none'
                    }
                    borderRadius="md"
                    _focus={{ bgColor: 'none', boxShadow: 'none' }}
                    fontWeight={
                      categoryArray.includes('Brake Rotor') ? 600 : 500
                    }
                    justifyContent="space-between"
                    alignItems="center"
                    p="10px"
                    mb="3px"
                    pr={6}
                  >
                    <Text fontSize="14px" pl={6}>
                      BRAKE ROTORS
                    </Text>
                    <FaCheck
                      visibility={
                        categoryArray.includes('Brake Rotor')
                          ? 'visible'
                          : 'hidden'
                      }
                      fontWeight={600}
                      color="#1ABC35"
                    />
                  </Flex>
                  <Flex
                    onClick={() => {
                      onClickCategoryBrand('Brake Fluid', 'category');
                    }}
                    bgColor={
                      categoryArray.includes('Brake Fluid') ? '#ECE9D2' : 'none'
                    }
                    borderRadius="md"
                    _focus={{ bgColor: 'none', boxShadow: 'none' }}
                    fontWeight={
                      categoryArray.includes('Brake Fluid') ? 600 : 500
                    }
                    justifyContent="space-between"
                    alignItems="center"
                    p="10px"
                    mb="3px"
                    pr={6}
                  >
                    <Text fontSize="14px" pl={6}>
                      BRAKE FLUIDS
                    </Text>
                    <FaCheck
                      visibility={
                        categoryArray.includes('Brake Fluid')
                          ? 'visible'
                          : 'hidden'
                      }
                      fontWeight={600}
                      color="#1ABC35"
                    />
                  </Flex>
                  <Flex
                    onClick={() => {
                      onClickCategoryBrand('Other', 'category');
                    }}
                    bgColor={
                      categoryArray.includes('Other') ? '#ECE9D2' : 'none'
                    }
                    borderRadius="md"
                    _focus={{ bgColor: 'none', boxShadow: 'none' }}
                    fontWeight={categoryArray.includes('Other') ? 600 : 500}
                    justifyContent="space-between"
                    alignItems="center"
                    p="10px"
                    mb="3px"
                    pr={6}
                  >
                    <Text fontSize="14px" pl={6}>
                      OTHERS
                    </Text>
                    <FaCheck
                      visibility={
                        categoryArray.includes('Other') ? 'visible' : 'hidden'
                      }
                      fontWeight={600}
                      color="#1ABC35"
                    />
                  </Flex>
                </Flex>

                <Flex
                  bg="#F5F5F5"
                  justifyContent="space-between"
                  alignItems="center"
                  p="10px"
                  height="50px"
                  pr={7}
                >
                  <Text className="font_NunitoA" fontWeight="bold" pl="28px">
                    BRANDS
                  </Text>
                  <SearchIcon
                    onClick={() => {}}
                    fontSize="1.3rem"
                    color="#9E9D9D"
                  />
                </Flex>
                <Box p={1} height={'30vh'} overflowY="scroll">
                  {brandList}
                </Box>
              </Flex>
              <DrawerFooter justifyContent="center" p={0}>
                <Button
                  onClick={() => {
                    onSubmitCategoryBrand(), onClose();
                  }}
                  className="font_Manrope"
                  width="100%"
                  p="10px"
                  fontWeight="600"
                  bgColor="#F9E107"
                  borderTopRadius="5px"
                  borderBottomRadius={0}
                  variant="outline"
                >
                  APPLY
                </Button>
              </DrawerFooter>
            </DrawerContent>
          ) : (
            <DrawerContent
              // background={'#f9e107b3'}
              bg="#F5F5F5"
            >
              <Flex
                p="1rem 2rem 5px 2rem"
                position={'relative'}
                justifyContent="center"
              >
                <Text
                  className="font_Manrope"
                  fontWeight="bold"
                  textAlign={'center'}
                >
                  SELECT VEHICLE
                </Text>
                {filteredoptions?.make || filteredoptions?.model || (
                  <Box
                    padding={'1px'}
                    borderRadius="md"
                    onClick={() => {
                      onClear('all');
                    }}
                    position="absolute"
                    right={2}
                  >
                    <Flex
                      p="5px 12px"
                      boxShadow="xs"
                      borderRadius="md"
                      fontSize="12px"
                      bgColor="#e7e7e780"
                    >
                      <Text className="font_NunitoA" whiteSpace={'nowrap'}>
                        Clear
                      </Text>
                    </Flex>
                  </Box>
                )}
              </Flex>
              <DrawerBody pt={6} pb={14}>
                <Flex flexDirection={'column'}>
                  <Box className="FPSelectMob">
                    <CustomSelectFilterMobile3
                      id="CustomSelectMake"
                      // inputStyle={inputStyle}
                      name={'make'}
                      options={make}
                      value={filter?.make ? filter?.make[0] : ''}
                      onChange={onChange}
                      placeholder="Make"
                      boxheight={'50vh'}
                    />
                  </Box>
                  <Box className="FPSelectMob">
                    <CustomSelectFilterMobile3
                      id="CustomSelectModel"
                      name={'model'}
                      options={model}
                      value={filter?.model ? filter?.model[0] : ''}
                      onChange={onChange}
                      placeholder="Model"
                      boxheight={'40vh'}
                    />
                  </Box>
                  <Box className="FPSelectMob">
                    <CustomSelectFilterMobile3
                      id="CustomSelectYear"
                      name={'year'}
                      options={year}
                      value={filter?.year ? filter?.year[0] : ''}
                      onChange={onChange}
                      placeholder="Year"
                      boxheight={'33vh'}
                    />
                  </Box>

                  {version && version.length != 0 && (
                    <Box className="FPSelectMob">
                      <CustomSelectFilterMobile3
                        name={'version'}
                        options={version}
                        value={filter?.version ? filter?.version[0] : ''}
                        onChange={onChange}
                        placeholder="Version"
                      />
                    </Box>
                  )}
                  <Box className="FPSelectMob">
                    <CustomSelectFilterMobile3
                      name={'fuel'}
                      options={fuel}
                      value={filter?.fuel ? filter?.fuel[0] : ''}
                      onChange={onChange}
                      placeholder="Fuel Type"
                    />
                  </Box>
                  <Box className="FPSelectMob">
                    <CustomSelectFilterMobile3
                      name={'type'}
                      options={type}
                      value={filter?.type ? filter?.type[0] : ''}
                      onChange={onChange}
                      placeholder="Front/rear"
                    />
                  </Box>

                  {/* <Button isLoading={SearchButtonLOader}
                                            alignSelf="center"
                                            onClick={() => onClickFilterProceedButton()}
                                            mt='3vh'
                                            className='font_Manrope' width='70%' p="10px" fontWeight='700' borderRadius='5px'
                                            bg="#424CDB"
                                            color='white'
                                        >
                                            {isSelected3 ? "VIEW PRODUCTS" : isSelected2 ? "SELECT YEAR" : isSelected1 ? "SELECT MODEL" : "SELECT MAKE"}
                                        </Button> */}
                </Flex>
              </DrawerBody>
              <DrawerFooter justifyContent="center" p={0} height="64px">
                <Button
                  isLoading={SearchButtonLOader}
                  // onClick={() => { onClose() }}
                  height="64px"
                  onClick={() => onClickFilterProceedButton()}
                  // onClick={handleSubmit((d) => props.onSearch(d))}
                  className="font_Manrope"
                  width="100%"
                  p="10px"
                  fontWeight="700"
                  borderTopRadius="5px"
                  borderBottomRadius={0}
                  // bgColor={isSelected3 ? "#F9E107" : isSelected2 ? "#e9d940" : isSelected1 ? "#e0d88b" : "#C9C9C9"}
                  bgColor={
                    isSelected3
                      ? '#F9E107'
                      : isSelected2
                      ? '#c6c3c3'
                      : isSelected1
                      ? '#dcdcdc'
                      : '#e8e6e6'
                  }
                >
                  {isSelected3
                    ? 'VIEW PRODUCTS'
                    : isSelected2
                    ? 'SELECT YEAR'
                    : isSelected1
                    ? 'SELECT MODEL'
                    : 'SELECT MAKE'}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          )}
        </Drawer>
      </Box>

      {/* <SortFilter
                    filter={filter} onCloseFilter={onCloseFilter}
                    onClear={onClear} onChange={onChange}
                    filters={{ make, model, year, version, fuel, type }}
                    make={make} model={model}
                    year={year} version={version}
                    fuel={fuel} type={type}
                    brandDts={brand}
                    drawerType={drawerType}
                    OnDrawerOpen={OnDrawerOpen}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    onChangeBrand={onChangeBrand}
                    onSubmitBrand={onSubmitBrand}
                    spinner={spinner}
                    setspinner={setspinner}
                    hideVersion={hideVersion}
                /> */}

      <Box className="filter_itemDisplay">
        {isloading && (
          <Flex height={'70vh'}>
            <Flex width="100%" height="300px" zIndex={5}>
              <Box height="35%" margin=" auto ">
                <Image maxHeight="150px" src={Loader} />
              </Box>
            </Flex>
          </Flex>
        )}
        {products.length != 0 ? (
          <Box>
            <p className="nxt_subheaders mt-7">{sortTitle}</p>
            <div
              style={{ height: '2px' }}
              className="mb-3 border-y bg-yellowB border-yellowB"
            ></div>

            <ItemDisplayMob3
              justifyContent="center"
              products={products}
              onBoxClick={onBoxClick}
              isSelected3={isSelected3}
              isSelected1={isSelected1}
              isSelected2={isSelected2}
              OnDrawerOpen={OnDrawerOpen}
              filter={filter}
            />

            {showShowMore && (
              <Flex justifyContent="center" textAlign="center">
                <Box
                  className="font_Nunito"
                  fontWeight="600"
                  // w='8rem' p={3}
                  p={'0.25rem 0.5rem'}
                  fontSize="1rem"
                  // borderRadius='.1rem'
                  bgColor="white"
                  mt="6vh"
                  mb="10vh"
                  borderRadius="4px"
                  textAlign="center"
                  boxShadow="0px 3px 10px #00000040"
                  _active={{
                    bgColor: '#FFFFFF',
                    boxShadow: '0px 3px 10px #00000040',
                  }}
                  onClick={() => onNext()}
                >
                  <Text> Show More </Text>
                </Box>
              </Flex>
            )}
          </Box>
        ) : (
          !isloading && (
            <Flex
              className="item_grid"
              gridArea="item_grid"
              p="2rem 10vw 4rem"
              bg=""
              alignItems="center"
              flexDirection="column"
              justifyItems="center"
            >
              <Box
                width="30vw"
                height="500px"
                className="emptylottiebox"
                maxW="500px"
              >
                <lottie-player
                  src={
                    'https://api.dev.brakeup.in/api/v2/json/Filter_no_item.json'
                  }
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </Box>
              <Box
                pb="40px"
                className="font_Manrope"
                fontWeight="600"
                marginTop="-5vw"
              >
                <Text className="sorry" fontSize="30px" textAlign="center">
                  Products may be sold out!
                </Text>
                <Text className="sorry2" fontSize="16px" textAlign="center">
                  Please make a different search
                </Text>
              </Box>
            </Flex>
          )
        )}
      </Box>

      {/* <Flex mb='3vw' margin={reViewed?.length == 0 && '4vw'} className='recent' gridArea='recent_items' justifyContent='center'>

                <RecentView reViewed={reViewed} onRectClick={onRectClick} />

            </Flex > */}

      {/* <Box ref={showbacktotopref} m='4vw' onClick={() => { onClickBrandAd("Brembo") }} >
                <Image width='100%' borderRadius='16px' objectFit='contain' src={bottomAd1} />
            </Box> */}

      {/* <AdsWeb AD3={true} adStyle={{ margin: "0 4vw" }} adStyleImg={{ borderRadius: '5px', boxShadow: "0px 1px 1px #00000026" }} /> */}

      {/* <Box m='4vw' mb={"8vw"} onClick={() => { onClickBrandAd("ATE") }}>
                <Image src={bottomAd2} borderRadius='16px' width="100%" objectFit='contain' />
            </Box> */}

      <div style={{ margin: '5vw' }} className="mx-4 mb-8">
        <Image
          className="rounded-brBs drop-shadow-dsCard2"
          src={bottomAd1}
          loading="lazy"
          alt="brakeup"
        />
      </div>

      <div ref={showbacktotopref} className="w-full px-4">
        <ProductCardScroll title={'RECENTLY VIEWED'} reViewed={reViewed} />
      </div>

      {onScreen && showBacktoTop && (
        <Box
          className="font_NunitoA"
          fontWeight="600"
          p={2}
          style={scrolling ? { opacity: '0.1' } : {}}
          _disabled={scrolling ? true : false}
          // borderRadius='.1rem'
          bgColor="white"
          position="fixed"
          bottom="19%"
          right="3"
          borderRadius="full"
          boxShadow="0px 3px 10px #00000040"
          zIndex={10}
          // _active={{ bgColor: '#FFFFFF', boxShadow: '0px 3px 10px #00000040' }}
          onClick={ScrolltoTop}
        >
          <Flex
            flexDir="column"
            fontSize="0.9rem"
            width="2rem"
            alignItems="center"
            textAlign="center"
          >
            <HiChevronDoubleUp fontSize="1.3rem" />
            <Text
              overflowWrap="break-word"
              whiteSpace="pre-wrap"
              lineHeight="2rem"
            >
              Top
              {/* Back to top  */}
              {/* <span> Back </span>
                            <span> to </span>
                            <span> top </span> */}
            </Text>
          </Flex>
        </Box>
      )}

      <Flex
        width="100%"
        height="64px"
        position="fixed"
        bottom="0"
        zIndex="5"
        className="font_NunitoUp"
        fontWeight="bold"
        alignItems="center"
        justifyContent="center"
        p="3.5vh"
        bgColor="#F9E107"
        borderTopRadius="5px"
        ref={btnRef}
        onClick={() => OnDrawerOpen('selectcar')}
      >
        <Text>
          {isSelected3
            ? `${filter.make} ${filter.model} ${filter.year}`
            : isSelected2
            ? 'SELECT YEAR'
            : isSelected1
            ? 'SELECT MODEL'
            : 'SELECT VEHICLE'}
        </Text>
      </Flex>
    </Flex>
  );
};
