import { Box, Button, Divider, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import React from 'react';



export const FilterIconsx9Mob = (props) => {

    // console.log(props, "FilterIconsx9Mob");


    let items = []
    props?.data && props?.data.forEach((elem) => {

        items.push(

            <GridItem padding='10px 0 10px 0' boxShadow={"0px 1px 1px rgba(0, 0, 0, 0.15)"} borderRadius='15px'
            background={"linear-gradient(270deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%)"} backdropFilter='blur(1px)'
            onClick={() => props.onClickFilter(elem.searchkey)}>
                <Image className="" transition={"all 0.2s ease"} src={elem.image} />
                <Text className='font_Manrope700' textAlign='center'>{elem.title}</Text>
            </GridItem>

        )
    })



    return (
        <Box mt="7vw" bgImage={props.backgroundFilterImage} bgPosition='center' bgSize='contain' bgRepeat='no-repeat'>
            <Text marginLeft='15px' textAlign='left' className="hpsubheaders" mb="1.5vw">{props.heading}</Text>
            <Divider color="#FBDE00" border='2px solid' mb="3vw" />

            <Grid templateColumns='repeat(3, 1fr)' gap={3} bgImage={props.backgroundFilterImage2} bgPosition='center' bgSize='contain' bgRepeat='no-repeat'>

                {items}

            </Grid>


            {/* <Image className="hpscroll-group-image" transition={"all 0.2s ease"} src={Supergrip} onClick={() => props.onClickCategory({ value: "Supergrip", name: "brand", label: "Supergrip" })} /> */}
            {/* <Image className="hpscroll-group-image" transition={"all 0.2s ease"} src={Motherson} onClick={() => props.onClickCategory({ value: "Motherson", name: "brand", label: "Motherson" })} /> */}
            {/* <Image className="hpscroll-group-image" transition={"all 0.2s ease"} src={Hella} onClick={() => props.onClickCategory({ value: "Hella", name: "brand", label: "Hella" })} /> */}
            {/* <Image className="hpscroll-group-image" transition={"all 0.2s ease"} src={AlliedNippon} onClick={() => props.onClickCategory({ value: "Allied Nippon", name: "brand", label: "Allied Nippon" })} /> */}

            <Flex width="100%" justifyContent='center' mt="3vw">
                <Button
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    className="hpsearchotherbtn"
                    width="80%"
                    boxShadow="0px 1px 1px #00000026"
                    // opacity='0.8'
                    borderRadius='6px'
                    bgColor='#FBDE00BA' >
                    {props.SearchButtonTitle}
                </Button>
            </Flex>
        </Box>
    )
}

