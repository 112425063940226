import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    filter: {},
}


export const categoryFilterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setCategoryFilter: (state, action) => {
            state.filter = action.payload
        },
    },
})


// Action creators are generated for each case reducer function
export const { setCategoryFilter } = categoryFilterSlice.actions

export default categoryFilterSlice.reducer