import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { API_URL, post } from '../../services/api';
import { Box, Flex } from '@chakra-ui/layout';

function PaymentPending() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const orderId = params.get('id');

  const history = useHistory();

  useEffect(() => {
    if (orderId) {
      post('/api/v2/payment/phonepe_paymentverification/', {
        order_id: orderId,
      })
        .then((res) => {
          if (res.status == 200) {
            history.replace(`/order-success?id=${orderId}`);
          } else {
            history.replace(`/order-failed?id=${orderId}`);
          }
        })
        .catch((err) => {
          history.replace(`/order-failed?id=${orderId}`);
        });
    }
  }, []);
  return (
    <div className="h-[100vh]">
      <Flex
        width="100%"
        height="100%"
        backdropFilter="brightness(70%) blur(2px)"
        inset="auto"
        top={0}
        position="absolute"
        zIndex={5}
      >
        <Box height="7%" maxHeight="300px" margin=" 56vh auto 0">
          <lottie-player
            // src={API_URL + "/api/v2/json/Brakeup_Loader.json"}
            src={
              'https://api.dev.brakeup.in/api/v2/json/Brakeup_Loader_Yellow.json'
            }
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </Box>
      </Flex>
    </div>
  );
}

export default PaymentPending;
