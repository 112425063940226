import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    Addresses: [],
    SelectedAddress: {}

}

export const showAddressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setAddressess: (state, action) => {
            state.Addresses = action.payload
        },
        setSelectedAddress: (state, action) => {
            state.SelectedAddress = action.payload
        }
    },

})

// Action creators are generated for each case reducer function
export const { setAddressess,setSelectedAddress } = showAddressSlice.actions

export default showAddressSlice.reducer