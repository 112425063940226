import { Box } from '@chakra-ui/react';
import React from 'react'
import { useState, useEffect } from 'react';

const Timer = (props) => {
    const [counter, setCounter] = useState(0)
    useEffect(() => {
        if (counter > 0) {
            const timer = setInterval(() => setCounter(counter - 1), 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [counter]);
    useEffect(() => {
        if (counter == 0) {
            props.enableBtn()
        }
    }, [counter])
    useEffect(() => {
        if (props.trigger == true) {
            setCounter(30);
        }
    }, [props.trigger])

    return (
        <Box display={counter == 0 ? 'none' : 'block'}>
            <h1>in {counter} sec</h1>
        </Box>
    )
}

export default Timer;