import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Input, {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import {
  Box,
  Button,
  color,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
} from '@chakra-ui/react';
import { FormErrorMessage, FormLabel, FormControl } from '@chakra-ui/react';
import { API_URL, get, post } from '../../../services/api';

export const AddressForm = (props) => {
  const [values, setValues] = useState();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({ mode: 'onChange', defaultValues: { address_type: 'other' } });
  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(props.editItems).length > 0) {
        setValue('address', props.editItems?.address);
        setValue('address2', props.editItems?.address2);
        setValue('address_type', props.editItems?.address_type);
        setValue('district', props.editItems?.district);
        setValue('email', props.editItems?.email);
        setValue('landmark', props.editItems?.landmark);
        setValue('mobile', props.editItems?.mobile);
        setValue('name', props.editItems?.name);
        setValue('pincode', props.editItems?.pincode);
        setValue('state', props.editItems?.state);
        // console.log(props.editItems, 'HEREEEEEEEEEEEEEEEEee', props)
      }
    }, 1000);
  }, [props.editItems]);

  return (
    <Modal
      onClose={props.onClose}
      isOpen={props.isOpen}
      size="xl"
      closeOnOverlayClick={false}
      motionPreset="slideInRight"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        className="addressbox_1"
        bgColor={'var(--white-rock-2)'}
        padding="2vw"
      >
        <ModalCloseButton size="lg" zIndex={2} />
        <form>
          <ModalBody marginTop="4rem">
            <VStack>
              <Flex alignSelf="flex-start">
                <Flex flexDirection="column" width="75%">
                  <Text className="add_edit_adress">ADD NEW ADDRESS</Text>

                  <Flex alignItems="baseline">
                    <input
                      marginRight="1vw"
                      className="inputbox"
                      type="number"
                      variant="outline"
                      placeholder="Pincode"
                      style={{ marginRight: '20px' }}
                      name="pincode"
                      ref={register({
                        required: true,
                        minLength: { value: 6 },
                      })}
                    />
                    <Flex boxShadow="0px 1px 4px #00000033" alignItems="center">
                      <Flex
                        className="input91"
                        bgColor="white"
                        pr="3px"
                        borderRadius="2px"
                        paddingLeft="3px"
                        height={'2rem'}
                        alignItems="center"
                      >
                        <Text>+{getCountryCallingCode('IN')}</Text>
                      </Flex>

                      <input
                        style={{
                          paddingLeft: '5px',
                          marginTop: '0',
                          boxShadow: 'none',
                        }}
                        marginRight="1vw"
                        className="inputbox2"
                        type="number"
                        variant="outline"
                        placeholder="Mobile number"
                        name="mobile"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Flex>
                  </Flex>
                </Flex>

                <div className="lottie_address_modal">
                  <lottie-player
                    src={
                      'https://api.dev.brakeup.in/api/v2/json/Address_File.json'
                    }
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                    style={{ mixBlendMode: 'multiply' }}
                  ></lottie-player>
                </div>
              </Flex>
              <VStack width="100%">
                <input
                  className="inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Enter Full Name"
                  name="name"
                  ref={register({ required: true })}
                ></input>
                <input
                  className="inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Address Line 1"
                  name="address"
                  ref={register({ required: true })}
                ></input>
                <input
                  className="inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Address Line 2"
                  name="address2"
                  ref={register({ required: true })}
                ></input>
                <input
                  className="inputbox"
                  variant="outline"
                  type="text"
                  placeholder="Landmark"
                  name="landmark"
                  ref={register({ required: true })}
                ></input>
              </VStack>

              <Flex alignItems="flex-end">
                <input
                  style={{ marginRight: '1vw' }}
                  className="inputbox"
                  variant="outline"
                  placeholder="City"
                  name="district"
                  ref={register({ required: true })}
                ></input>
                <input
                  style={{ marginRight: '1vw' }}
                  className="inputbox"
                  variant="outline"
                  placeholder="State"
                  name="state"
                  ref={register({ required: true })}
                ></input>
                <FormControl>
                  <Controller
                    as={
                      <RadioGroup ref={register()} name="address_type">
                        <Stack direction="row">
                          <Radio
                            size="lg"
                            borderColor="#f9e107"
                            _before={{ border: '5px', borderColor: '#f9e107' }}
                            _focus={{ border: '5px', borderColor: '#f9e107' }}
                            value="home"
                          >
                            <Box
                              height="auto"
                              width="50px"
                              textAlign="center"
                              className="font_Nunito radiobox"
                              borderRadius="8px"
                              bgColor={values == 'home' ? '#f9e107' : '#acacac'}
                              textColor={values == 'home' ? 'black' : 'white'}
                            >
                              {' '}
                              HOME
                            </Box>
                          </Radio>
                          <Radio
                            size="lg"
                            borderColor="#f9e107"
                            _before={{ border: '5px', borderColor: '#f9e107' }}
                            _focus={{ border: '5px', borderColor: '#f9e107' }}
                            value="office"
                          >
                            <Box
                              height="auto"
                              width="50px"
                              textAlign="center"
                              className="font_Nunito radiobox"
                              borderRadius="8px"
                              bgColor={
                                values == 'office' ? '#f9e107' : '#acacac'
                              }
                              textColor={values == 'office' ? 'black' : 'white'}
                            >
                              {' '}
                              OFFICE
                            </Box>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    }
                    name="address_type"
                    control={control}
                  />
                </FormControl>
              </Flex>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              marginRight={'20px'}
              className="cancel "
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="save_proceed"
              onClick={handleSubmit((d) =>
                props.onSubmit(d, props?.editItems?._id?.$oid)
              )}
            >
              Save &amp; Proceed
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
