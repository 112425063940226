import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import AddNewAddressModal from '../../components/Modals/AddNewAddressModal';
import AddressData from './AddressData';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IncludeComaSeperator } from '../ProductDetailpage/pd';
import { get, post } from '../../services/api';
import { setSelectedAddress } from '../../stateManagement/user/showAddressSlice';
import { Toast2 } from '../cart';
import {
  setpaymentPriceDetails,
  setcartItemss,
} from '../../stateManagement/payment/paymentDetails';
import loadFacebookPixels from '../../services/loadFacebookPixel';

const Address = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedAddress = useSelector((state) => state.address.SelectedAddress);
  const priceDts = useSelector((state) => state.payment.paymentPriceDetails);

  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [addresses, setAddresses] = useState([]);

  //for edit
  const [editAddress, setEditAddress] = useState({});

  let { id } = useParams();

  useEffect(() => {
    let isMounted = true;

    if (id) {
      getAddress();
      getPrice(id)
        .then(() => {
          get('/api/v2/products/product_buynow/')
            .then((res) => {
              dispatch(setcartItemss(res?.data));
              window.scrollTo(0, 0);
              setTimeout(() => {
                history.replace('/address');
                loadFacebookPixels();
              }, 1000);
            })
            .catch((err) => {
              dispatch(setcartItemss([]));
              history.goBack();
            });
        })
        .catch((err) => {
          // Handle error if getPrice or getAddress fails
        });
    } else if (Object.keys(priceDts).length > 1) {
      window.scrollTo(0, 0);
      getAddress();
      loadFacebookPixels();
    } else {
      isMounted && history.push('/cart');
    }

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [id]);

  const getPrice = async (id) => {
    get(`/api/v2/products/cart/price_details/?cart_id=${id}`)
      .then((res) => {
        // console.log(res?.data, "getpriceee");
        let price = res?.data[0];
        price.cart_id = id;
        // price.is_cod_disabled = true;

        let float_price =
          -price.delivery_discount + price.promo_discount + price.discount;
        price.total_discount = float_price.toFixed(0);
        let calc_total =
          price.subtotal -
          price.promo_discount +
          (price.delivery_discount == 0 ? price.delivery_charge : 0);
        price.total = calc_total.toFixed(0);
        price.delivery_charge =
          price.delivery_charge && price.delivery_charge.toFixed(0);
        price.delivery_discount =
          price.delivery_discount && price.delivery_discount.toFixed(0);

        price.brakeup_part_nos = res?.brakeup_part_nos;

        dispatch(setpaymentPriceDetails(price));
      })
      .catch((err) => {
        dispatch(setpaymentPriceDetails({}));
        history.goBack();
      });
  };

  const getAddress = () => {
    get('/api/v2/products/address/')
      .then((res) => {
        setAddresses(res.data);
        if (res?.data.length == 1) {
          // console.log(res.data[0], "setAddressessres.data[0]res.data[0]");
          dispatch(setSelectedAddress(res.data[0]));
        } else {
          res?.data.map((items) => {
            if (items.isMain) {
              dispatch(setSelectedAddress(items));
            }
          });
        }
      })
      .catch((err) => {
        setAddresses([]);
      });
  };

  const proceedToPayment = (type) => {
    const tokenpin = JSON.parse(localStorage.getItem('pincode') || '{}');

    if (selectedAddress && Object.keys(selectedAddress).length > 0) {
      if (selectedAddress?.pincode == tokenpin) {
        //go to next page
        history.push(`/order-summary`);
      } else {
        let addressid = {
          address_id: selectedAddress['_id']['$oid'],
        };
        post(`/api/v2/products/updateaddress/`, addressid)
          .then((res) => {
            localStorage.setItem(
              'pincode',
              JSON.stringify(selectedAddress.pincode)
            );
            //go to next page
            history.push(`/order-summary`);
          })
          .catch((err) => {});
      }
    } else {
      Toast2('Please Add Address !', 'info', '#414bdb', '#414bdb');
    }
  };

  //add addresss

  const EditAddress = (data) => {
    setEditAddress(data);
    setIsOpenAddress(true);
    // console.log(data, "EditAddress");
  };

  return (
    <>
      <div className=" max-w-full py-7  bg-white ">
        <div className=" md:px-16 lg:px-20  px-4 mx-auto max-w-screen-2xl">
          <p className="nxt_subheaders  hidden md:block ">SAVED ADDRESSES</p>
          <div className="ccr_divider hidden md:block "></div>
          <div className="py-4   md:flex md:w-full  justify-between ">
            <div className="">
              <div className="flex items-center justify-center md:items-start md:justify-start">
                <button
                  className="flex  items-center justify-center rounded-brBs border border-yellowB bg-none px-6 py-2 add_font12 uppercase   
                      tracking-wide text-blueB3  shadow-bsCard3 font-bold
                      focus:outline-none hover:opacity-50 "
                  type="submit"
                  onClick={() => setIsOpenAddress(true)}
                >
                  ADD NEW ADDRESS
                </button>
              </div>
              {addresses && addresses.length != 0 && (
                <>
                  <p className="nxt_subheaders block md:hidden mt-7">
                    SAVED ADDRESSES
                  </p>
                  <div className="ccr_divider block md:hidden"></div>

                  <div
                    className="  flex flex-col 
              md:min-w-[330px] md:w-[46vw] md:max-w-[512px]
              lg:min-w-[502px] lg:w-[49vw] lg:max-w-[718px]
               xl:min-w-[678px] xl:w-[53vw] xl:max-w-[918px]
              2xl:w-[833px]   
               md:mt-11"
                  >
                    <div>
                      <AddressData
                        title={'SAVED ADDRESSES'}
                        addresses={addresses}
                        center={false}
                        isSelectable={true}
                        getAddress={getAddress}
                        EditAddress={EditAddress}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="   md:sticky md:top-0 md:h-max">
              <div
                className="  md:mt-20 
              md:w-[34vw] md:max-w-[329px]
              lg:w-[31vw] lg:max-w-[400px]
               xl:w-[29vw] xl:max-w-[442px] 
               2xl:w-[451px] "
              >
                <p className=" nxt_subheaders mt-7 block md:hidden md:text-xl">
                  PRICE DETAILS
                </p>
                <div className="  ccr_divider block md:hidden"></div>
                <div className="cne_border mb-6  p-4 add_font18">
                  <div className="grid gap-y-3.5">
                    <div className="flex justify-between">
                      <p>Total Items</p> <p>{priceDts?.total_items}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Total Price</p>{' '}
                      <p>{IncludeComaSeperator(priceDts?.mrp_total)}</p>
                    </div>

                    <div className="flex justify-between">
                      <p>Total Discount (-)</p>{' '}
                      <p>{IncludeComaSeperator(priceDts?.total_discount)}</p>
                    </div>

                    <div className="flex justify-between add_font20 font-bold">
                      <p>Total Payable</p>{' '}
                      <p className="font-bold">
                        {IncludeComaSeperator(priceDts?.total)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 md:block">
                  <button
                    className="hidden w-full add_font20 large_button"
                    id="BeginCheckout"
                    onClick={() => proceedToPayment()}
                  >
                    <p className="  md:font-bold"> PROCEED</p>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="add_font20 fixed_button md:hidden"
        onClick={() => proceedToPayment()}
      >
        <p className="font-bold"> PROCEED</p>
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          ></path>
        </svg>
      </button>
      <AddNewAddressModal
        isOpen={isOpenAddress}
        setIsOpen={setIsOpenAddress}
        message={'Add New Address'}
        editAddress={editAddress}
        setEditAddress={setEditAddress}
        getAddress={getAddress}
      />
    </>
  );
};
export default Address;
