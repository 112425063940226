import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

import {
  Box,
  Button,
  color,
  Image,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useToast,
  Heading,
} from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
} from '@chakra-ui/react';
import { FormErrorMessage, FormLabel, FormControl } from '@chakra-ui/react';
import { API_URL, get, post } from '../../../services/api';

import { FiEdit } from 'react-icons/fi';
import { CgClose } from 'react-icons/cg';
import { AddressFormMobile } from './AddressFormMobile';

//toast
import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

export const AddressBookMob = (props) => {
  //toast
  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [address, setAddress] = useState([]);

  //Alert Dialog PopUp
  const {
    isOpen: isOpenDeleteAddress,
    onOpen: onOpenDeleteAddress,
    onClose: onCloseDeleteAddress,
  } = useDisclosure();

  const [deleteAdd, setdeleteAdd] = useState({});

  const openAlertBox = (id, user_id) => {
    setdeleteAdd({ id: id, user_id: user_id });
    onOpenDeleteAddress();
  };

  const submitEdit = () => {
    onCloseDeleteAddress();
    setEditItems(deleteAdd);
    onOpen();
  };

  //jsonImage

  const { priceDts } = props;

  //form
  const getAddress = () => {
    get('/api/v2/products/address/')
      .then((res) => {
        setAddress(res.data);
      })
      .catch((err) => {
        setAddress([]);
      });
  };
  const deleteAddress = () => {
    // console.log(deleteAdd, "deleteAdd");
    post('/api/v2/products/deleteaddress/', {
      address_id: deleteAdd.id,
      user_id: deleteAdd.user_id,
    })
      .then((res) => {
        getAddress();
        Toast2('Address Removed !', 'error', '#f22020');
        //onCloseDeleteAddress();

        //setdeleteAdd({})
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAddress();
  }, []);

  const onSubmit = (data, value) => {
    // console.log(data, value, "onSubmittttttt");
    // data.address_type = value;
    let url = '/api/v2/products/address/';
    if (value) {
      data.address_id = value;
      url = '/api/v2/products/updateaddress/';
    }
    post(url, data)
      .then((res) => {
        getAddress();
        Toast2('Address added Successfully !', 'success', '#089520');

        onClose();
      })
      .catch((err) => {
        if (err?.response?.data?.status == 401) {
          Toast2('Address already exists !', 'error', '#f22020');
        }
      });
  };

  //radio buttons

  //pincode
  const [editItems, setEditItems] = useState({});

  return (
    <>
      <Flex
        bgColor="white"
        flexDirection="column"
        alignItems="center"
        minH="77vh"
        width={'100%'}
      >
        <Text className="mob_address_head_font" mt="30px">
          Address
        </Text>

        <Flex flexDirection="column" mt="30px" mb="30px" width="100%">
          {address.length == 0 ? (
            <Flex
              alignSelf="center"
              flexDirection="column"
              alignItems="center"
              className="flexbox_Empty_State"
            >
              <Box className="savederrorjson">
                <lottie-player
                  src={
                    'https://api.dev.brakeup.in/api/v2/json/Brakeup_Empty_State.json'
                  }
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </Box>

              <Flex
                className="textbox_Empty_State"
                flexDir="column"
                alignItems="center"
              >
                <Text
                  className="bold_text_Empty_State nunitosans-bold-shark "
                  whiteSpace="nowrap"
                  textAlign="center"
                >
                  {' '}
                  No shipping address
                </Text>
                <Text
                  className="text2_Empty_State nunitosans-normal-melanzane"
                  whiteSpace="nowrap"
                  textAlign="center"
                >
                  Please add your shipping address{' '}
                </Text>
                <button
                  className="btn_empty_state nunitosans-bold-shark"
                  onClick={() => {
                    onOpen();
                    setEditItems({});
                  }}
                >
                  ADD ADDRESS
                </button>
              </Flex>
            </Flex>
          ) : (
            <>
              {address &&
                address.map((items) => {
                  // lottie display data

                  const lottieData = () => {
                    if (items.address_type == 'home')
                      return {
                        url: 'https://api.dev.brakeup.in/api/v2/json/Home_Address.json',
                        tag: 'HOME',
                      };
                    else if (items.address_type == 'office')
                      return {
                        url: 'https://api.dev.brakeup.in/api/v2/json/Office_Address.json',
                        tag: 'OFFICE',
                      };
                    else
                      return {
                        url: 'https://api.dev.brakeup.in/api/v2/json/Location_Address.json',
                        tag: 'OTHER',
                      };
                  };

                  return (
                    <Flex
                      flexDirection="column"
                      m="5px 5px 0px 5px"
                      p="5px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex alignSelf="flex-end">
                        <FiEdit
                          size="14px"
                          style={{ color: '#ACACAC', cursor: 'pointer' }}
                          onClick={() => {
                            onOpen();
                            setEditItems(items);
                          }}
                        />
                        <CgClose
                          style={{
                            marginLeft: '15px',
                            color: '#ACACAC',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            openAlertBox(
                              items?._id['$oid'],
                              items?.user_id['$oid']
                            );
                            setEditItems(items);
                          }}
                        />
                      </Flex>
                      <Box className="mob_lottie_address">
                        <lottie-player
                          src={lottieData().url}
                          background="transparent"
                          speed="1"
                          loop
                          autoplay
                        ></lottie-player>
                      </Box>
                      <Text className="mob_lottie_nameM">
                        {lottieData().tag}
                      </Text>
                      <Flex
                        mt="10px"
                        flexDirection="column"
                        alignItems="center"
                        overflow="hidden"
                        width="100%"
                        className="mob_home-address"
                      >
                        <Flex
                          mb="4px"
                          className="mob_nunitosans-normal-melanzane"
                        >
                          <Text textTransform="capitalize">{items.name}</Text>
                          <Text marginLeft="20px">{items.mobile}</Text>
                        </Flex>

                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          wrap="wrap"
                          className="mob_nunitosans-normal-melanzane"
                        >
                          <Flex mb="4px" whiteSpace="nowrap" overflow="hidden">
                            <Text textTransform="capitalize">
                              {items.address},
                            </Text>
                            <Text marginLeft="5px" marginRight="5px"></Text>
                          </Flex>

                          <Flex mb="4px" whiteSpace="nowrap" overflow="hidden">
                            <Text textTransform="capitalize">
                              {items.address2},
                            </Text>
                            <Text marginLeft="5px"></Text>
                          </Flex>
                          <Flex mb="4px" whiteSpace="nowrap" overflow="hidden">
                            <Text textTransform="capitalize">
                              {items.landmark},
                            </Text>
                            <Text marginLeft="5px"></Text>
                          </Flex>
                        </Flex>

                        <Flex
                          mb="8px"
                          className="mob_nunitosans-normal-melanzane"
                        >
                          <Text textTransform="capitalize">
                            {' '}
                            {items.district}{' '}
                          </Text>
                          <Text textTransform="capitalize" marginLeft="5px">
                            {items.state}
                          </Text>
                          <Text marginLeft="5px"> {items.pincode}</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                })}
            </>
          )}
        </Flex>
        {address.length == 0 ? (
          ''
        ) : (
          <Flex
            width="100%"
            justifyContent="flex-end"
            margin="40px 0px 40px 0px"
          >
            <Button
              size={'xs'}
              onClick={() => {
                onOpen();
                setEditItems({});
              }}
              className="mob_add_addressM"
              marginRight="20px"
            >
              ADD ADDRESS
            </Button>
          </Flex>
        )}
      </Flex>

      {/* ADD ADDRESS MODAL */}

      <AddressFormMobile
        onSubmit={onSubmit}
        onClose={onClose}
        isOpen={isOpen}
        editItems={editItems}
      />

      {/* DELETE ADDRESS */}

      <AlertDialog
        onClose={() => {
          onCloseDeleteAddress(), setEditItems({});
        }}
        isOpen={isOpenDeleteAddress}
        isCentered
        size={'sm'}
        deleteAdd={deleteAdd}
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent borderRadius="2px">
          <AlertDialogCloseButton />

          <AlertDialogBody>
            <AlertDialogHeader
              className="mob_nunitosans-normal-shark-14px"
              fontSize="14px"
              pl={0}
              mt="20px"
            >
              Are you sure you want to remove this Address?
            </AlertDialogHeader>
          </AlertDialogBody>
          <AlertDialogFooter pt={0}>
            <Button
              className="mob_nunitosans-normal-shark-14px"
              fontSize="14px"
              bgColor="var(--white)"
              borderRadius="3px"
              width="70px"
              size="xs"
              onClick={() => {
                deleteAddress();
              }}
              boxShadow="0px 3px 6px #00000029"
            >
              Delete
            </Button>

            <Button
              className="mob_nunitosans-normal-shark-14px"
              ml={3}
              width="70px"
              size="xs"
              fontSize="14px"
              borderRadius="3px"
              boxShadow={'0px 0px 3px #050E2740'}
              bgColor={'var( --white-rock)'}
              onClick={() => {
                submitEdit();
              }}
            >
              Edit
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
