import React from 'react';
import AddressData from '../Address/AddressData';
import { useState } from 'react';
import Timer from '../Login/LoginSignup/timer';
import { IncludeComaSeperator } from '../ProductDetailpage/pd';
import { post } from '../../services/api';

const PayOnVerification = (props) => {
  // console.log(props, "fhdjgksfnldasdbjnnjdn");

  const [isSentOTP, setIsSentOTP] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [OTP, setOTP] = useState('');
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [disablePlaceOrderButton, setdisablePlaceOrderButton] = useState(false);

  const enableBtn = () => {
    setTrigger(false);
  };

  const resendOtp = () => {
    let data = {
      type: 'COD_OTP_RESEND',
      text: props.selectedAddress[0]?.mobile.toString(),
      cart_id: props.cartItems[0]['cart_id']['$oid'],
    };
    post('/api/v2/cod_resendotp/', data)
      .then((res) => {
        // console.log(data, "jbfnkd");
        setTrigger(true);
      })
      .catch((err) => {});
  };

  const SentOTP = () => {
    let data = {
      cart_id: props.cartItems[0]['cart_id']['$oid'],
      mobile: props.selectedAddress[0]?.mobile.toString(),
    };
    post('/api/v2/products/sendcod_otp/', data)
      .then((res) => {
        // console.log(data, "onSubmitjbfnkd");
        setIsSentOTP(true);
        setTrigger(true);
      })
      .catch((err) => {});
  };

  const PlaceOrder = () => {
    if (OTP.length == 4) {
      props.placeOrder(OTP);
      // console.log('bgnbdzbgnb');
      setdisablePlaceOrderButton(true);
    } else {
      setInvalidOTP(true);
    }
  };

  // console.log(isSentOTP, "isSentOTPisSentOTP", trigger, "triggertrigger");

  return (
    <div className="max-w-full py-7 px-4 md:px-14 bg-white max-h-full">
      <div className="lg:px-16 mx-auto max-w-screen-2xl ">
        <div className="pb-4">
          <div>
            <p className="nxt_subheaders">PAY ON DELIVERY VERIFICATION</p>
            <div className="ccr_divider"></div>
            <div className="add_font16 px-7 font-normal mt-4 md:px-10">
              <p className="font-normal">
                To verify your Pay on Delivery purchase, an OTP will be sent to
                the mobile number in the selected address
              </p>
            </div>

            <div className="add_font14 mt-[4.48vh] flex flex-col md:mt-[7.04vh] md:min-w-[385px] md:max-w-[434px] lg:min-w-[415px] lg:max-w-[459px] xl:min-w-[499px] xl:max-w-[514px] md:mx-auto">
              <AddressData
                title={'PAY ON DELIVERY VERIFICATION'}
                addresses={props.selectedAddress}
                center={false}
              />
              <div style={{ minHeight: '22px', width: '100%' }}>
                <span className="add_font12 ml-2 font-bold text-redB">
                  COD Charges: {props.priceDts?.cod_charges} Rs
                </span>
              </div>
            </div>

            <div className="md:min-w-[385px] md:max-w-[434px] lg:min-w-[415px] lg:max-w-[459px] xl:min-w-[499px] xl:max-w-[514px] md:mx-auto">
              <div className="relative mt-8">
                <input
                  className="ccr_inputbox add_font18 p-3 text-greyB3"
                  name="number"
                  type="number"
                  placeholder={`+91${props.selectedAddress[0]?.mobile}`}
                  disabled
                />
                {!isSentOTP && (
                  <div className="absolute top-2.5 sm:top-2 md:top-1.5 lg:top-2 right-3 flex font-bold">
                    <button
                      className="flex items-center rounded-lg bg-yellowB px-3 py-2 md:px-4 lg:px-5 font-bold uppercase add_font12 tracking-wide text-blueB3 shadow-bsCard focus:outline-none hover:opacity-60"
                      onClick={SentOTP}
                    >
                      SEND OTP
                    </button>
                  </div>
                )}

                <span className="ml-2 flex justify-end gap-1.5 md:text-sm">
                  <span className="add_font14 font-bold">
                    <Timer enableBtn={enableBtn} trigger={trigger} />
                  </span>{' '}
                  {isSentOTP && (
                    <button
                      className={`add_font14 font-bold ${
                        trigger ? 'text-greyB3 cursor-not-allowed' : ''
                      }`}
                      disabled={trigger}
                      onClick={() => {
                        console.log('bhjdfksnl');
                        resendOtp();
                      }}
                    >
                      RESEND
                    </button>
                  )}
                </span>
              </div>
              <div
                className={`relative mt-[2.56vh] ${
                  isSentOTP ? 'mb-[7.04vh]' : ''
                }`}
              >
                {isSentOTP && (
                  <input
                    className="ccr_inputbox add_font18 p-3 drop-shadow-dsInput text-greyB3"
                    name="otp"
                    type="number"
                    placeholder="Enter OTP"
                    maxLength={4}
                    onKeyDown={(e) => {
                      e.key === 'Enter' &&
                        e.target.value.length === e.target.maxLength &&
                        PlaceOrder(OTP);
                    }}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      else {
                        setOTP(e.target.value);
                      }
                      setInvalidOTP(false);
                    }}
                  />
                )}
                <div style={{ minHeight: '30px', width: '100%' }}>
                  {invalidOTP && (
                    <span className="add_font12 ml-2 font-normal text-redB opacity-50">
                      Invalid OTP
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="pay_font14-20 text-center">
              <button
                type="button"
                className={`ModalyellowBtnBorder mb-[7vh] px-5 sm:px-6 py-2.5 md:px-7 md:py-3 lg:px-8 hover:opacity-60 ${
                  !isSentOTP
                    ? 'text-greyB3 opacity-90 cursor-not-allowed'
                    : 'hover:opacity-60'
                }`}
                disabled={!isSentOTP || disablePlaceOrderButton}
                onClick={() => PlaceOrder(OTP)}
              >
                <p>PLACE ORDER</p>
                <p>
                  (
                  {IncludeComaSeperator(
                    props.priceDts.shiprocket_cod_total_amount
                  )}{' '}
                  Rs)
                </p>
              </button>

              <p className="mb-[7vh]">OR</p>
              <button
                type="button"
                className="ModalyellowBtn px-6 sm:px-7 py-2.5 md:px-8 md:py-3 lg:px-9 hover:opacity-60"
                onClick={() => props.onlinePayment()}
              >
                <p> PAY ONLINE </p>
                <p>
                  ({IncludeComaSeperator(props.priceDts.shiprocket_total)} Rs)
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PayOnVerification;
